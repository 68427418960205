import React from 'react';

import { insertBlock } from '../../services/editor';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import YouTubePrompter from './YouTubePrompter';

class YouTubeTool extends React.Component {
    constructor(props) {
        super(props);
        this.confirmYouTube = this.confirmYouTube.bind(this);
    }

    confirmYouTube(url, caption, position) {
        insertBlock(this, 'YOUTUBE', { url, caption, position });
        this.props.dialogs.remove(dialogsKeys.editorBlock);
    }

    render() {
        const { editorState, setEditorState } = this.props;

        return (
            <YouTubePrompter
                editorState={editorState}
                setEditorState={setEditorState}
                confirmYouTube={this.confirmYouTube}
                isToolButton
            >
                YouTube
            </YouTubePrompter>
        );
    }
}

export default withDialogs(YouTubeTool);
