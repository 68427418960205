import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ClippingCreateOrUpdateForm from '../../forms/Clipping/ClippingCreateOrUpdateForm';
import ClippingRemoveForm from '../../forms/Clipping/ClippingRemoveForm';

export default function ClippingUpdatePage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Editar fonte de mídia"
            tabs={[
                { linkTo: `/na-midia/${id}`, text: 'Editar' },
                { linkTo: `/na-midia/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <ClippingCreateOrUpdateForm id={id} />
            <br />
            <ClippingRemoveForm id={id} />
        </DefaultLayout>
    );
}
