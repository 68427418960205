import React from 'react';

import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import AtomicForm from '../AtomicForm';
import ImageForm from './ImageForm';

class ImagePrompter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            atomicParams: null,
        };
        this.promptAtomicForm = this.promptAtomicForm.bind(this);
        this.promptImageForm = this.promptImageForm.bind(this);
    }

    promptAtomicForm(event) {
        event.stopPropagation();
        const { orientation, position } = this.props;
        const atomicParams = orientation && position ? { orientation, position } : null;

        this.props.dialogs.add(
            dialogsKeys.editorBlock,
            this.props.image && this.props.image.id ? 'Editar imagem' : 'Adicionar imagem',
            <AtomicForm atomicParams={atomicParams} setAtomicParams={this.promptImageForm} withOrientation />
        );
    }

    promptImageForm(atomicParams) {
        this.props.dialogs.remove(dialogsKeys.editorBlock);
        const { image, caption, orientation, position } = this.props;

        let imageProps = '';
        if (image && atomicParams.orientation === orientation && atomicParams.position === position) {
            imageProps = image;
        }

        this.props.dialogs.add(
            dialogsKeys.editorBlock,
            this.props.image && this.props.image.id ? 'Editar imagem' : 'Adicionar imagem',
            <ImageForm
                image={imageProps || ''}
                caption={caption}
                atomicParams={atomicParams}
                confirmImage={this.props.confirmImage}
            />
        );
    }

    render() {
        const { isToolButton, children } = this.props;

        return (
            <button
                type="button"
                className={`button ${isToolButton ? 'is-primary is-light' : ''}`}
                onClick={this.promptAtomicForm}
            >
                {children}
            </button>
        );
    }
}

export default withDialogs(ImagePrompter);
