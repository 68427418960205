import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterCollection from '../../collections/Newsletter/NewsletterCollection';

export default function NewsletterRemovedIndexPage() {
    return (
        <DefaultLayout
            title="Boletins removidos"
            tabs={[
                { linkTo: '/boletins', text: 'Ativas' },
                { linkTo: '/boletins/removidos', text: 'Removidos' },
            ]}
        >
            <NewsletterCollection paginateRequest="paginateRemoved" />
        </DefaultLayout>
    );
}
