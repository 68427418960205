import React from 'react';

import { subscribe } from '../../requests/newsletter-recipient';
import withNotifications from '../../contexts/withNotifications';
import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';

class NewsletterRecipientSubscribePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: 'Estamos te inscrevendo na lista de recebimento do nosso boletim...',
        };
    }

    componentDidMount() {
        const { location } = this.props;
        const url = new URLSearchParams(location.search).get('url');

        subscribe(this, url);
    }

    render() {
        return (
            <UnauthenticatedLayout title="Inscrição no boletim">
                <p className="subtitle">{this.state.message}</p>
            </UnauthenticatedLayout>
        );
    }
}

export default withNotifications(NewsletterRecipientSubscribePage);
