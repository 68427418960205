import React from 'react';

import { listAll } from '../../requests/work-group';
import withNotifications from '../../contexts/withNotifications';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import WorkGroupCreateOrUpdateForm from '../../forms/WorkGroup/WorkGroupCreateOrUpdateForm';
import Select from './Select';

class WorkGroupField extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            workGroupList: [],
        };
        this.onChange = this.onChange.bind(this);
        this.openWorkGroupCreateForm = this.openWorkGroupCreateForm.bind(this);
    }

    componentDidMount() {
        listAll(this);
    }

    onChange(event) {
        const { addFn } = this.props;
        addFn({
            id: event.target.value,
            title: event.target.options[event.target.selectedIndex].text,
        });
        this.setState({ value: '' });
    }

    openWorkGroupCreateForm() {
        const { addFn, dialogs } = this.props;

        dialogs.add(
            dialogsKeys.workGroup,
            'Adicionar grupo de trabalho',
            <WorkGroupCreateOrUpdateForm
                successCallbackFn={(response) => {
                    listAll(this);
                    addFn({
                        id: response.data.id,
                        title: response.data.title,
                    });
                    dialogs.remove(dialogsKeys.workGroup);
                }}
                withoutBox
            />
        );
    }

    render() {
        const { values } = this.props;
        const { workGroupList, isLoading } = this.state;

        return (
            <>
                <div className="field">
                    <Select
                        name="work-group-controller"
                        label="Grupos de trabalho"
                        isLoading={isLoading}
                        onChange={this.onChange}
                        withAddOn={
                            <button
                                type="button"
                                className={`button is-primary ${isLoading ? 'is-loading' : ''}`}
                                disabled={isLoading}
                                onClick={this.openWorkGroupCreateForm}
                            >
                                Adicionar
                            </button>
                        }
                    >
                        <option value="">Selecione ou adicione um grupo de trabalho...</option>
                        {Boolean(workGroupList.length) &&
                            workGroupList.map((workGroup) =>
                                values ? (
                                    values.filter((value) => value.title === workGroup.title).length === 0 && (
                                        <option key={workGroup.id} value={workGroup.id}>
                                            {workGroup.title}
                                        </option>
                                    )
                                ) : (
                                    <option key={workGroup.id} value={workGroup.id}>
                                        {workGroup.title}
                                    </option>
                                )
                            )}
                    </Select>
                </div>
            </>
        );
    }
}

export default withNotifications(withDialogs(WorkGroupField));
