import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterCreateOrUpdateForm from '../../forms/Newsletter/NewsletterCreateOrUpdateForm';
import NewsletterRemoveForm from '../../forms/Newsletter/NewsletterRemoveForm';

export default function NewsletterUpdatePage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Editar boletim"
            tabs={[
                { linkTo: `/boletins/${id}`, text: 'Editar' },
                { linkTo: `/boletins/${id}/metricas`, text: 'Métricas' },
                { linkTo: `/boletins/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <NewsletterCreateOrUpdateForm id={id} />
            <br />
            <NewsletterRemoveForm id={id} />
        </DefaultLayout>
    );
}
