import React from 'react';

import { editBlock, deleteBlock } from '../../services/editor';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import BlockquotePrompter from './BlockquotePrompter';
import BlockquoteElement from './BlockquoteElement';

class BlockquoteBlock extends React.Component {
    constructor(props) {
        super(props);
        this.confirmBlockquote = this.confirmBlockquote.bind(this);
        this.removeBlockquote = this.removeBlockquote.bind(this);
    }

    confirmBlockquote(text, position) {
        editBlock(this, { text, position });
        this.props.dialogs.remove(dialogsKeys.editorBlock);
    }

    removeBlockquote(event) {
        event.stopPropagation();
        deleteBlock(this);
    }

    render() {
        const { block, contentState } = this.props;
        const { editorState, setEditorState } = this.props.blockProps;
        const { text, position } = contentState.getEntity(block.getEntityAt(0)).getData();

        return (
            <div className="editor-block">
                <BlockquoteElement text={text} />
                <BlockquotePrompter
                    editorState={editorState}
                    setEditorState={setEditorState}
                    text={text}
                    position={position}
                    confirmBlockquote={this.confirmBlockquote}
                >
                    Editar olho
                </BlockquotePrompter>
                <button type="button" className="button" onClick={this.removeBlockquote}>
                    Remover olho
                </button>
            </div>
        );
    }
}

export default withDialogs(BlockquoteBlock);
