import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import UserUpdateForm from '../../forms/User/UserUpdateForm';
// import UserRemoveForm from '../../forms/User/UserRemoveForm';

export default function UserRemovedPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Usuário removido">
            <UserUpdateForm id={id} onSubmitRequest="restore" />
            {/* <br />
            <UserRemoveForm id={id} onSubmitRequest="removePermanently" /> */}
        </DefaultLayout>
    );
}
