import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import InterviewCreateOrUpdateForm from '../../forms/Interview/InterviewCreateOrUpdateForm';

export default function InterviewRevisionPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Versão de entrevista" isFullWidth>
            <InterviewCreateOrUpdateForm id={id} showRequest="revision" />
        </DefaultLayout>
    );
}
