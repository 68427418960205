import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { requestCreate } from '../../requests/newsletter-recipient';
import withNotifications from '../../contexts/withNotifications';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';

export const initialValues = {
    email: '',
};

export const validationSchema = Yup.object({
    email: Yup.string()
        .required('Campo obrigatório')
        .email('Endereço de e-mail inválido')
        .max(191, 'O campo não pode ter mais que 191 caracteres'),
});

class NewsletterRecipientRequestCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        requestCreate(this, values);
    }

    render() {
        const { isLoading } = this.state;

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit}>
                {({ errors, touched }) => (
                    <Form>
                        <div className="box">
                            <p>
                                Preencha abaixo o e-mail que será convidado a se inscrever no boletim. Será enviado a
                                ele um link para completar a inscrição.
                            </p>
                            <br />
                            <div className="columns">
                                <div className="column">
                                    <Field
                                        name="email"
                                        label="E-mail"
                                        type="email"
                                        autoComplete="email"
                                        errors={errors.email}
                                        touched={touched.email}
                                    />
                                </div>
                                <div className="column"></div>
                            </div>
                        </div>
                        <div className="actions actions--sticky">
                            <button
                                type="submit"
                                className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                                disabled={isLoading}
                            >
                                Enviar e-mail
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withNotifications(withRouter(NewsletterRecipientRequestCreateForm));
