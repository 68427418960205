import React from 'react';

import NewsletterSectionField from './NewsletterSectionField';
import NewsletterSectionTemplateCollection from '../../../collections/NewsletterSectionTemplate/NewsletterSectionTemplateCollection';

export default class NewsletterSectionsField extends React.PureComponent {
    constructor(props) {
        super(props);
        this.select = this.select.bind(this);
    }

    /**
     * Seleciona uma seção de boletim e a adiciona ao boletim.
     *
     * @param {*} newsletterSectionTemplate
     */
    select(newsletterSectionTemplate) {
        const { arrayHelpers } = this.props;

        arrayHelpers.push({
            ...newsletterSectionTemplate,
            id: undefined,
            items: [
                {
                    image: {
                        id: undefined,
                        url: undefined,
                    },
                    title: '',
                    text: '',
                    link_text: '',
                    link_href: '',
                    is_cdts: false,
                },
            ],
        });
    }

    /**
     * @inheritdoc
     */
    render() {
        const { parentName, values, errors, touched, setFieldValue, arrayHelpers, isLoading } = this.props;

        return (
            <>
                {values &&
                    Boolean(values.length) &&
                    values.map((section, i) => (
                        <NewsletterSectionField
                            key={i}
                            index={i}
                            name={`${parentName}.${i}`}
                            length={values.length}
                            values={section}
                            errors={errors ? errors[i] : {}}
                            touched={touched ? touched[i] : {}}
                            setFieldValue={setFieldValue}
                            arrayHelpers={arrayHelpers}
                            isLoading={isLoading}
                        />
                    ))}
                <div className="NewsletterSectionsField">
                    {touched && errors && typeof errors === 'string' && (
                        <p className="menu-label has-text-centered has-text-danger py-3">{errors}</p>
                    )}
                    {(!values || !Boolean(values.length)) && (
                        <p className="menu-label has-text-centered py-3">
                            Selecione uma seção abaixo para adicioná-la ao boletim
                        </p>
                    )}
                    <NewsletterSectionTemplateCollection selectFn={this.select} withoutBox />
                </div>
            </>
        );
    }
}
