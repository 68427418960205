import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

import { convertToHtml, Decorator } from '../services/editor';
import * as storable from '../services/storable';
import * as crud from './crud';
import * as softDeletes from './soft-deletes';
import * as revisionable from './revisionable';

const URL_PREFIX = 'opiniao-do-especialista';
const STATE_KEY = 'opinion';
const STORAGE_KEY = 'opinion';

/**
 * @export
 * @param {*} component
 * @param {*} page
 * @param {*} [options=null]
 */
export function paginate(component, page, options = null) {
    crud.paginate(URL_PREFIX, component, page, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} values
 * @param {*} [options=null]
 */
export function create(component, values, options = null) {
    crud.create(URL_PREFIX, component, values, {
        ...options,
        storageKey: STORAGE_KEY,
        transformData: (data) => {
            const newData = JSON.parse(JSON.stringify(data));

            newData.payload = JSON.stringify(convertToRaw(data.payload.getCurrentContent()));
            newData.html = convertToHtml(data.payload.getCurrentContent());
            newData.image_id = newData.image.id;
            newData.thumbnail_id = newData.thumbnail.id;

            return newData;
        },
    });
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function show(component, id, options) {
    crud.show(URL_PREFIX, STATE_KEY, component, id, {
        ...options,
        afterStateUpdateFn: () => {
            showStoredValues(component, id);
        },
        transformData: (data) => {
            const newData = data;

            newData.payload = EditorState.createWithContent(convertFromRaw(JSON.parse(data.payload)), Decorator);

            return newData;
        },
    });
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} values
 * @param {*} [options=null]
 */
export function update(component, id, values, options = null) {
    crud.update(URL_PREFIX, component, id, values, {
        ...options,
        storageKey: STORAGE_KEY,
        transformData: (data) => {
            const newData = JSON.parse(JSON.stringify(data));

            newData.payload = JSON.stringify(convertToRaw(data.payload.getCurrentContent()));
            newData.html = convertToHtml(data.payload.getCurrentContent());
            newData.image_id = newData.image.id;
            newData.thumbnail_id = newData.thumbnail.id;

            return newData;
        },
    });
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function remove(component, id, options = null) {
    crud.remove(URL_PREFIX, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} page
 */
export function paginateRemoved(component, page) {
    softDeletes.paginateRemoved(URL_PREFIX, component, page);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function showRemoved(component, id, options = null) {
    softDeletes.showRemoved(URL_PREFIX, STATE_KEY, component, id, {
        ...options,
        transformData: (data) => {
            const newData = data;
            newData.payload = EditorState.createWithContent(convertFromRaw(JSON.parse(data.payload)), Decorator);

            return newData;
        },
    });
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function restore(component, id) {
    softDeletes.restore(URL_PREFIX, component, id, {
        transformData: (data) => {
            const newData = JSON.parse(JSON.stringify(data));
            newData.payload = JSON.stringify(convertToRaw(data.payload.getCurrentContent()));

            return newData;
        },
    });
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function removePermanently(component, id) {
    softDeletes.removePermanently(URL_PREFIX, component, id);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} page
 */
export function listRevisions(component, id, page) {
    revisionable.listRevisions(URL_PREFIX, component, id, page);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function showRevision(component, id, options = null) {
    revisionable.showRevision(URL_PREFIX, STATE_KEY, component, id, {
        ...options,
        transformData: (data) => {
            const newData = data;
            newData.payload = EditorState.createWithContent(convertFromRaw(JSON.parse(data.payload)), Decorator);

            return newData;
        },
    });
}

/**
 * @export
 * @param {*} values
 * @param {*} initialValues
 * @param {*} [options=null]
 */
export function storeValues(values, initialValues, options = null) {
    storable.storeValues(STORAGE_KEY, values, initialValues, {
        ...options,
        transformData: (data) => {
            const newData = JSON.parse(JSON.stringify(data));
            newData.payload = convertToRaw(data.payload.getCurrentContent());

            return newData;
        },
    });
}

/**
 * @export
 * @param {*} component
 * @param {*} [id=null]
 * @param {*} [options=null]
 */
export function showStoredValues(component, id = null, options = null) {
    storable.getStoredValues(STORAGE_KEY, STATE_KEY, component, id, {
        ...options,
        transformData: (data) => {
            const newData = JSON.parse(JSON.stringify(data));
            newData.payload = EditorState.createWithContent(convertFromRaw(data.payload), Decorator);

            return newData;
        },
    });
}

/**
 * @export
 * @param {*} component
 * @param {*} [id=null]
 */
export function removeStoredValues(component, initialValues, id = null) {
    storable.removeStoredValues(STORAGE_KEY, STATE_KEY, initialValues, show, component, id);
}
