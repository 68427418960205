import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterRecipientRevisionCollection from '../../collections/NewsletterRecipient/NewsletterRecipientRevisionCollection';

export default function NewsletterRecipientRevisionIndexPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Histórico do destinatário de boletim"
            tabs={[
                { linkTo: `/destinatarios-de-boletim/${id}`, text: 'Visualizar' },
                { linkTo: `/destinatarios-de-boletim/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <NewsletterRecipientRevisionCollection
                id={id}
                request="listNewsletterRecipientRevisions"
                linkToPrefix="destinatarios-de-boletim"
            />
        </DefaultLayout>
    );
}
