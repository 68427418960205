const TOKEN_KEY = '2&zs-Td_';

/**
 * Retorna o JWT da localStorage.
 *
 * @export
 * @returns string
 */
export function getToken() {
    return localStorage.getItem(TOKEN_KEY);
}

/**
 * Define o JTW na localStorage.
 *
 * @export
 * @param {string} token
 */
export function setToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
}

/**
 * Remove o JWT da localStorage.
 *
 * @export
 */
export function removeToken() {
    localStorage.removeItem(TOKEN_KEY);
}

/**
 * Retorna se o JWT está presente na localStorage.
 *
 * @export
 * @returns
 */
export function isAuthenticated() {
    return localStorage.getItem(TOKEN_KEY) !== null;
}
