import React from 'react';
import { Link } from 'react-router-dom';

import DefaultLayout from '../../layouts/DefaultLayout';
import WorkGroupCollection from '../../collections/WorkGroup/WorkGroupCollection';

export default function WorkGroupIndexPage() {
    return (
        <DefaultLayout
            title="Grupos de trabalho"
            tabs={[
                { linkTo: '/grupos-de-trabalho', text: 'Ativos' },
                { linkTo: '/grupos-de-trabalho/removidos', text: 'Removidos' },
            ]}
        >
            <WorkGroupCollection />
            <Link to="/grupos-de-trabalho/adicionar" className="button is-primary is-medium">
                Adicionar
            </Link>
        </DefaultLayout>
    );
}
