import React from 'react';
import { Link } from 'react-router-dom';

import DefaultLayout from '../../layouts/DefaultLayout';
import PostCollection from '../../collections/Post/PostCollection';

export default function PostIndexPage() {
    return (
        <DefaultLayout
            title="Notícias"
            tabs={[
                { linkTo: '/noticias', text: 'Ativas' },
                { linkTo: '/noticias/removidas', text: 'Removidas' },
            ]}
        >
            <PostCollection />
            <Link to="/noticias/adicionar" className="button is-primary is-medium">
                Adicionar
            </Link>
        </DefaultLayout>
    );
}
