import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterRecipientCollection from '../../collections/NewsletterRecipient/NewsletterRecipientCollection';

export default function NewsletterRecipientListRemovedPage() {
    return (
        <DefaultLayout
            title="Destinatários de boletim removidos"
            tabs={[
                { linkTo: '/destinatarios-de-boletim', text: 'Ativos' },
                { linkTo: '/destinatarios-de-boletim/removidos', text: 'Removidos' },
            ]}
        >
            <NewsletterRecipientCollection paginateRequest="paginateRemoved" />
        </DefaultLayout>
    );
}
