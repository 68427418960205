import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

import placeholder_1600x900 from '../../../images/placeholders/1600x900.png';
import withDialogs, { dialogsKeys } from '../../../contexts/withDialogs';
import Field from '../../../components/Forms/Field';
import CheckboxField from '../../../components/Forms/CheckboxField';
import ImageField from '../../../components/Forms/ImageField';

class NewsletterSectionItemField extends React.PureComponent {
    constructor(props) {
        super(props);
        this.openImageField = this.openImageField.bind(this);
    }

    /**
     * Abre o ImageField.
     */
    openImageField() {
        const { name, setFieldValue, dialogs } = this.props;

        dialogs.add(
            dialogsKeys.imageField,
            'Gerenciador de Mídias',
            <ImageField
                mimeType="image/jpeg"
                width={1600}
                height={900}
                aspectRatio={16 / 9}
                setImageId={(value) => setFieldValue(`${name}.image.id`, value)}
                setImageUrl={(value) => setFieldValue(`${name}.image.url`, value)}
            />,
            true
        );
    }

    /**
     * @inheritdoc
     */
    render() {
        const { index, name, length, values, errors, touched, setFieldValue, arrayHelpers, isLoading } = this.props;

        return (
            <>
                <section className="NewsletterSectionItemFormFields">
                    <div className="NewsletterSectionItemFormFields__image image-buttons">
                        {values.image && values.image.id ? (
                            <>
                                <img
                                    src={`${process.env.REACT_APP_BASE_WEB_URL}${values.image.url}`}
                                    alt=""
                                    width={800}
                                    height={450}
                                />
                                <button
                                    type="button"
                                    className={`image-buttons__double button button-reset is-small ${
                                        isLoading ? 'is-loading' : ''
                                    }`}
                                    disabled={isLoading}
                                    onClick={this.openImageField}
                                >
                                    Trocar imagem
                                </button>
                                <button
                                    type="button"
                                    className={`image-buttons__double button button-reset is-small ${
                                        isLoading ? 'is-loading' : ''
                                    }`}
                                    disabled={isLoading}
                                    onClick={() => {
                                        setFieldValue(`${name}.image.id`, '');
                                        setFieldValue(`${name}.image.url`, '');
                                    }}
                                >
                                    Remover imagem
                                </button>
                            </>
                        ) : (
                            <button
                                type="button"
                                className={`
                                    image-buttons__single
                                    image-buttons__single--with-placeholder
                                    button
                                    is-small ${isLoading ? 'is-loading' : ''}`}
                                disabled={isLoading}
                                onClick={this.openImageField}
                            >
                                <img src={placeholder_1600x900} alt="" width={800} height={450} />
                                <span>Adicionar imagem</span>
                            </button>
                        )}
                    </div>
                    <div className="NewsletterSectionItemFormFields__content">
                        <Field
                            variant="NewsletterSectionItemFormFields"
                            modifier={`title ${values.is_cdts ? 'is-cdts' : ''}`}
                            name={`${name}.title`}
                            label="Título"
                            labelHidden
                            as={TextareaAutosize}
                            placeholder="Título do item"
                            errors={errors && errors.title}
                            touched={touched && touched.title}
                            isFastField
                        />
                        <Field
                            variant="NewsletterSectionItemFormFields"
                            modifier="text"
                            name={`${name}.text`}
                            label="Texto"
                            labelHidden
                            as={TextareaAutosize}
                            placeholder="Texto do item"
                            errors={errors && errors.text}
                            touched={touched && touched.text}
                            isFastField
                        />
                        <div className="NewsletterSectionItemFormFields__level">
                            <Field
                                variant="NewsletterSectionItemFormFields"
                                modifier="link_text"
                                name={`${name}.link_text`}
                                label="Texto do link"
                                labelHidden
                                placeholder="Texto do link"
                                style={{ width: `${values.link_text ? values.link_text.length : 0}ch` }}
                                errors={errors && errors.link_text}
                                touched={touched && touched.link_text}
                                isFastField
                            />
                            <Field
                                variant="NewsletterSectionItemFormFields"
                                modifier="link_href"
                                name={`${name}.link_href`}
                                label="Endereço do link"
                                labelHidden
                                placeholder="Endereço do link"
                                type="url"
                                as={TextareaAutosize}
                                errors={errors && errors.link_href}
                                touched={touched && touched.link_href}
                                isFastField
                            />
                        </div>
                        <CheckboxField
                            name={`${name}.is_cdts`}
                            label="Logo do CDTS"
                            errors={errors && errors.is_cdts}
                            touched={touched && touched.is_cdts}
                            isFastField
                        />
                    </div>
                </section>
                <div className="buttons">
                    <button
                        type="button"
                        className={`button is-small ${isLoading ? 'is-loading' : ''}`}
                        disabled={index === 0 || isLoading}
                        onClick={() => arrayHelpers.move(index, index - 1)}
                    >
                        Item para cima
                    </button>
                    <button
                        type="button"
                        className={`button is-small ${isLoading ? 'is-loading' : ''}`}
                        disabled={index + 1 === length || isLoading}
                        onClick={() => arrayHelpers.move(index, index + 1)}
                    >
                        Item para baixo
                    </button>
                    <button
                        type="button"
                        className={`button is-small ${isLoading ? 'is-loading' : ''}`}
                        disabled={isLoading}
                        onClick={() => arrayHelpers.remove(index)}
                    >
                        Remover item
                    </button>
                </div>
            </>
        );
    }
}

export default withDialogs(NewsletterSectionItemField);
