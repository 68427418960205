import React from 'react';
import { Link } from 'react-router-dom';

import { getDate, getTime } from '../../services/datetime';

export const revisionTableHeaders = ['Data e hora', 'Mensagem', 'Autor(a)'];

export default function RevisionTableRow(props) {
    const { data, linkToPrefix } = props;

    return (
        <tr key={data.id}>
            <td>
                <Link to={`/${linkToPrefix}/historico/${data.id}`}>
                    {`${getDate(data.created_at)} às ${getTime(data.created_at)}`}
                </Link>
            </td>
            <td>{data.message}</td>
            <td>
                {data.user.id ? (
                    <Link to={`/usuarios/${data.user.id}`}>{data.user.email}</Link>
                ) : (
                    <span>{data.user}</span>
                )}
            </td>
        </tr>
    );
}
