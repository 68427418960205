import React from 'react';

import { insertBlock } from '../../services/editor';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import BlockquotePrompter from './BlockquotePrompter';

class BlockquoteTool extends React.Component {
    constructor(props) {
        super(props);
        this.confirmBlockquote = this.confirmBlockquote.bind(this);
    }

    confirmBlockquote(text, position) {
        insertBlock(this, 'BLOCKQUOTE', { text, position });
        this.props.dialogs.remove(dialogsKeys.editorBlock);
    }

    render() {
        const { editorState, setEditorState } = this.props;

        return (
            <BlockquotePrompter
                editorState={editorState}
                setEditorState={setEditorState}
                confirmBlockquote={this.confirmBlockquote}
                isToolButton
            >
                Olho
            </BlockquotePrompter>
        );
    }
}

export default withDialogs(BlockquoteTool);
