import React from 'react';
import { Link } from 'react-router-dom';

import DefaultLayout from '../../layouts/DefaultLayout';
import ClippingSourceCollection from '../../collections/ClippingSource/ClippingSourceCollection';

export default function ClippingSourceIndexPage() {
    return (
        <DefaultLayout
            title="Fontes de mídia"
            tabs={[
                { linkTo: '/fontes-de-midia', text: 'Ativas' },
                { linkTo: '/fontes-de-midia/removidas', text: 'Removidas' },
            ]}
        >
            <ClippingSourceCollection />
            <Link to="/fontes-de-midia/adicionar" className="button is-primary is-medium">
                Adicionar
            </Link>
        </DefaultLayout>
    );
}
