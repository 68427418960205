import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import 'moment/locale/pt-br';

import { show, create, update, restore, showRemoved, showRevision } from '../../requests/clipping';
import withNotifications from '../../contexts/withNotifications';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';
import RevisionData from '../../components/Forms/RevisionData';
import ImageField from '../../components/Forms/ImageField';
import ClippingSourceField from '../../components/Forms/ClippingSourceField';
import ClippingCollectionItem from '../../collections/Clipping/ClippingCollectionItem';
import PublicationFields from '../../components/Forms/PublicationFields';

moment.locale('pt-br');

export const initialValues = {
    title: '',
    url: '',
    image: {
        id: '',
        url: '',
    },
    clipping_source: {
        id: '',
        image: {
            id: '',
            url: '',
        },
    },
    published: '1',
    published_at: moment().format('YYYY-MM-DD HH:mm:ss'),
};

export const validationSchema = Yup.object({
    title: Yup.string().required('Campo obrigatório').max(191, 'O campo não pode ter mais que 191 caracteres'),
    image: Yup.object().shape({
        id: Yup.number().required('Campo obrigatório'),
    }),
    url: Yup.string().required('Campo obrigatório').url('O campo deve ser uma URL válida'),
    clipping_source: Yup.object().shape({
        id: Yup.number().required('Campo obrigatório'),
    }),
    published: Yup.bool().required('Campo obrigatório'),
    published_at: Yup.string().required('Campo obrigatório'),
});

class ClippingCreatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            clipping: initialValues,
            clippingSource: '',
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { id, showRequest, onSubmitRequest } = this.props;

        if (id) {
            if (showRequest === 'revision') {
                showRevision(this, id);
            } else if (onSubmitRequest === 'restore') {
                showRemoved(this, id);
            } else {
                show(this, id);
            }
        }
    }

    /**
     * Cria, atualiza ou restaura a clipping ao submeter o formulário.
     *
     * @param {*} values
     */
    onSubmit(values) {
        const { id, onSubmitRequest } = this.props;

        if (id) {
            if (onSubmitRequest === 'restore') {
                restore(this, id);
            } else {
                update(this, id, values);
            }
        } else {
            create(this, values);
        }
    }

    openImageField(setFieldValue) {
        this.props.dialogs.add(
            dialogsKeys.imageField,
            'Gerenciador de Mídias',
            <ImageField
                mimeType="image/jpeg"
                width={1600}
                height={1200}
                aspectRatio={4 / 3}
                setImageId={(value) => setFieldValue('image.id', value)}
                setImageUrl={(value) => setFieldValue('image.url', value)}
            />,
            true
        );
    }

    /**
     * @inheritdoc
     */
    render() {
        const { id, showRequest, disabled, onSubmitRequest } = this.props;
        const { isLoading, clipping, revision } = this.state;

        return (
            <Formik
                initialValues={clipping}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <fieldset disabled={disabled || showRequest === 'revision' || onSubmitRequest === 'restore'}>
                            <div className="box">
                                <div className="columns">
                                    <div className="column">
                                        <Field
                                            name="title"
                                            label="Título"
                                            errors={errors.title}
                                            touched={touched.title}
                                        />
                                        <Field
                                            name="url"
                                            label="Link"
                                            type="url"
                                            errors={errors.url}
                                            touched={touched.url}
                                        />

                                        <ClippingSourceField
                                            value={values.clipping_source}
                                            errors={errors.clipping_source}
                                            touched={touched.clipping_source}
                                            setFieldValue={setFieldValue}
                                        />

                                        <div className="field">
                                            <p className="label">Imagem</p>
                                            {values.image && values.image.id ? (
                                                <div className="buttons">
                                                    <button
                                                        type="button"
                                                        className={`button ${isLoading ? 'is-loading' : ''}`}
                                                        disabled={isLoading}
                                                        onClick={() => this.openImageField(setFieldValue)}
                                                    >
                                                        Trocar imagem
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={`button is-danger is-light ${
                                                            isLoading ? 'is-loading' : ''
                                                        }`}
                                                        disabled={isLoading}
                                                        onClick={() => {
                                                            setFieldValue('image.id', '');
                                                            setFieldValue('image.url', '');
                                                        }}
                                                    >
                                                        Remover imagem
                                                    </button>
                                                </div>
                                            ) : (
                                                <>
                                                    <button
                                                        type="button"
                                                        className={`button ${isLoading ? 'is-loading' : ''}`}
                                                        disabled={isLoading}
                                                        onClick={() => this.openImageField(setFieldValue)}
                                                    >
                                                        Adicionar imagem
                                                    </button>
                                                    {touched && errors && touched.image && errors.image && (
                                                        <div className="help is-danger">{errors.image.id}</div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="column">
                                        <ClippingCollectionItem clipping={values} />
                                    </div>
                                </div>
                            </div>

                            <hr className="is-invisible" />

                            <PublicationFields errors={errors} touched={touched} />
                        </fieldset>

                        {showRequest === 'revision' && revision && (
                            <>
                                <hr className="invisible" />
                                <RevisionData revision={revision} />
                            </>
                        )}

                        {showRequest !== 'revision' && (
                            <>
                                <hr className="invisible" />
                                <div className="buttons actions actions--sticky">
                                    <button
                                        type="submit"
                                        className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                                        disabled={isLoading}
                                    >
                                        {onSubmitRequest === 'restore' ? 'Restaurar' : id ? 'Editar' : 'Adicionar'}
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withDialogs(withNotifications(withRouter(ClippingCreatePage)));
