import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import AuthenticationUpdateEmailForm from '../../forms/Authentication/AuthenticationUpdateEmailForm';

export default function AuthenticationUpdateEmailPage() {
    return (
        <DefaultLayout title="Atualizar meu e-mail">
            <AuthenticationUpdateEmailForm />
        </DefaultLayout>
    );
}
