import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import AuthenticationUpdatePasswordForm from '../../forms/Authentication/AuthenticationUpdatePasswordForm';

export default function AuthenticationUpdatePasswordPage() {
    return (
        <DefaultLayout title="Atualizar minha senha">
            <AuthenticationUpdatePasswordForm />
        </DefaultLayout>
    );
}
