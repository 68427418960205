import React from 'react';
import { ResponsiveLine } from '@nivo/line';

import { listImpressionsAndRedirects } from '../requests/newsletter';
import withNotifications from '../contexts/withNotifications';
import Pagination from '../components/Pagination';
import Message from '../components/Message';
import Loader from '../components/Loader';

class NewsletterProgressionChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            data: null,
            links: null,
            meta: null,
        };
        this.paginate = this.paginate.bind(this);
        this.getChart = this.getChart.bind(this);
    }

    componentDidMount() {
        const { filter } = this.state;
        listImpressionsAndRedirects(this, filter);
    }

    componentDidUpdate(prevProps, prevState) {
        const { filter } = this.state;
        if (filter !== prevState.filter) {
            listImpressionsAndRedirects(this, filter);
        }
    }

    paginate(page) {
        const { filter } = this.state;
        listImpressionsAndRedirects(this, filter, page);
    }

    getChart() {
        const { data, links, meta } = this.state;

        if (data) {
            if (Boolean(data.length)) {
                const impressionsData = [];
                const redirectsData = [];

                for (let i = 0; i < data.length; i += 1) {
                    impressionsData.push({ x: data[i].title, y: data[i].newsletters_impressions_total });
                    redirectsData.push({ x: data[i].title, y: data[i].newsletters_redirects_total });
                }

                return (
                    <>
                        <div className="mb-3" style={{ height: '350px' }}>
                            <ResponsiveLine
                                data={[
                                    { id: 'Visualizações', data: impressionsData.reverse() },
                                    { id: 'Redirecionamentos', data: redirectsData.reverse() },
                                ]}
                                colors={{ scheme: 'category10' }}
                                margin={{
                                    top: 30,
                                    right: 10,
                                    bottom: 100,
                                    left: 60,
                                }}
                                xScale={{ type: 'point' }}
                                yScale={{
                                    type: 'linear',
                                    min: 'auto',
                                    max: 'auto',
                                    stacked: false,
                                    reverse: false,
                                }}
                                axisBottom={{ tickRotation: -45 }}
                                lineWidth={2}
                                pointSize={10}
                                pointBorderWidth={2}
                                enablePointLabel
                                enableSlices="x"
                                legends={[
                                    {
                                        anchor: 'bottom',
                                        direction: 'row',
                                        translateY: 100,
                                        itemWidth: 160,
                                        itemHeight: 16,
                                        symbolShape: 'circle',
                                    },
                                ]}
                                animate={false}
                            />
                        </div>
                        {links && meta && (
                            <Pagination links={links} meta={meta} goToPage={this.paginate} />
                        )}
                    </>
                );
            }
            
            return <Message>Nenhum resultado</Message>;
        }

        return (
            <Message>
                <Loader />
            </Message>
        );
    }

    render() {
        const { filter } = this.state;

        return (
            <>
                <nav className="tabs is-toggle">
                    <ul className="is-right">
                        <li className={filter === '' ? 'is-active' : ''}>
                            <button type="button" className="button" onClick={() => this.setState({ filter: '' })}>
                                Todos
                            </button>
                        </li>
                        <li className={filter === 'web' ? 'is-active' : ''}>
                            <button type="button" className="button" onClick={() => this.setState({ filter: 'web' })}>
                                Site
                            </button>
                        </li>
                        <li className={filter === 'email' ? 'is-active' : ''}>
                            <button type="button" className="button" onClick={() => this.setState({ filter: 'email' })}>
                                E-mail
                            </button>
                        </li>
                    </ul>
                </nav>

                {this.getChart()}
            </>
        );
    }
}

export default withNotifications(NewsletterProgressionChart);
