import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import WorkGroupRevisionCollection from '../../collections/WorkGroup/WorkGroupRevisionCollection';

export default function WorkGroupRevisionIndexPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Histórico de grupo de trabalho"
            tabs={[
                { linkTo: `/grupos-de-trabalho/${id}`, text: 'Editar' },
                { linkTo: `/grupos-de-trabalho/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <WorkGroupRevisionCollection id={id} request="listUserRevisions" linkToPrefix="grupos-de-trabalho" />
        </DefaultLayout>
    );
}
