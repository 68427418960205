import React from 'react';
import { EditorState, RichUtils } from 'draft-js';

import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import LinkForm from './LinkForm';

class LinkTool extends React.Component {
    constructor(props) {
        super(props);
        this.promptForLink = this.promptForLink.bind(this);
        this.confirmLink = this.confirmLink.bind(this);
        this.removeLink = this.removeLink.bind(this);
    }

    promptForLink(event) {
        event.stopPropagation();
        const { editorState, dialogs } = this.props;
        const selection = editorState.getSelection();

        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = editorState.getSelection().getStartKey();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const startOffset = editorState.getSelection().getStartOffset();
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

            let href = '';
            let target = '_blank';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                href = linkInstance.getData().href;
                target = linkInstance.getData().target;
            }

            dialogs.add(
                dialogsKeys.editorBlock,
                'Adicionar Link',
                <LinkForm href={href} target={target} confirmLink={this.confirmLink} />
            );
        }
    }

    confirmLink(href, target) {
        const { editorState, setEditorState } = this.props;

        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { href, target });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.push(editorState, contentStateWithEntity, 'apply-entity');

        setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
    }

    removeLink(event) {
        event.stopPropagation();
        const { editorState, setEditorState } = this.props;
        const selection = editorState.getSelection();

        if (!selection.isCollapsed()) {
            setEditorState(RichUtils.toggleLink(editorState, selection, null));
        }
    }

    render() {
        const { editorState } = this.props;
        const selection = editorState.getSelection();

        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = selection.getStartKey();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);

            if (blockWithLinkAtBeginning && blockWithLinkAtBeginning.getType() !== 'atomic') {
                const startOffset = editorState.getSelection().getStartOffset();
                const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
                let linkInstance = null;

                if (linkKey) {
                    linkInstance = contentState.getEntity(linkKey);
                }

                return (
                    <>
                        <button
                            type="button"
                            className={`button is-primary ${linkInstance ? 'is-active' : ''}`}
                            onClick={this.promptForLink}
                        >
                            {linkInstance ? 'Editar link' : 'Adicionar link'}
                        </button>
                        {linkInstance && (
                            <button type="button" className="button is-danger" onClick={this.removeLink}>
                                Remover Link
                            </button>
                        )}
                    </>
                );
            }

            return null;
        }

        return null;
    }
}

export default withDialogs(LinkTool);
