import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import OpinionRevisionCollection from '../../collections/Opinion/OpinionRevisionCollection';

export default function OpinionRevisionIndexPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Histórico de opinião do especialista"
            tabs={[
                { linkTo: `/opiniao-do-especialista/${id}`, text: 'Editar' },
                { linkTo: `/opiniao-do-especialista/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <OpinionRevisionCollection id={id} request="listUserRevisions" linkToPrefix="opiniao-do-especialista" />
        </DefaultLayout>
    );
}
