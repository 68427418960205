import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterRedirectsCountGroupedByUrlCollection from '../../collections/Newsletter/NewsletterRedirectCountGroupedByUrlCollection';
import NewsletterRedirectsCollection from '../../collections/Newsletter/NewsletterRedirectCollection';
import NewsletterImpressionsCollection from '../../collections/Newsletter/NewsletterImpressionCollection';

export default function NewsletterMetricsPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Métricas de boletim"
            tabs={[
                { linkTo: `/boletins/${id}`, text: 'Editar' },
                { linkTo: `/boletins/${id}/metricas`, text: 'Métricas' },
                { linkTo: `/boletins/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <br />
            <h2 className="subtitle">Links mais clicados</h2>
            <NewsletterRedirectsCountGroupedByUrlCollection id={id} />
            <hr className="is-invisible" />
            <h2 className="subtitle">Visualizações</h2>
            <NewsletterImpressionsCollection id={id} />
            <hr className="is-invisible" />
            <h2 className="subtitle">Redirecionamentos</h2>
            <NewsletterRedirectsCollection id={id} />
        </DefaultLayout>
    );
}
