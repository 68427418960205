import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { send } from '../../requests/newsletter';
import withNotifications from '../../contexts/withNotifications';
import withDialogs from '../../contexts/withDialogs';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';
import SelectField from '../../components/Forms/SelectField';
import DateTimeField from '../../components/Forms/DateTimeField';

const initialValues = {
    recipients: '',
    email: '',
    schedule: '',
    schedule_datetime: '',
};

const validationSchema = Yup.object({
    recipients: Yup.string().required('Campo obrigatório'),
    email: Yup.string().when('recipients', {
        is: 'email',
        then: Yup.string().email('Endereço de e-mail inválido').required('Campo obrigatório'),
    }),
    schedule: Yup.bool().when('recipients', { is: 'default', then: Yup.bool().required('Campo obrigatório') }),
    schedule_datetime: Yup.string().when('schedule', { is: true, then: Yup.string().required('Campo obrigatório') }),
});

class NewsletterSendForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        send(this, this.props.id, values);
    }

    render() {
        const { id } = this.props;
        const { isLoading } = this.state;

        return (
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
            >
                {({ values, errors, touched }) => (
                    <Form>
                        <div className="columns">
                            <div className="column is-one-third">
                                <SelectField
                                    name="recipients"
                                    label="Destinatário(s)"
                                    as="select"
                                    errors={errors.recipients}
                                    touched={touched.recipients}
                                >
                                    <option value="">Selecione uma opção...</option>
                                    <option value="email">E-mail único</option>
                                    <option value="default">Todos os destinatários</option>
                                </SelectField>
                                {values.recipients && values.recipients === 'email' && (
                                    <Field
                                        name="email"
                                        label="E-mail"
                                        type="email"
                                        autoComplete="email"
                                        errors={errors.email}
                                        touched={touched.email}
                                    />
                                )}
                                {values.recipients && values.recipients === 'default' && (
                                    <>
                                        <Field
                                            name="schedule"
                                            label="Enviar em"
                                            as="select"
                                            errors={errors.schedule}
                                            touched={touched.schedule}
                                        >
                                            <option value="">Selecione uma opção...</option>
                                            <option value="0">Enviar agora</option>
                                            <option value="1">Programar envio</option>
                                        </Field>
                                        {values.schedule && values.schedule === '1' && (
                                            <DateTimeField
                                                name="schedule_datetime"
                                                label="Data e hora do envio"
                                                errors={errors.schedule_datetime}
                                                touched={touched.schedule_datetime}
                                            />
                                        )}
                                    </>
                                )}
                            </div>

                            <div className="column is-two-thirds">
                                <h3 className="subtitle">Pré-visualização</h3>
                                <div className="NewsletterSendForm__container">
                                    <iframe
                                        title="Pré-visualização do boletim"
                                        className="NewsletterSendForm__preview"
                                        src={`${process.env.REACT_APP_BASE_URL}/boletins/${id}/pre-visualizacao/e-mail`}
                                    />
                                    <a
                                        href={`${process.env.REACT_APP_BASE_URL}/boletins/${id}/pre-visualizacao/e-mail`}
                                        className="NewsletterSendForm__link button is-small"
                                    >
                                        Ver em tela cheia
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="actions actions--sticky">
                            <button
                                type="submit"
                                className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                                disabled={isLoading}
                            >
                                Enviar boletim
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withNotifications(withDialogs(withRouter(NewsletterSendForm)));
