import React from 'react';
import { Form as FormikForm } from 'formik';
import { debounce } from 'lodash';

import withNotifications from '../../contexts/withNotifications';

class Form extends React.Component {
    componentDidMount() {
        const { storeValues } = this.props;

        if (storeValues) {
            this.debouncedStoreValues = debounce(storeValues, 5000);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { storeValues, values, initialValues, submitCount, isValid, notifications } = this.props;

        if (storeValues && values && initialValues) {
            this.debouncedStoreValues(values, initialValues);
        }

        if (isValid === false && submitCount > prevProps.submitCount) {
            notifications.add('Há erros no formulário.', 'danger');
        }
    }

    render() {
        const { children } = this.props;

        return <FormikForm noValidate>{children}</FormikForm>;
    }
}

export default withNotifications(Form);
