import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ClippingCollection from '../../collections/Clipping/ClippingCollection';

export default function ClippingRemovedIndexPage() {
    return (
        <DefaultLayout
            title="Na mídia removidos"
            tabs={[
                { linkTo: '/na-midia', text: 'Ativas' },
                { linkTo: '/na-midia/removidos', text: 'Removidos' },
            ]}
        >
            <ClippingCollection paginateRequest="paginateRemoved" />
        </DefaultLayout>
    );
}
