import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import PostCreateOrUpdateForm from '../../forms/Post/PostCreateOrUpdateForm';

export default function PostRemovedPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Notícia removida" isFullWidth>
            <PostCreateOrUpdateForm id={id} onSubmitRequest="restore" />
        </DefaultLayout>
    );
}
