import React from 'react';
import { Link } from 'react-router-dom';

import { paginate, paginateRemoved } from '../../requests/newsletter-recipient';
import withNotifications from '../../contexts/withNotifications';
import PaginatedTable from '../../components/Collections/PaginatedTable';

class NewsletterRecipientCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            links: null,
            meta: null,
        };
        this.paginate = this.paginate.bind(this);
    }

    paginate(page) {
        const { paginateRequest } = this.props;

        if (paginateRequest === 'paginateRemoved') {
            paginateRemoved(this, page);
        } else {
            paginate(this, page);
        }
    }

    render() {
        const { paginateRequest } = this.props;
        const { data, links, meta } = this.state;

        return (
            <div className="box">
                <PaginatedTable
                    listFn={this.paginate}
                    data={data}
                    links={links}
                    meta={meta}
                    headers={['E-mail', 'Status']}
                    row={(data) => (
                        <tr key={data.id}>
                            <td>
                                <Link
                                    to={`/destinatarios-de-boletim${
                                        paginateRequest === 'paginateRemoved' ? '/removidos' : ''
                                    }/${data.id}`}
                                >
                                    {data.email}
                                </Link>
                            </td>
                            <td>{data.active ? 'Inscrito(a)' : 'Desinscrito(a)'}</td>
                        </tr>
                    )}
                />
            </div>
        );
    }
}

export default withNotifications(NewsletterRecipientCollection);
