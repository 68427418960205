import React from 'react';
import { EditorState } from 'draft-js';
import Immutable from 'immutable';

import InlineStyle from '../../editor/InlineStyle';
import BlockStyle from '../../editor/BlockStyle';
import LinkTool from '../../editor/Link/LinkTool';
import BlockquoteTool from '../../editor/Blockquote/BlockquoteTool';
import ImageTool from '../../editor/Image/ImageTool';
import YouTubeTool from '../../editor/YouTube/YouTubeTool';
import SoundCloudTool from '../../editor/SoundCloud/SoundCloudTool';
import BlockquoteBlock from '../../editor/Blockquote/BlockquoteBlock';
import ImageBlock from '../../editor/Image/ImageBlock';
import YouTubeBlock from '../../editor/YouTube/YouTubeBlock';
import SoundCloudBlock from '../../editor/SoundCloud/SoundCloudBlock';
import Editor from './Editor';

const blockRenderMap = Immutable.Map({
    'header-two': { element: 'h2', aliasedElements: ['h1', 'h3', 'h4', 'h5', 'h6'] },
    'unordered-list-item': { element: 'li', wrapper: <ul /> },
    atomic: { element: 'figure' },
    unstyled: { element: 'div', aliasedElements: ['p', 'span', 'blockquote'] },
});

export default class EditorField extends React.Component {
    constructor(props) {
        super(props);
        this.blockRenderer = this.blockRenderer.bind(this);
        this.blockStyle = this.blockStyle.bind(this);
    }

    blockRenderer(block) {
        const type = block.getType();
        const { editorState, setEditorState } = this.props;

        if (type === 'atomic') {
            if (block.getEntityAt(0)) {
                const contentState = this.props.editorState.getCurrentContent();
                const entity = contentState.getEntity(block.getEntityAt(0));
                const type = entity.getType();

                switch (type) {
                    case 'BLOCKQUOTE':
                        return {
                            component: BlockquoteBlock,
                            editable: false,
                            props: { editorState, setEditorState },
                        };
                    case 'IMAGE':
                        return {
                            component: ImageBlock,
                            editable: false,
                            props: { editorState, setEditorState },
                        };
                    case 'YOUTUBE':
                        return {
                            component: YouTubeBlock,
                            editable: false,
                            props: { editorState, setEditorState },
                        };
                    case 'SOUNDCLOUD':
                        return {
                            component: SoundCloudBlock,
                            editable: false,
                            props: { editorState, setEditorState },
                        };
                    default:
                        return null;
                }
            }
        }
    }

    blockStyle(block) {
        if (block.getEntityAt(0)) {
            const contentState = this.props.editorState.getCurrentContent();
            const entity = contentState.getEntity(block.getEntityAt(0));
            const type = entity.getType();
            const { position } = entity.getData();

            if (type === 'LINK') {
                return 'paragraph';
            }

            return `cdts-atomic cdts-atomic--${type.toLowerCase()} cdts-atomic--${position}`;
        } else {
            switch (block.getType()) {
                case 'unstyled':
                    return 'paragraph';
                default:
                    return null;
            }
        }
    }

    render() {
        const { editorState, setEditorState } = this.props;

        return (
            <Editor
                editorState={editorState}
                setEditorState={setEditorState}
                blockRenderMap={blockRenderMap}
                blockRendererFn={this.blockRenderer}
                blockStyleFn={this.blockStyle}
            >
                <div className="Editor__toolbar">
                    <div className="buttons are-small">
                        <button
                            type="button"
                            className="button"
                            disabled={editorState === EditorState.undo(editorState)}
                            onClick={() => setEditorState(EditorState.undo(editorState))}
                        >
                            Desfazer
                        </button>
                        <button
                            type="button"
                            className="button"
                            disabled={editorState === EditorState.redo(editorState)}
                            onClick={() => setEditorState(EditorState.redo(editorState))}
                        >
                            Refazer
                        </button>
                        <InlineStyle name="BOLD" editorState={editorState} setEditorState={setEditorState}>
                            Negrito
                        </InlineStyle>
                        <InlineStyle name="ITALIC" editorState={editorState} setEditorState={setEditorState}>
                            Itálico
                        </InlineStyle>
                        <LinkTool editorState={editorState} setEditorState={setEditorState} />
                    </div>
                    <div className="buttons">
                        <BlockStyle name="header-two" editorState={editorState} setEditorState={setEditorState}>
                            Título
                        </BlockStyle>
                        <BlockStyle
                            name="unordered-list-item"
                            editorState={editorState}
                            setEditorState={setEditorState}
                        >
                            Lista
                        </BlockStyle>
                        <BlockquoteTool editorState={editorState} setEditorState={setEditorState} />
                        <ImageTool editorState={editorState} setEditorState={setEditorState} />
                        <YouTubeTool editorState={editorState} setEditorState={setEditorState} />
                        <SoundCloudTool editorState={editorState} setEditorState={setEditorState} />
                    </div>
                </div>
            </Editor>
        );
    }
}
