import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterCreateOrUpdateForm from '../../forms/Newsletter/NewsletterCreateOrUpdateForm';

export default function NewsletterRevisionPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Versão de boletim">
            <NewsletterCreateOrUpdateForm id={id} showRequest="revision" />
        </DefaultLayout>
    );
}
