import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextareaAutosize from 'react-autosize-textarea';

import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import { getImagePropsFromAtomicParams } from '../../services/editor';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';
import ImageField from '../../components/Forms/ImageField';
import ImageElement from './ImageElement';

export const validationSchema = Yup.object({
    image: Yup.object().shape({
        id: Yup.number().required('Campo obrigatório'),
    }),
});

class ImageForm extends React.Component {
    constructor(props) {
        super(props);
        this.openImageField = this.openImageField.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    openImageField(setFieldValue) {
        const { width, height, aspectRatio } = getImagePropsFromAtomicParams(this.props.atomicParams);

        this.props.dialogs.add(
            dialogsKeys.imageField,
            'Gerenciador de Mídias',
            <ImageField
                mimeType="image/jpeg"
                width={width}
                height={height}
                aspectRatio={aspectRatio}
                setImageId={(value) => setFieldValue('image.id', value)}
                setImageUrl={(value) => setFieldValue('image.url', value)}
            />,
            true
        );
    }

    onSubmit(values) {
        const { confirmImage, dialogs, atomicParams } = this.props;
        const { position, orientation } = atomicParams;
        const { width, height } = getImagePropsFromAtomicParams(atomicParams);

        confirmImage(values.image, values.caption, width, height, position, orientation);
        dialogs.remove(dialogsKeys.editorBlock);
    }

    render() {
        const { width, height } = getImagePropsFromAtomicParams(this.props.atomicParams);
        const { image, caption } = this.props;

        const initialValues = {
            image: image || '',
            caption: caption || '',
        };

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit}>
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        {values.image && values.image.id && (
                            <>
                                <figure
                                    className="cdts-atomic cdts-atomic--image"
                                    style={{
                                        width: `${parseInt(width / 2, 10)}px`,
                                        margin: '0 auto',
                                    }}
                                >
                                    <ImageElement
                                        image={values.image}
                                        caption={values.caption}
                                        width={width}
                                        height={height}
                                    />
                                </figure>
                            </>
                        )}
                        <div className="field">
                            <p className="label">Imagem</p>
                            {image && image.id ? (
                                <div className="buttons">
                                    <button
                                        type="button"
                                        className="button"
                                        onClick={() => this.openImageField(setFieldValue)}
                                    >
                                        Trocar imagem
                                    </button>
                                    <button
                                        type="button"
                                        className="button is-danger is-light"
                                        onClick={() => {
                                            setFieldValue('image.id', '');
                                            setFieldValue('image.url', '');
                                        }}
                                    >
                                        Remover imagem
                                    </button>
                                </div>
                            ) : (
                                <>
                                    <button
                                        type="button"
                                        className="button"
                                        onClick={() => this.openImageField(setFieldValue)}
                                    >
                                        Adicionar imagem
                                    </button>
                                    {touched && errors && touched.image && errors.image && (
                                        <div className="help is-danger">{errors.image.id}</div>
                                    )}
                                </>
                            )}
                        </div>
                        <Field
                            name="caption"
                            label="Legenda"
                            as={TextareaAutosize}
                            errors={errors.caption}
                            touched={touched.caption}
                        />
                        <div className="actions actions--sticky">
                            <button type="submit" className="button is-primary is-medium">
                                Confirmar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withDialogs(ImageForm);
