import React from 'react';
import { RichUtils } from 'draft-js';

export default class InlineStyle extends React.Component {
    constructor(props) {
        super(props);
        this.onToggle = this.onToggle.bind(this);
    }

    onToggle(event) {
        event.stopPropagation();
        const { editorState, setEditorState, name } = this.props;

        setEditorState(RichUtils.toggleInlineStyle(editorState, name));
    }

    render() {
        const { editorState, name, children } = this.props;
        const selection = editorState.getSelection();

        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const blockAtBeginning = contentState.getBlockForKey(selection.getStartKey());

            if (blockAtBeginning && blockAtBeginning.getType() !== 'atomic') {
                const currentStyle = editorState.getCurrentInlineStyle();

                return (
                    <button
                        type="button"
                        className={`button is-primary ${currentStyle && currentStyle.has(name) ? 'is-active' : ''}`}
                        onClick={this.onToggle}
                    >
                        {children}
                    </button>
                );
            }

            return null;
        }

        return null;
    }
}
