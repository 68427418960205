import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import PostCreateOrUpdateForm from '../../forms/Post/PostCreateOrUpdateForm';
import PostRemoveForm from '../../forms/Post/PostRemoveForm';

export default function PostUpdatePage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Editar notícia"
            isFullWidth
            tabs={[
                { linkTo: `/noticias/${id}`, text: 'Editar' },
                { linkTo: `/noticias/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <PostCreateOrUpdateForm id={id} />
            <br />
            <PostRemoveForm id={id} />
        </DefaultLayout>
    );
}
