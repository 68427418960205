import { removeToken } from './authentication';

const REBOOT_MESSAGE_KEY = 'reboot_message';

/**
 * Retorna mensagens para os erros das requisições.
 *
 * @export
 * @param {AxiosError} error
 * @returns string
 */
export default function handleError(error) {
    if (process.env.NODE_ENV === 'development') {
        if (typeof error.toJSON === 'function') {
            console.error(error.toJSON());
        } else {
            console.error(error);
        }
    }

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        switch (error.response.status) {
            case 422:
                if (error.response.data && error.response.data.errors) {
                    const errors = Object.values(error.response.data.errors)[0];
                    if (Array.isArray(errors[0])) {
                        return errors[0][0];
                    }

                    return errors[0];
                }

                return 'Há erros no formulário.';
            case 404:
                return 'Nenhum resultado encontrado.';
            case 429:
                return 'Muitas requisições. Tente novamente em alguns minutos.';
            case 500:
                return 'Erro no servidor. A falha foi registrada.';
            default:
                if (error.response.data && error.response.data.message) {
                    return error.response.data.message;
                }

                return 'Ocorreu um erro, por favor tente novamente.';
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser
        // and an instance of http.ClientRequest in node.js
        return 'Erro ao receber resposta, por favor tente novamente.';
    } else {
        // Something happened in setting up the request that triggered an Error
        return 'Ocorreu um erro inesperado, por favor tente atualizar a página.';
    }
}

/**
 * Remove o token de autenticação, gera mensagem de reinicialização
 * e reinicia o app.
 *
 * @export
 */
export function authReboot() {
    removeToken();
    sessionStorage.setItem(
        REBOOT_MESSAGE_KEY,
        'A sua chave de autenticação precisa ser renovada. Por favor, entre novamente.'
    );
    window.location.href = '/entrar';
}

/**
 * Notifica sobre a reinicialização do app a um componente.
 *
 * @export
 * @param {React.Component} component
 */
export function rebooted(component) {
    const { notifications } = component.props;

    if (sessionStorage.getItem(REBOOT_MESSAGE_KEY)) {
        notifications.add(sessionStorage.getItem(REBOOT_MESSAGE_KEY), 'info');
        sessionStorage.removeItem(REBOOT_MESSAGE_KEY);
    }
}
