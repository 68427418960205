import React from 'react';

import { listRevisions } from '../../requests/user';
import withNotifications from '../../contexts/withNotifications';
import PaginatedTable from '../../components/Collections/PaginatedTable';
import RevisionTableRow, { revisionTableHeaders } from '../../components/Collections/RevisionTable';

class UserRevisionCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            links: null,
            meta: null,
        };
        this.paginate = this.paginate.bind(this);
    }

    paginate(page) {
        const { id } = this.props;

        listRevisions(this, id, page);
    }

    render() {
        const { data, links, meta } = this.state;

        return (
            <div className="box">
                <PaginatedTable
                    listFn={this.paginate}
                    data={data}
                    links={links}
                    meta={meta}
                    headers={revisionTableHeaders}
                    row={(data) => <RevisionTableRow key={data.id} data={data} linkToPrefix="usuarios" />}
                />
            </div>
        );
    }
}

export default withNotifications(UserRevisionCollection);
