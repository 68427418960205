import React from 'react';

import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import AtomicForm from '../AtomicForm';
import YouTubeForm from './YouTubeForm';

class YouTubePrompter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            atomicParams: null,
        };
        this.promptAtomicForm = this.promptAtomicForm.bind(this);
        this.promptYouTubeForm = this.promptYouTubeForm.bind(this);
    }

    promptAtomicForm(event) {
        event.stopPropagation();
        const { position } = this.props;
        const atomicParams = position ? { position } : null;

        this.props.dialogs.add(
            dialogsKeys.editorBlock,
            this.props.url ? 'Editar YouTube' : 'Adicionar YouTube',
            <AtomicForm atomicParams={atomicParams} setAtomicParams={this.promptYouTubeForm} />
        );
    }

    promptYouTubeForm(atomicParams) {
        this.props.dialogs.remove(dialogsKeys.editorBlock);
        const { url, caption } = this.props;

        this.props.dialogs.add(
            dialogsKeys.editorBlock,
            this.props.url ? 'Editar YouTube' : 'Adicionar YouTube',
            <YouTubeForm
                url={url}
                caption={caption}
                atomicParams={atomicParams}
                confirmYouTube={this.props.confirmYouTube}
            />
        );
    }

    render() {
        const { isToolButton, children } = this.props;

        return (
            <button
                type="button"
                className={`button ${isToolButton ? 'is-primary is-light' : ''}`}
                onClick={this.promptAtomicForm}
            >
                {children}
            </button>
        );
    }
}

export default withDialogs(YouTubePrompter);
