import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import UserRequestCreateForm from '../../forms/User/UserRequestCreateForm';

export default function UserRequestCreatePage() {
    return (
        <DefaultLayout title="Convidar usuário">
            <UserRequestCreateForm />
        </DefaultLayout>
    );
}
