import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { getAuthenticatedUser, updateEmail } from '../../requests/authentication';
import withNotifications from '../../contexts/withNotifications';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';

const initialValues = {
    id: '',
    password: '',
    password_confirmation: '',
    email: '',
    role: 'user',
};

const schemaValidation = Yup.object({
    email: Yup.string()
        .required('Campo obrigatório')
        .email('Endereço de e-mail inválido')
        .max(191, 'O campo não pode ter mais que 191 caracteres'),
    password: Yup.string().required('Campo obrigatório'),
    password_confirmation: Yup.string()
        .required('Campo obrigatório')
        .oneOf([Yup.ref('password'), null], 'As senhas não coincidem'),
});

class AuthenticationUpdateEmailForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            authenticatedUser: initialValues,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        getAuthenticatedUser(this);
    }

    onSubmit(values) {
        updateEmail(this, values);
    }

    render() {
        const { authenticatedUser, isLoading } = this.state;

        return (
            <Formik
                initialValues={authenticatedUser}
                enableReinitialize
                validationSchema={schemaValidation}
                onSubmit={this.onSubmit}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className="box">
                            <div className="columns">
                                <div className="column">
                                    <Field
                                        name="email"
                                        label="Novo e-mail"
                                        type="email"
                                        autoComplete="email"
                                        errors={errors.email}
                                        touched={touched.email}
                                    />
                                </div>
                                <div className="column">
                                    <Field
                                        name="password"
                                        label="Senha"
                                        type="password"
                                        autoComplete="current-password"
                                        errors={errors.password}
                                        touched={touched.password}
                                    />
                                </div>
                                <div className="column">
                                    <Field
                                        name="password_confirmation"
                                        label="Confirmar senha"
                                        type="password"
                                        autoComplete="current-password"
                                        errors={errors.password_confirmation}
                                        touched={touched.password_confirmation}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="actions actions--sticky">
                            <button
                                type="submit"
                                className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                                disabled={isLoading}
                            >
                                Atualizar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withNotifications(withRouter(AuthenticationUpdateEmailForm));
