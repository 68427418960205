import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import MemberCreateOrUpdateForm from '../../forms/Member/MemberCreateOrUpdateForm';

export default function MemberCreatePage() {
    return (
        <DefaultLayout title="Adicionar membro" isFullWidth>
            <MemberCreateOrUpdateForm />
        </DefaultLayout>
    );
}
