import React from 'react';
import { withRouter } from 'react-router-dom';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import TextareaAutosize from 'react-autosize-textarea';
import moment from 'moment';
import 'moment/locale/pt-br';

import { show, create, update, showRemoved, showRevision, restore } from '../../requests/member';
import withNotifications from '../../contexts/withNotifications';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';
import RevisionData from '../../components/Forms/RevisionData';
import PublicationFields from '../../components/Forms/PublicationFields';
import TagsField from '../../components/Forms/TagsField';
import ImageField from '../../components/Forms/ImageField';
import LattesIcon from '../../components/Icons/LattesIcon';
import EmailIcon from '../../components/Icons/EmailIcon';
import WorkGroupsField from '../../components/Forms/WorkGroupsField';

moment.locale('pt-br');

export const initialValues = {
    name: '',
    job_title: '',
    bio: '',
    email: '',
    lattes_url: '',
    published: '1',
    published_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    image: {
        id: undefined,
        url: undefined,
    },
};

export const validationSchema = Yup.object({
    name: Yup.string().required('Campo obrigatório').max(191, 'O campo não pode ter mais que 191 caracteres'),
    job_title: Yup.string().required('Campo obrigatório').max(191, 'O campo não pode ter mais que 191 caracteres'),
    bio: Yup.string().required('Campo obrigatório'),
    email: Yup.string().nullable(),
    lattes_url: Yup.string().nullable(),
    published: Yup.bool().required('Campo obrigatório'),
    published_at: Yup.string().required('Campo obrigatório'),
});

class MemberCreateOrUpdateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            member: initialValues,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.openImageField = this.openImageField.bind(this);
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { id, showRequest, onSubmitRequest } = this.props;

        if (id) {
            if (showRequest === 'revision') {
                showRevision(this, id);
            } else if (onSubmitRequest === 'restore') {
                showRemoved(this, id);
            } else {
                show(this, id);
            }
        }
    }

    /**
     * Cria, atualiza ou restaura a opinião do especialista ao submeter o formulário.
     *
     * @param {*} values
     */
    onSubmit(values) {
        const { id, onSubmitRequest } = this.props;

        if (id) {
            if (onSubmitRequest === 'restore') {
                restore(this, id);
            } else {
                update(this, id, values);
            }
        } else {
            create(this, values);
        }
    }

    /**
     * Abre o ImageField para o campo image.
     */
    openImageField(setFieldValue) {
        const { dialogs } = this.props;

        dialogs.add(
            dialogsKeys.imageField,
            'Gerenciador de Mídias',
            <ImageField
                mimeType="image/jpeg"
                width={1920}
                height={1920}
                aspectRatio={1}
                setImageId={(value) => setFieldValue('image.id', value)}
                setImageUrl={(value) => setFieldValue('image.url', value)}
            />,
            true
        );
    }

    /**
     * @inheritdoc
     */
    render() {
        const { id, showRequest, disabled, onSubmitRequest } = this.props;
        const { isLoading, member, revision } = this.state;

        return (
            <Formik
                initialValues={member}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
            >
                {({ values, errors, touched, setFieldValue, isValid, submitCount }) => (
                    <Form
                        withErrorNotification
                        values={values}
                        initialValues={member}
                        isValid={isValid}
                        submitCount={submitCount}
                    >
                        <fieldset
                            className="cdts-member MemberCreateOrUpdateForm"
                            disabled={disabled || showRequest === 'revision' || onSubmitRequest === 'restore'}
                        >
                            <div className="cdts-cover">
                                <div className="cdts-cover__content">
                                    <div className="cdts-cover__core">
                                        <Field
                                            variant="MemberCreateOrUpdateForm"
                                            modifier="title"
                                            name="name"
                                            label="Nome"
                                            labelHidden
                                            as={TextareaAutosize}
                                            async
                                            placeholder="Nome"
                                            errors={errors && errors.title}
                                            touched={touched && touched.title}
                                        />
                                        <Field
                                            variant="MemberCreateOrUpdateForm"
                                            modifier="title-complement"
                                            name="job_title"
                                            label="Especialidade"
                                            labelHidden
                                            as={TextareaAutosize}
                                            async
                                            placeholder="Especialidade"
                                            errors={errors && errors.job_title}
                                            touched={touched && touched.job_title}
                                        />
                                        <div className="cdts-cover__bar" />
                                        <Field
                                            variant="MemberCreateOrUpdateForm"
                                            modifier="subtitle"
                                            name="bio"
                                            label="Breve biografia profissional"
                                            labelHidden
                                            placeholder="Breve biografia profissional"
                                            as={TextareaAutosize}
                                            async
                                            errors={errors && errors.subtitle}
                                            touched={touched && touched.subtitle}
                                        />
                                    </div>
                                    <div className="cdts-cover__footer">
                                        <hr className="cdts-cover__footer-hr" />
                                        <Field
                                            variant="MemberCreateOrUpdateForm"
                                            modifier="lattes_url"
                                            type="url"
                                            name="lattes_url"
                                            label={<LattesIcon title="Endereço do currículo Lattes" />}
                                            async
                                            placeholder="Endereço do currículo Lattes"
                                            errors={errors && errors.lattes_url}
                                            touched={touched && touched.lattes_url}
                                        />
                                        <Field
                                            variant="MemberCreateOrUpdateForm"
                                            modifier="email"
                                            type="email"
                                            name="email"
                                            label={<EmailIcon title="E-mail" />}
                                            async
                                            placeholder="E-mail"
                                            errors={errors && errors.email}
                                            touched={touched && touched.email}
                                        />
                                    </div>
                                </div>
                                <div className="cdts-cover__image">
                                    <div className="cdts-cover__image-inner image-buttons">
                                        {values.image && values.image.id ? (
                                            <>
                                                <img
                                                    src={`${process.env.REACT_APP_BASE_WEB_URL}${values.image.url}`}
                                                    alt=""
                                                    width={960}
                                                    height={960}
                                                    data-object-fit
                                                    className="cdts-cover__image-core"
                                                />
                                                <button
                                                    type="button"
                                                    className={`image-buttons__double button button-reset ${
                                                        isLoading ? 'is-loading' : ''
                                                    }`}
                                                    disabled={isLoading}
                                                    onClick={() => this.openImageField(setFieldValue)}
                                                >
                                                    Trocar imagem
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`image-buttons__double button button-reset ${
                                                        isLoading ? 'is-loading' : ''
                                                    }`}
                                                    disabled={isLoading}
                                                    onClick={() => {
                                                        setFieldValue('image.id', '');
                                                        setFieldValue('image.url', '');
                                                    }}
                                                >
                                                    Remover imagem
                                                </button>
                                            </>
                                        ) : (
                                            <button
                                                type="button"
                                                className={`image-buttons__single button button-reset ${
                                                    isLoading ? 'is-loading' : ''
                                                }`}
                                                disabled={isLoading}
                                                onClick={() => this.openImageField(setFieldValue)}
                                            >
                                                Adicionar imagem
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <hr className="is-invisible" />

                            <section className="contained">
                                <h2 className="subtitle">Grupos de trabalho</h2>
                                <div className="box">
                                    <FieldArray
                                        name="work_groups"
                                        render={(arrayHelpers) => (
                                            <WorkGroupsField
                                                values={values.work_groups}
                                                errors={errors.work_groups}
                                                touched={touched.work_groups}
                                                arrayHelpers={arrayHelpers}
                                                showRequest={showRequest}
                                            />
                                        )}
                                    />
                                </div>
                            </section>

                            <hr className="is-invisible" />

                            <section className="contained">
                                <h2 className="subtitle">Tags</h2>
                                <div className="box">
                                    <FieldArray
                                        name="tags"
                                        render={(arrayHelpers) => (
                                            <TagsField
                                                values={values.tags}
                                                errors={errors.tags}
                                                touched={touched.tags}
                                                arrayHelpers={arrayHelpers}
                                                showRequest={showRequest}
                                            />
                                        )}
                                    />
                                </div>
                            </section>

                            <hr className="is-invisible" />

                            <section className="contained">
                                <PublicationFields errors={errors} touched={touched} />
                            </section>
                        </fieldset>

                        {showRequest === 'revision' && revision && (
                            <>
                                <hr className="invisible" />
                                <div className="contained">
                                    <RevisionData revision={revision} />
                                </div>
                            </>
                        )}

                        {showRequest !== 'revision' && (
                            <>
                                <hr className="invisible" />
                                <div className="contained buttons actions actions--sticky">
                                    <button
                                        type="submit"
                                        className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                                        disabled={isLoading}
                                    >
                                        {onSubmitRequest === 'restore' ? 'Restaurar' : id ? 'Editar' : 'Adicionar'}
                                    </button>
                                </div>
                            </>
                        )}

                        {showRequest !== 'revision' &&
                            onSubmitRequest !== 'restore' &&
                            member.slug &&
                            Boolean(member.published) && (
                                <div className="contained buttons is-justified-center">
                                    <a
                                        className={`button is-light is-info ${isLoading ? 'is-loading' : ''}`}
                                        href={`${process.env.REACT_APP_BASE_WEB_URL}/membros/${member.slug}`}
                                    >
                                        Visualizar no site
                                    </a>
                                </div>
                            )}
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withDialogs(withNotifications(withRouter(MemberCreateOrUpdateForm)));
