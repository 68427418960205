import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import MemberCreateOrUpdateForm from '../../forms/Member/MemberCreateOrUpdateForm';

export default function MemberRemovedPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Membro removido" isFullWidth>
            <MemberCreateOrUpdateForm id={id} onSubmitRequest="restore" />
        </DefaultLayout>
    );
}
