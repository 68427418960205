import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { updatePassword } from '../../requests/authentication';
import withNotifications from '../../contexts/withNotifications';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';

const initialValues = {
    password: '',
    new_password: '',
    new_password_confirmation: '',
};

const validationSchema = Yup.object({
    password: Yup.string().required('Campo obrigatório'),
    new_password: Yup.string()
        .required('Campo obrigatório')
        .matches(
            /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*+]).*$/,
            'A nova senha deve conter letras maiúsculas e minúsculas, números e símbolos (!@#$%&*+).'
        )
        .min(8, 'A senha precisa ter no mínimo 8 caracteres'),
    new_password_confirmation: Yup.string()
        .required('Campo obrigatório')
        .oneOf([Yup.ref('new_password'), null], 'As senhas não coincidem'),
});

class AuthenticationUpdatePasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        updatePassword(this, values);
    }

    render() {
        const { isLoading } = this.state;

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit}>
                {({ errors, touched }) => (
                    <Form>
                        <div className="box">
                            <div className="columns">
                                <div className="column">
                                    <Field
                                        name="password"
                                        label="Senha atual"
                                        type="password"
                                        autoComplete="current-password"
                                        errors={errors.password}
                                        touched={touched.password}
                                    />
                                </div>
                                <div className="column">
                                    <Field
                                        name="new_password"
                                        label="Nova senha"
                                        type="password"
                                        autoComplete="new-password"
                                        errors={errors.new_password}
                                        touched={touched.new_password}
                                    />
                                </div>
                                <div className="column">
                                    <Field
                                        name="new_password_confirmation"
                                        label="Confirmar nova senha"
                                        type="password"
                                        autoComplete="new-password"
                                        errors={errors.new_password_confirmation}
                                        touched={touched.new_password_confirmation}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="actions actions--sticky">
                            <button
                                type="submit"
                                className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                                disabled={isLoading}
                            >
                                Atualizar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withNotifications(withRouter(AuthenticationUpdatePasswordForm));
