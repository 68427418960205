import React from 'react';

import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import AtomicForm from '../AtomicForm';
import BlockquoteForm from './BlockquoteForm';

class BlockquotePrompter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            atomicParams: null,
        };
        this.promptAtomicForm = this.promptAtomicForm.bind(this);
        this.promptBlockquoteForm = this.promptBlockquoteForm.bind(this);
    }

    promptAtomicForm(event) {
        event.stopPropagation();
        const { position } = this.props;
        const atomicParams = position ? { position } : null;

        this.props.dialogs.add(
            dialogsKeys.editorBlock,
            this.props.text ? 'Editar olho' : 'Adicionar olho',
            <AtomicForm atomicParams={atomicParams} setAtomicParams={this.promptBlockquoteForm} />
        );
    }

    promptBlockquoteForm(atomicParams) {
        this.props.dialogs.remove(dialogsKeys.editorBlock);
        const { text } = this.props;

        this.props.dialogs.add(
            dialogsKeys.editorBlock,
            this.props.text ? 'Editar olho' : 'Adicionar olho',
            <BlockquoteForm text={text} atomicParams={atomicParams} confirmBlockquote={this.props.confirmBlockquote} />
        );
    }

    render() {
        const { isToolButton, children } = this.props;

        return (
            <button
                type="button"
                className={`button ${isToolButton ? 'is-primary is-light' : ''}`}
                onClick={this.promptAtomicForm}
            >
                {children}
            </button>
        );
    }
}

export default withDialogs(BlockquotePrompter);
