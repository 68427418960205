import React from 'react';

import { editBlock, deleteBlock } from '../../services/editor';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import SoundCloudPrompter from './SoundCloudPrompter';
import SoundCloudElement from './SoundCloudElement';

class SoundCloudBlock extends React.Component {
    constructor(props) {
        super(props);
        this.confirmSoundCloud = this.confirmSoundCloud.bind(this);
        this.removeSoundCloud = this.removeSoundCloud.bind(this);
    }

    confirmSoundCloud(url, caption, position) {
        editBlock(this, { url, caption, position });
        this.props.dialogs.remove(dialogsKeys.editorBlock);
    }

    removeSoundCloud(event) {
        event.stopPropagation();
        deleteBlock(this);
    }

    render() {
        const { block, contentState } = this.props;
        const { editorState, setEditorState } = this.props.blockProps;
        const { url, caption, position } = contentState.getEntity(block.getEntityAt(0)).getData();

        return (
            <div className="editor-block">
                <SoundCloudElement url={url} caption={caption} />
                <SoundCloudPrompter
                    editorState={editorState}
                    setEditorState={setEditorState}
                    url={url}
                    caption={caption}
                    position={position}
                    confirmSoundCloud={this.confirmSoundCloud}
                >
                    Editar SoundCloud
                </SoundCloudPrompter>
                <button type="button" className="button" onClick={this.removeSoundCloud}>
                    Remover SoundCloud
                </button>
            </div>
        );
    }
}

export default withDialogs(SoundCloudBlock);
