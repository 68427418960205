import React from 'react';
import { Link } from 'react-router-dom';

import DefaultLayout from '../../layouts/DefaultLayout';
import TagCollection from '../../collections/Tag/TagCollection';

export default function TagIndexPage() {
    return (
        <DefaultLayout
            title="Tags"
            tabs={[
                { linkTo: '/tags', text: 'Ativas' },
                { linkTo: '/tags/removidas', text: 'Removidas' },
            ]}
        >
            <TagCollection />
            <Link to="/tags/adicionar" className="button is-primary is-medium">
                Adicionar
            </Link>
        </DefaultLayout>
    );
}
