import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import TagCreateOrUpdateForm from '../../forms/Tag/TagCreateOrUpdateForm';

export default function TagCreatePage() {
    return (
        <DefaultLayout title="Adicionar tag">
            <TagCreateOrUpdateForm />
        </DefaultLayout>
    );
}
