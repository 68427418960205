import audioIcon from '../images/audio.svg';
import documentIcon from '../images/document.svg';
import pdfIcon from '../images/pdf.svg';
import spreadsheetIcon from '../images/spreadsheet.svg';
import videoIcon from '../images/video.svg';
import genericIcon from '../images/generic.svg';

export const imageMimeTypes = [
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/gif',
    'image/x-icon',
    'image/tiff',
    'image/webp',
];

export const pdfMimeTypes = ['application/pdf'];

export const documentMimeTypes = [
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text',
];

export const presentationMimeTypes = [
    'application/mspowerpoint',
    'application/powerpoint',
    'application/vnd.ms-powerpoint',
    'application/x-mspowerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/mspowerpoint',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
];

export const spreadsheetMimeTypes = [
    'application/excel',
    'application/vnd.ms-excel',
    'application/x-excel',
    'application/x-msexcel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const audioMimeTypes = [
    'audio/mpeg',
    'audio/x-mpeg',
    'audio/webm',
    'audio/mpeg3',
    'audio/x-mpeg-3',
    'audio/m4a',
    'audio/ogg',
    'audio/wav',
    'audio/x-wav',
    'audio/mpeg',
    'audio/3gpp',
    'audio/3gpp2',
];

export const videoMimeTypes = [
    'video/mpeg',
    'video/x-mpeg',
    'video/webm',
    'video/mp4',
    'video/x-m4v',
    'video/quicktime',
    'video/x-ms-asf',
    'video/x-ms-wmv',
    'application/x-troff-msvideo',
    'video/avi',
    'video/msvideo',
    'video/x-msvideo',
    'video/mpeg',
    'video/ogg',
    'video/3gpp',
    'video/3gpp2',
];

/**
 * Retorna o ícone de um mimetype.
 *
 * @export
 * @param {string} mimeType
 * @returns string
 */
export function getMimeTypeIcon(mimeType) {
    switch (true) {
        case audioMimeTypes.includes(mimeType):
            return audioIcon;
        case documentMimeTypes.includes(mimeType):
            return documentIcon;
        case pdfMimeTypes.includes(mimeType):
            return pdfIcon;
        case spreadsheetMimeTypes.includes(mimeType):
            return spreadsheetIcon;
        case videoMimeTypes.includes(mimeType):
            return videoIcon;
        default:
            return genericIcon;
    }
}

/**
 * Retorna a imagem ou, se for não for imagem, o ícone do mimetype correspondente.
 *
 * @export
 * @param string mimeType
 * @param string url
 * @returns
 */
export function getImageOrIcon(mimeType, url) {
    if (imageMimeTypes.includes(mimeType)) {
        return `${process.env.REACT_APP_BASE_WEB_URL}${url}`;
    }

    return getMimeTypeIcon(mimeType);
}

/**
 * Formata bytes em uma medida mais apropriada de acordo com seu tamanho.
 *
 * @export
 * @param {int} bytes
 * @param {number} [decimals=2]
 * @returns
 */
export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
        return '0 bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}
