import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import WorkGroupCreateOrUpdateForm from '../../forms/WorkGroup/WorkGroupCreateOrUpdateForm';

export default function WorkGroupRemovedPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Grupo de trabalho removido">
            <WorkGroupCreateOrUpdateForm id={id} onSubmitRequest="restore" />
        </DefaultLayout>
    );
}
