import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { show, create, update, restore, showRemoved, showRevision } from '../../requests/clipping-source';
import withNotifications from '../../contexts/withNotifications';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';
import RevisionData from '../../components/Forms/RevisionData';
import ImageField from '../../components/Forms/ImageField';

export const initialValues = {
    title: '',
    image: {
        id: '',
        url: '',
    },
};

export const validationSchema = Yup.object({
    title: Yup.string().required('Campo obrigatório').max(191, 'O campo não pode ter mais que 191 caracteres'),
    image: Yup.object().shape({
        id: Yup.number().required('Campo obrigatório'),
    }),
});

class ClippingSourceCreatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            clippingSource: initialValues,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { id, showRequest, onSubmitRequest } = this.props;

        if (id) {
            if (showRequest === 'revision') {
                showRevision(this, id);
            } else if (onSubmitRequest === 'restore') {
                showRemoved(this, id);
            } else {
                show(this, id);
            }
        }
    }

    /**
     * Cria, atualiza ou restaura a clippingSource ao submeter o formulário.
     *
     * @param {*} values
     */
    onSubmit(values) {
        const { id, onSubmitRequest, successCallbackFn } = this.props;

        if (id) {
            if (onSubmitRequest === 'restore') {
                restore(this, id);
            } else {
                update(this, id, values);
            }
        } else {
            if (successCallbackFn) {
                create(this, values, { successCallbackFn });
            } else {
                create(this, values);
            }
        }
    }

    openImageField(setFieldValue) {
        this.props.dialogs.add(
            dialogsKeys.imageField,
            'Gerenciador de Mídias',
            <ImageField
                mimeType="image/jpeg"
                width={960}
                height={540}
                aspectRatio={16 / 9}
                setImageId={(value) => setFieldValue('image.id', value)}
                setImageUrl={(value) => setFieldValue('image.url', value)}
            />,
            true
        );
    }

    /**
     * @inheritdoc
     */
    render() {
        const { id, showRequest, disabled, onSubmitRequest, withoutBox } = this.props;
        const { isLoading, clippingSource, revision } = this.state;

        return (
            <Formik
                initialValues={clippingSource}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <fieldset
                            className={!withoutBox ? 'box' : ''}
                            disabled={disabled || showRequest === 'revision' || onSubmitRequest === 'restore'}
                        >
                            <div className="columns">
                                <div className="column">
                                    <Field name="title" label="Título" errors={errors.title} touched={touched.title} />
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <p className="label">Imagem</p>
                                        {values.image && values.image.id ? (
                                            <div className="buttons">
                                                <button
                                                    type="button"
                                                    className={`button ${isLoading ? 'is-loading' : ''}`}
                                                    disabled={isLoading}
                                                    onClick={() => this.openImageField(setFieldValue)}
                                                >
                                                    Trocar imagem
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`button is-danger is-light ${
                                                        isLoading ? 'is-loading' : ''
                                                    }`}
                                                    disabled={isLoading}
                                                    onClick={() => {
                                                        setFieldValue('image.id', '');
                                                        setFieldValue('image.url', '');
                                                    }}
                                                >
                                                    Remover imagem
                                                </button>
                                            </div>
                                        ) : (
                                            <>
                                                <button
                                                    type="button"
                                                    className={`button ${isLoading ? 'is-loading' : ''}`}
                                                    disabled={isLoading}
                                                    onClick={() => this.openImageField(setFieldValue)}
                                                >
                                                    Adicionar imagem
                                                </button>
                                                {touched && errors && touched.image && errors.image && (
                                                    <div className="help is-danger">{errors.image.id}</div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    {values.image && values.image.id && (
                                        <img
                                            src={`${process.env.REACT_APP_BASE_WEB_URL}${values.image.url}`}
                                            alt=""
                                            width={480}
                                            height={270}
                                        />
                                    )}
                                </div>
                            </div>
                        </fieldset>

                        {showRequest === 'revision' && revision && (
                            <>
                                <hr className="invisible" />
                                <RevisionData revision={revision} />
                            </>
                        )}

                        {showRequest !== 'revision' && (
                            <>
                                <hr className="invisible" />
                                <div className="buttons actions actions--sticky">
                                    <button
                                        type="submit"
                                        className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                                        disabled={isLoading}
                                    >
                                        {onSubmitRequest === 'restore' ? 'Restaurar' : id ? 'Editar' : 'Adicionar'}
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withDialogs(withNotifications(withRouter(ClippingSourceCreatePage)));
