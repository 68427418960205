import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ClippingRevisionCollection from '../../collections/Clipping/ClippingRevisionCollection';

export default function ClippingRevisionIndexPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Histórico de fonte de mídia"
            tabs={[
                { linkTo: `/na-midia/${id}`, text: 'Editar' },
                { linkTo: `/na-midia/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <ClippingRevisionCollection id={id} request="listUserRevisions" linkToPrefix="opiniao-do-especialista" />
        </DefaultLayout>
    );
}
