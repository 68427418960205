import React from 'react';
import { Field as FormikField } from 'formik';
import { FastField as FormikFastField } from 'formik';

export default class Field extends React.Component {
    render() {
        const {
            variant,
            modifier,
            name,
            labelHidden,
            label,
            type,
            autoComplete,
            placeholder,
            as,
            component,
            style,
            async,
            isStatic,
            isUppercase,
            errors,
            touched,
            children,
            isFastField,
        } = this.props;

        return (
            <div
                className={
                    variant
                        ? `variant-field ${variant}__field ${modifier ? `${variant}__field--${modifier}` : ''}`
                        : 'field'
                }
            >
                <label htmlFor={name} className={`label ${labelHidden ? 'is-sr-only' : ''}`}>
                    {label}
                </label>
                <div
                    className={
                        variant
                            ? `variant-control ${variant}__control ${
                                  modifier ? `${variant}__control--${modifier}` : ''
                              }`
                            : 'control'
                    }
                >
                    {isFastField ? (
                        <FormikFastField
                            id={name}
                            name={name}
                            type={type}
                            autoComplete={autoComplete}
                            placeholder={placeholder}
                            as={as}
                            component={component}
                            style={style}
                            async={async}
                            className={
                                variant
                                    ? `variant-input ${errors ? 'is-danger' : ''}${variant}__input ${
                                          modifier ? `${variant}__input--${modifier}` : ''
                                      }`
                                    : `input ${errors && touched ? 'is-danger' : ''} ${isStatic ? 'is-static' : ''}`
                            }
                        >
                            {children}
                        </FormikFastField>
                    ) : (
                        <FormikField
                            id={name}
                            name={name}
                            type={type}
                            autoComplete={autoComplete}
                            placeholder={placeholder}
                            as={as}
                            component={component}
                            style={style}
                            async={async}
                            className={
                                variant
                                    ? `variant-input ${errors ? 'is-danger' : ''}${variant}__input ${
                                          modifier ? `${variant}__input--${modifier}` : ''
                                      }`
                                    : `input ${errors && touched ? 'is-danger' : ''} ${isStatic ? 'is-static' : ''} ${
                                          isUppercase ? 'is-uppercase' : ''
                                      }`
                            }
                        >
                            {children}
                        </FormikField>
                    )}
                    {errors && touched && <div className="help is-danger">{errors}</div>}
                </div>
            </div>
        );
    }
}
