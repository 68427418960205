/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';

export default class Select extends React.Component {
    render() {
        const {
            name,
            label,
            labelHidden,
            value,
            defaultValue,
            withAddOn,
            onChange,
            errors,
            touched,
            children,
            isLoading,
            errorString,
        } = this.props;

        return (
            <>
                {withAddOn && (
                    <label htmlFor={name} className={`label ${labelHidden ? 'is-sr-only' : ''}`}>
                        {label}
                    </label>
                )}
                <div className={`field ${withAddOn ? 'has-addons' : ''}`}>
                    {!withAddOn && (
                        <label htmlFor={name} className={`label${labelHidden ? ' is-sr-only' : ''}`}>
                            {label}
                        </label>
                    )}
                    <div className="control">
                        <div className="select">
                            <select
                                id={name}
                                name={name}
                                value={value || defaultValue}
                                onChange={onChange}
                                className={`input ${errors && touched ? 'is-danger' : ''} ${
                                    isLoading ? 'is-loading' : ''
                                }`}
                            >
                                {children}
                            </select>
                        </div>
                        {errors && touched && <div className="help is-danger">{errorString || errors}</div>}
                    </div>
                    {withAddOn && <div className="control">{withAddOn}</div>}
                </div>
            </>
        );
    }
}
