import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterCreateOrUpdateForm from '../../forms/Newsletter/NewsletterCreateOrUpdateForm';

export default function NewsletterCreatePage() {
    return (
        <DefaultLayout title="Adicionar boletim">
            <NewsletterCreateOrUpdateForm />
        </DefaultLayout>
    );
}
