import api from '../services/api';
import handleError from '../services/error-handler';
import { removeToken } from '../services/authentication';
import * as crud from './crud';
import * as softDeletes from './soft-deletes';
import * as revisionable from './revisionable';

const URL_PREFIX = 'usuarios';
const STATE_KEY = 'user';

/**
 * Faz a requisição de convite de cadastro no sistema.
 *
 * @export
 * @param {*} component
 * @param {*} values
 */
export function requestCreate(component, values) {
    const { notifications, history } = component.props;

    component.setState({ isLoading: true });

    api.post(`/${URL_PREFIX}/solicitar-cadastro`, values)
        .then((response) => {
            notifications.add(response.data.message, 'success');
            history.push('/');
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            component.setState({ isLoading: false });
        });
}

/**
 * Faz a requisição de criação de usuário.
 *
 * @export
 * @param {*} component
 * @param {*} values
 * @param {*} url
 */
export function create(component, values, url) {
    const { notifications, history } = component.props;

    component.setState({ isLoading: true });

    api.post(url, values)
        .then((response) => {
            notifications.add(response.data.message, 'success');
            removeToken();
            history.push('/entrar');
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            component.setState({ isLoading: false });
        });
}

/**
 * @export
 * @param {*} component
 * @param {*} page
 * @param {*} [options=null]
 */
export function paginate(component, page, options = null) {
    crud.paginate(URL_PREFIX, component, page, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function show(component, id, options = null) {
    crud.show(URL_PREFIX, STATE_KEY, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} values
 * @param {*} [options=null]
 */
export function update(component, id, values, options = null) {
    crud.update(URL_PREFIX, component, id, values, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function remove(component, id, options = null) {
    crud.remove(URL_PREFIX, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} page
 */
export function paginateRemoved(component, page) {
    softDeletes.paginateRemoved(URL_PREFIX, component, page);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function showRemoved(component, id, options = null) {
    softDeletes.showRemoved(URL_PREFIX, STATE_KEY, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function restore(component, id) {
    softDeletes.restore(URL_PREFIX, component, id);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function removePermanently(component, id) {
    softDeletes.removePermanently(URL_PREFIX, component, id);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} page
 */
export function listRevisions(component, id, page) {
    revisionable.listRevisions(URL_PREFIX, component, id, page);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function showRevision(component, id, options = null) {
    revisionable.showRevision(URL_PREFIX, STATE_KEY, component, id, options);
}
