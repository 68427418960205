import React from 'react';
import Cropper from 'cropperjs';

import { create, remove } from '../../requests/image';
import withNotifications from '../../contexts/withNotifications';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import Input from '../../components/Forms/Input';
import ImageField from '../../components/Forms/ImageField';

class ImageEditorForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            croppedCanvasBlob: null,
            dragMode: 'crop',
            rotateTo: 0,
        };
        this.editor = null;
        this.editorLoadingNotification = null;
        this.imageRef = React.createRef();
        this.canvasRef = React.createRef();
        this.loadEditor = this.loadEditor.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
    }

    componentDidMount() {
        this.editorLoadingNotification = this.props.notifications.add('Carregando o editor...');
    }

    componentDidUpdate(prevProps, prevState) {
        const { dragMode, rotateTo } = this.state;

        if (this.editor && dragMode !== prevState.dragMode) {
            this.editor.setDragMode(dragMode);
        }

        if (this.editor && rotateTo !== prevState.rotateTo) {
            this.editor.rotateTo(rotateTo);
        }
    }

    componentWillUnmount() {
        if (this.editor) {
            this.editor.destroy();
        }
    }

    loadEditor() {
        const { width, height, aspectRatio, mimeType, notifications } = this.props;
        const image = this.imageRef.current;
        const canvas = this.canvasRef.current;

        canvas.width = image.offsetWidth;
        canvas.height = image.offsetHeight;
        canvas
            .getContext('2d')
            .drawImage(
                image,
                0,
                0,
                image.naturalWidth,
                image.naturalHeight,
                0,
                0,
                image.offsetWidth,
                image.offsetHeight
            );

        this.editor = new Cropper(canvas, {
            aspectRatio: aspectRatio,
            viewMode: 1,
            toggleDragModeOnDblclick: false,
            ready: () => {
                image.hidden = true;
            },
            crop: () => {
                this.editor
                    .getCroppedCanvas({
                        width,
                        height,
                        minWidth: 1,
                        minHeight: 1,
                        fillColor: !mimeType || mimeType === 'image/jpeg' ? '#fff' : 'transparent',
                        imageSmoothingEnabled: true,
                        imageSmoothingQuality: 'high',
                    })
                    .toBlob(
                        (blob) => {
                            this.setState(
                                { croppedCanvasBlob: blob },
                                notifications.remove(this.editorLoadingNotification)
                            );
                        },
                        mimeType || 'image/jpeg',
                        0.7
                    );
            },
        });
    }

    uploadImage(event) {
        event.preventDefault();
        const { mimeType, width, height, aspectRatio, setImageId, setImageUrl, dialogs, overrideId } = this.props;

        const croppedImageFile = new File([this.state.croppedCanvasBlob], this.props.filename, {
            type: mimeType || 'image/jpeg',
        });

        create(this, croppedImageFile, {
            successCallbackFn: (response) => {
                if (overrideId) {
                    remove(this, overrideId);
                }

                dialogs.add(
                    dialogsKeys.imageField,
                    'Gerenciador de Mídias',
                    <ImageField
                        mimeType={mimeType || 'image/jpeg'}
                        width={width}
                        height={height}
                        aspectRatio={aspectRatio}
                        setImageId={setImageId}
                        setImageUrl={setImageUrl}
                        selectedId={response.data.id}
                        selectedUrl={response.data.url}
                    />,
                    true
                );

                dialogs.remove(dialogsKeys.imageEditor);
            },
        });
    }

    render() {
        const { image, width } = this.props;
        const { isLoading, croppedCanvasBlob, dragMode } = this.state;

        return (
            <form action="#" noValidate onSubmit={this.uploadImage}>
                <img
                    className="ImageEditorForm__image"
                    src={image}
                    alt="Imagem original"
                    ref={this.imageRef}
                    onLoad={this.loadEditor}
                />
                <div className="columns">
                    <div className="column is-one-fifth">
                        <p className="label">Ação</p>
                        <div className="columns">
                            <div className="column">
                                <button
                                    type="button"
                                    className={`button is-fullwidth ${isLoading ? 'is-loading' : ''} ${
                                        dragMode === 'crop' ? 'is-active' : ''
                                    }`}
                                    disabled={isLoading}
                                    onClick={() => this.setState({ dragMode: 'crop' })}
                                >
                                    Selecionar
                                </button>
                            </div>
                            <div className="column">
                                <button
                                    type="button"
                                    className={`button is-fullwidth ${isLoading ? 'is-loading' : ''} ${
                                        dragMode === 'move' ? 'is-active' : ''
                                    }`}
                                    disabled={isLoading}
                                    onClick={() => this.setState({ dragMode: 'move' })}
                                >
                                    Mover
                                </button>
                            </div>
                        </div>
                        <Input
                            name="rotate"
                            label="Girar"
                            type="range"
                            value={this.state.rotateTo}
                            min="0"
                            max="360"
                            step="1"
                            onChange={(e) => this.setState({ rotateTo: e.target.value })}
                        />
                        <p className="label">Zoom</p>
                        <div className="columns">
                            <div className="column">
                                <button
                                    type="button"
                                    className={`button is-fullwidth ${isLoading ? 'is-loading' : ''}`}
                                    disabled={isLoading}
                                    onClick={() => this.editor.zoom(-0.1)}
                                >
                                    Menos
                                </button>
                            </div>
                            <div className="column">
                                <button
                                    type="button"
                                    className={`button is-fullwidth ${isLoading ? 'is-loading' : ''}`}
                                    disabled={isLoading}
                                    onClick={() => this.editor.zoom(0.1)}
                                >
                                    Mais
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="column is-three-fifth">
                        <div className="ImageEditorForm__wrapper">
                            <div className="ImageEditorForm__container">
                                <canvas ref={this.canvasRef} />
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-fifth">
                        <h2 className="subtitle">Pré-visualização</h2>
                        {croppedCanvasBlob && (
                            <img
                                src={window.URL.createObjectURL(croppedCanvasBlob)}
                                alt="Imagem editada"
                                className="ImageEditorForm__cropped"
                                style={{ width: `${parseInt(width / 2, 10)}px` }}
                            />
                        )}
                    </div>
                </div>
                <div className="actions actions--sticky">
                    <button
                        type="submit"
                        className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                        disabled={isLoading}
                    >
                        Concluir edição
                    </button>
                </div>
            </form>
        );
    }
}

export default withNotifications(withDialogs(ImageEditorForm));
