import React from 'react';

import SelectField from './SelectField';
import DateTimeField from './DateTimeField';

export default class PublicationFields extends React.Component {
    render() {
        const { errors, touched } = this.props;

        return (
            <>
                <h2 className="subtitle">Publicação no site</h2>
                <div className="box">
                    <div className="columns">
                        <div className="column">
                            <SelectField
                                name="published"
                                label="Publicado?"
                                errors={errors.published}
                                touched={touched.published}
                            >
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </SelectField>
                        </div>
                        <div className="column">
                            <DateTimeField
                                name="published_at"
                                label="Publicado em"
                                errors={errors.published_at}
                                touched={touched.published_at}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
