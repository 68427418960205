import React from 'react';

export default function RemoveForm(props) {
    const { children, isLoading, onSubmit } = props;

    return (
        <form noValidate onSubmit={onSubmit}>
            <div className="has-text-centered">
                <button
                    type="submit"
                    className={`button is-light has-text-danger ${isLoading ? 'is-loading' : ''}`}
                    disabled={isLoading}
                >
                    {children}
                </button>
            </div>
        </form>
    );
}
