import api from '../services/api';
import handleError from '../services/error-handler';

/**
 * Faz a requisição de listagem de itens removidos.
 *
 * @export
 * @param {*} urlPrefix
 * @param {*} component
 * @param {*} page
 */
export function paginateRemoved(urlPrefix, component, page) {
    const { notifications } = component.props;

    component.setState({ isLoading: true });

    api.get(`/${urlPrefix}/removidos?page=${page}`)
        .then((response) => {
            component.setState({
                data: response.data.data,
                links: response.data.links,
                meta: response.data.meta,
            });
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
        })
        .finally(() => {
            component.setState({ isLoading: false });
        });
}

/**
 * Faz a requisição de exibição de um item removido.
 *
 * @export
 * @param {*} urlPrefix
 * @param {*} stateKey
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function showRemoved(urlPrefix, stateKey, component, id, options = null) {
    const { notifications } = component.props;

    component.setState({ isLoading: true });

    api.get(`/${urlPrefix}/removidos/${id}`)
        .then((response) => {
            let { data } = response.data;

            if (options && options.transformData) {
                data = options.transformData(data);
            }

            component.setState({ [stateKey]: data });
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
        })
        .finally(() => {
            component.setState({ isLoading: false });
        });
}

/**
 * Faz a requisição de restauração de um item removido.
 *
 * @export
 * @param {*} urlPrefix
 * @param {*} component
 * @param {*} id
 */
export function restore(urlPrefix, component, id) {
    const { notifications, history } = component.props;

    component.setState({ isLoading: true });

    api.put(`/${urlPrefix}/${id}/restaurar`)
        .then((response) => {
            notifications.add(response.data.message, 'success');
            history.push(`/${urlPrefix}/${id}`);
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            component.setState({ isLoading: false });
        });
}

/**
 * Faz a requisição de remoção permanente de um item removido.
 *
 * @export
 * @param {*} urlPrefix
 * @param {*} component
 * @param {*} id
 */
export function removePermanently(urlPrefix, component, id) {
    const { notifications, history } = component.props;

    component.setState({ isLoading: true });

    api.delete(`/${urlPrefix}/${id}/deletar`)
        .then((response) => {
            notifications.add(response.data.message, 'success');
            history.push(`/${urlPrefix}/removidos`);
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            this.setState({ isLoading: false });
        });
}
