import React from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm:ss';

export default class DateTime extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onChange(value) {
        const { field, form } = this.props;
        let datetimeValue = value;

        if (value instanceof moment) {
            datetimeValue = moment(value).format(`${DATE_FORMAT} ${TIME_FORMAT}`);
        }

        form.setFieldValue(field.name, datetimeValue);
    }

    onFocus() {
        const { field, form } = this.props;

        if (!field.value) {
            form.setFieldValue(field.name, moment(new Date()).format(`${DATE_FORMAT} ${TIME_FORMAT}`));
        }
    }

    onBlur() {
        const { field, form } = this.props;

        form.setFieldTouched(field.name, true);
    }

    render() {
        const { field, form } = this.props;

        return (
            <Datetime
                dateFormat={DATE_FORMAT}
                timeFormat={TIME_FORMAT}
                id={field.name}
                name={field.name}
                value={field.value}
                onChange={this.onChange}
                onOpen={this.onFocus}
                onClose={this.onBlur}
                closeOnSelect
                className={`${form.errors[field.name] && form.touched[field.name] ? ' is-danger' : ''}`}
            />
        );
    }
}
