import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ImageUpdateForm from '../../forms/Image/ImageUpdateForm';
import ImageRemoveForm from '../../forms/Image/ImageRemoveForm';

export default function ImageUpdatePage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Editar imagem"
            tabs={[
                { linkTo: `/imagens/${id}`, text: 'Editar' },
                { linkTo: `/imagens/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <ImageUpdateForm id={id} />
            <br />
            <ImageRemoveForm id={id} />
        </DefaultLayout>
    );
}
