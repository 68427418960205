import React from 'react';
import { Link } from 'react-router-dom';

import DefaultLayout from '../../layouts/DefaultLayout';
import ClippingCollection from '../../collections/Clipping/ClippingCollection';

export default function ClippingIndexPage() {
    return (
        <DefaultLayout
            title="Na mídia"
            tabs={[
                { linkTo: '/na-midia', text: 'Ativas' },
                { linkTo: '/na-midia/removidos', text: 'Removidos' },
            ]}
        >
            <ClippingCollection />
            <Link to="/na-midia/adicionar" className="button is-primary is-medium">
                Adicionar
            </Link>
        </DefaultLayout>
    );
}
