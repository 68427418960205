import React from 'react';

import { DialogsContext } from './DialogsProvider';

export const dialogsKeys = {
    imageField: 'image_field',
    imageEditor: 'image_editor',
    newsletterSend: 'newsletter_send',
    editorBlock: 'editor_block',
    clippingSource: 'clipping_source',
    workGroup: 'work_group',
};

export default function withDialogs(Component) {
    return (props) => (
        <DialogsContext.Consumer>{(dialogs) => <Component {...props} dialogs={dialogs} />}</DialogsContext.Consumer>
    );
}
