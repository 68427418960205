import React from 'react';

import Pagination from '../Pagination';
import Message from '../Message';
import Loader from '../Loader';

function Table(props) {
    const { headers, children } = props;

    return (
        <div className="table-container">
            <table className="table is-striped is-hoverable is-fullwidth">
                {headers && (
                    <thead>
                        <tr>
                            {headers.map((th) => (
                                <th scope="col" key={th}>
                                    {th}
                                </th>
                            ))}
                        </tr>
                    </thead>
                )}
                <tbody>{children}</tbody>
            </table>
        </div>
    );
}

export default class PaginatedTable extends React.Component {
    constructor(props) {
        super(props);
        this.paginate = this.paginate.bind(this);
    }

    componentDidMount() {
        this.paginate();
    }

    paginate(page = 1) {
        this.props.listFn(page);
    }

    render() {
        const { data, links, meta, headers, row } = this.props;

        if (data) {
            if (Boolean(data.length)) {
                return (
                    <>
                        <Table headers={headers}>{data && data.map(row)}</Table>
                        {links && meta && <Pagination links={links} meta={meta} goToPage={this.paginate} />}
                    </>
                );
            } else {
                return <Message>Nenhum resultado</Message>;
            }
        }

        return (
            <Message>
                <Loader />
            </Message>
        );
    }
}
