import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import WorkGroupCreateOrUpdateForm from '../../forms/WorkGroup/WorkGroupCreateOrUpdateForm';
import WorkGroupRemoveForm from '../../forms/WorkGroup/WorkGroupRemoveForm';

export default function WorkGroupUpdatePage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Editar grupo de trabalho"
            tabs={[
                { linkTo: `/grupos-de-trabalho/${id}`, text: 'Editar' },
                { linkTo: `/grupos-de-trabalho/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <WorkGroupCreateOrUpdateForm id={id} />
            <br />
            <WorkGroupRemoveForm id={id} />
        </DefaultLayout>
    );
}
