import { EditorState, SelectionState, AtomicBlockUtils, Modifier, CompositeDecorator } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import Link from '../editor/Link/LinkElement';
import { getBlockquoteHtmlString } from '../editor/Blockquote/BlockquoteElement';
import { getImageHtmlString } from '../editor/Image/ImageElement';
import { getYouTubeHtmlString } from '../editor/YouTube/YouTubeElement';
import { getSoundCloudHtmlString } from '../editor/SoundCloud/SoundCloudElement';

function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
}

export const Decorator = new CompositeDecorator([{ strategy: findLinkEntities, component: Link }]);

/**
 * Insere um bloco no editor.
 *
 * @export
 * @param {React.Component} component
 * @param {string} entityType
 * @param {Object} entityData
 */
export function insertBlock(component, entityType, entityData) {
    const { editorState, setEditorState } = component.props;

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(entityType, 'IMMUTABLE', entityData);
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.push(editorState, contentStateWithEntity, 'apply-entity');

    setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
}

/**
 * Edita um bloco do editor.
 *
 * @export
 * @param {React.component} component
 * @param {Object} entityData
 */
export function editBlock(component, entityData) {
    const { block, contentState } = component.props;
    const { editorState, setEditorState } = component.props.blockProps;

    const newContentState = contentState.replaceEntityData(block.getEntityAt(0), entityData);
    const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data');

    setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getSelection()));
}

/**
 * Deleta um bloco do editor.
 *
 * @export
 * @param {React.Component} component
 */
export function deleteBlock(component) {
    const { block, contentState } = component.props;
    const { editorState, setEditorState } = component.props.blockProps;

    const blockSelection = SelectionState.createEmpty(block.getKey());
    const blockMap = contentState.getBlockMap();
    const newContentState = Modifier.removeRange(contentState, blockSelection, 'forward');
    const newBlockMap = blockMap.delete(block.getKey());
    const newestContentState = newContentState.set('blockMap', newBlockMap);
    const newEditorState = EditorState.push(editorState, newestContentState, 'deleteBlock');

    setEditorState(EditorState.forceSelection(newEditorState, contentState.getSelectionAfter(block.getKey())));
}

/**
 * Converte o conteúdo do editor para HTML, eliminando linhas vazias e espaços duplos.
 *
 * @export
 * @param {DraftJs.ContentSate} contentState
 * @returns
 */
export function convertToHtml(contentState) {
    const getAtomicHtmlString = (type, position, children) => `
    <figure class="cdts-atomic cdts-atomic--${type.toLowerCase()} cdts-atomic--${position}">
      ${children}
    </figure>
  `;

    return stateToHTML(contentState, {
        blockRenderers: {
            unstyled: (block) => {
                return block.getText() ? null : '';
            },
            atomic: (block) => {
                if (block.getEntityAt(0)) {
                    const entity = contentState.getEntity(block.getEntityAt(0));
                    const type = entity.getType();
                    const { text, image, url, caption, width, height, position } = entity.getData();

                    switch (type) {
                        case 'BLOCKQUOTE':
                            return getAtomicHtmlString(type, position, getBlockquoteHtmlString(text));
                        case 'IMAGE':
                            return getAtomicHtmlString(
                                type,
                                position,
                                getImageHtmlString(image, width, height, caption)
                            );
                        case 'YOUTUBE':
                            return getAtomicHtmlString(type, position, getYouTubeHtmlString(url, caption));
                        case 'SOUNDCLOUD':
                            return getAtomicHtmlString(type, position, getSoundCloudHtmlString(url, caption));
                        default:
                            return null;
                    }
                }

                return null;
            },
        },
    }).replace(/\r?\n|\r|\s\s+|&nbsp;/g, ' ');
}

/**
 * Retorna os parâmetros de imagem de acordo com os parâmetros do bloco atômico.
 *
 * @export
 * @param {Object} atomicParams
 * @returns Object
 */
export function getImagePropsFromAtomicParams(atomicParams) {
    const { position, orientation } = atomicParams;
    const containerWidth = 1440;

    const width = () => {
        switch (position) {
            case 'position-1000':
            case 'position-0001':
            default:
                return containerWidth / 5;
            case 'position-1100':
            case 'position-0011':
                return containerWidth / 2;
            case 'position-1110':
            case 'position-0111':
                return (containerWidth / 2.5) * 2;
            case 'position-1111':
                return containerWidth;
        }
    };

    const aspectRatio = () => {
        switch (orientation) {
            case 'portrait':
                return 3 / 4;
            case 'square':
                return 1;
            default:
                return 16 / 9;
        }
    };

    const height = () => {
        switch (orientation) {
            case 'portrait':
                return (width() * 4) / 3;
            case 'square':
                return width() * 1;
            default:
                return (width() * 9) / 16;
        }
    };

    return {
        width: parseInt(width(), 10) * 2,
        height: parseInt(height(), 10) * 2,
        aspectRatio: aspectRatio(),
        position,
    };
}
