/**
 * Armazena valores de um componente React na localStorage.
 *
 * @export
 * @param {string} storageKey
 * @param {React.Component} component
 * @param {Object} prevProps
 * @param {Object} initialValues
 * @param {Object} [options=null]
 */
export function storeValues(storageKey, values, initialValues, options = null) {
    let _values = values;
    let _initialValues = initialValues;

    if (options && options.transformData) {
        _values = options.transformData(_values);
        _initialValues = options.transformData(_initialValues);
    }

    if (JSON.stringify(_values) !== JSON.stringify(_initialValues)) {
        localStorage.setItem(`${storageKey}${values.id ? `_${values.id}` : ''}`, JSON.stringify(_values));
    }
}

/**
 * Retorna valores salvos na localStorage para um component React.
 *
 * @export
 * @param {string} storageKey
 * @param {React.Component} component
 * @param {Object} prevProps
 * @param {int} id
 * @param {Object} [options=null]
 */
export function getStoredValues(storageKey, stateKey, component, id, options = null) {
    const { notifications } = component.props;

    let storedValues = localStorage.getItem(`${storageKey}${id ? `_${id}` : ''}`);

    if (storedValues) {
        if (options && options.transformData) {
            storedValues = options.transformData(JSON.parse(storedValues));
        }

        if (typeof storedValues === 'object') {
            component.setState({ [stateKey]: storedValues });
        } else {
            component.setState({ [stateKey]: JSON.parse(storedValues) });
        }

        notifications.add(
            'Você está vendo os dados armazenados automaticamente no seu navegador. Submeta o formulário para salvar esses dados, ou clique no botão no fim da página para limpar os dados armazenados.',
            'warning',
            true
        );

        component.setState({ hasStoredValues: true });
    } else {
        component.setState({ hasStoredValues: false });
    }
}

/**
 * Remove os valores salvos na localStorage.
 *
 * @export
 * @param {string} storageKey
 * @param {string} stateKey
 * @param {Object} initialValues
 * @param {function} showFn
 * @param {React.Component} component
 * @param {int} [id=null]
 */
export function removeStoredValues(storageKey, stateKey, initialValues, showFn, component, id = null) {
    const { notifications } = component.props;

    if (id) {
        localStorage.removeItem(`${storageKey}_${id}`);
        showFn(component, id);
        notifications.add('Dados armazenados no navegador removidos. Você agora está vendo a última versão salva.');
    } else {
        localStorage.removeItem(storageKey);
        component.setState({ [stateKey]: initialValues });
        notifications.add('Dados armazenados no navegador removidos.');
    }

    component.setState({ hasStoredValues: false });
}
