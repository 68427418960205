import React from 'react';
import { RichUtils } from 'draft-js';

export default class BlockStyle extends React.Component {
    constructor(props) {
        super(props);
        this.onToggle = this.onToggle.bind(this);
    }

    onToggle(event) {
        event.stopPropagation();
        const { editorState, setEditorState, name } = this.props;

        setEditorState(RichUtils.toggleBlockType(editorState, name));
    }

    render() {
        const { editorState, name, children } = this.props;

        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const blockAtBeginning = contentState.getBlockForKey(selection.getStartKey());

        return (
            <button
                type="button"
                className={`button is-primary is-light ${
                    blockAtBeginning && blockAtBeginning.getType() === name ? 'is-active' : ''
                }`}
                onClick={this.onToggle}
            >
                {children}
            </button>
        );
    }
}
