import React from 'react';

export default function WhatsappIcon() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 65.24">
            <path d="m48.13 39.79c-0.2-0.32-0.71-0.51-1.49-0.89s-4.57-2.25-5.28-2.53-1.23-0.38-1.75 0.39-2 2.5-2.45 3-0.9 0.58-1.68 0.2a21.3 21.3 0 0 1-6.23-3.82 23.62 23.62 0 0 1-4.32-5.32c-0.45-0.77 0-1.19 0.34-1.57s0.78-0.9 1.17-1.35a5.25 5.25 0 0 0 0.77-1.28 1.42 1.42 0 0 0-0.08-1.32c-0.2-0.38-1.75-4.17-2.39-5.71s-1.29-1.48-1.75-1.48a10.54 10.54 0 0 0-1.28 0.14 4.38 4.38 0 0 0-2.27 1 8.59 8.59 0 0 0-2.71 6.41c0 3.78 2.78 7.44 3.16 8s5.38 8.44 13.24 11.52 7.88 2 9.3 1.92 4.59-1.85 5.24-3.65a6.48 6.48 0 0 0 0.46-3.66z" />
            <path d="m65 31.91a32 32 0 0 1-47.33 27.72l-17.67 5.61 5.76-17a31.26 31.26 0 0 1-4.58-16.33 31.91 31.91 0 0 1 63.82 0zm-31.87-26.63a26.76 26.76 0 0 0-26.87 26.63 26.34 26.34 0 0 0 5.11 15.6l-3.35 9.86 10.31-3.25a26.86 26.86 0 0 0 41.59-22.21 26.76 26.76 0 0 0-26.79-26.63z" />
        </svg>
    );
}
