import React from 'react';
import { Link } from 'react-router-dom';

import DefaultLayout from '../../layouts/DefaultLayout';
import MemberCollection from '../../collections/Member/MemberCollection';

export default function MemberIndexPage() {
    return (
        <DefaultLayout
            title="Membros"
            tabs={[
                { linkTo: '/membros', text: 'Ativos' },
                { linkTo: '/membros/removidos', text: 'Removidos' },
            ]}
        >
            <MemberCollection />
            <Link to="/membros/adicionar" className="button is-primary is-medium">
                Adicionar
            </Link>
        </DefaultLayout>
    );
}
