import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterRecipientUpdateForm from '../../forms/NewsletterRecipient/NewsletterRecipientUpdateForm';

export default function NewsletterRecipientRevisionPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Versão de destinatário de boletim">
            <NewsletterRecipientUpdateForm id={id} showRequest="revision" />
        </DefaultLayout>
    );
}
