import React from 'react';

import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';
import AuthenticationForgotPasswordForm from '../../forms/Authentication/AuthenticationForgotPasswordForm';

export default function AuthenticationForgotPasswordPage() {
    return (
        <UnauthenticatedLayout title="Esqueci minha senha">
            <AuthenticationForgotPasswordForm />
        </UnauthenticatedLayout>
    );
}
