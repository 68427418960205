import api from '../services/api';
import handleError from '../services/error-handler';
import { setToken, removeToken } from '../services/authentication';

/**
 * Faz a requisição de login e salva o JWT.
 *
 * @param {React.Component} component
 * @param {array} values
 */
export function login(component, values) {
    const { history, notifications } = component.props;

    component.setState({ isLoading: true });

    api.post('/entrar', values)
        .then((response) => {
            setToken(response.data.token);
            history.push('/');
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            component.setState({ isLoading: false });
        });
}

/**
 * Faz a requisição de login e remove o JWT.
 *
 * @param {React.Component} component
 */
export function logout(component) {
    const { history, notifications } = component.props;

    api.post('/sair')
        .then((response) => {
            removeToken();
            notifications.add(response.data.message, 'success');
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
        })
        .finally(() => {
            history.push('/entrar');
        });
}

/**
 * Adiciona o usuário autenticado a um componente.
 *
 * @param {React.Component} component
 */
export function getAuthenticatedUser(component) {
    const { notifications } = component.props;

    component.setState({ isLoading: true });

    api.get('/usuario-autenticado')
        .then((response) => {
            response.data.password = '';
            response.data.password_confirmation = '';

            component.setState({ authenticatedUser: response.data });
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
        })
        .finally(() => {
            component.setState({ isLoading: false });
        });
}

/**
 * Faz a requisição de pedido de redefinição de senha.
 *
 * @param {React.Component} component
 * @param {array} values
 */
export function forgotPassword(component, values) {
    const { notifications, history } = component.props;

    component.setState({ isLoading: true });

    api.post('/solicitar-redefinicao-de-senha', values)
        .then((response) => {
            notifications.add(response.data.message, 'success');
            history.push('/entrar');
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            component.setState({ isLoading: false });
        });
}

/**
 * Faz a requisição de redefinição de senha.
 *
 * @param {React.Component} component
 * @param {array} values
 * @param {string} url
 */
export function resetPassword(component, values, url) {
    const { notifications, history } = component.props;

    component.setState({ isLoading: true });

    api.post(url, values)
        .then((response) => {
            notifications.add(response.data.message, 'success');
            history.push('/entrar');
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            component.setState({ isLoading: false });
        });
}

/**
 * Faz a requisição de atualização de e-mail do usuário autenticado.
 *
 * @param {React.Component} component
 * @param {array} values
 */
export function updateEmail(component, values) {
    const { notifications, history } = component.props;

    component.setState({ isLoading: true });

    api.put('/atualizar-email', values)
        .then((response) => {
            removeToken();
            notifications.add(response.data.message, 'success');
            history.push('/entrar');
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            component.setState({ isLoading: false });
        });
}

/**
 * Faz a requisição de atualização de senha do usuário autenticado.
 *
 * @param {React.Component} component
 * @param {array} values
 */
export function updatePassword(component, values) {
    const { notifications, history } = component.props;

    component.setState({ isLoading: true });

    api.put('/atualizar-senha', values)
        .then((response) => {
            removeToken();
            notifications.add(response.data.message, 'success');
            history.push('/entrar');
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            component.setState({ isLoading: false });
        });
}
