import React from 'react';
import { Link } from 'react-router-dom';

import { paginate, paginateRemoved } from '../../requests/clipping';
import withNotifications from '../../contexts/withNotifications';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import ClippingCollectionItem from './ClippingCollectionItem';

class ClippingCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            links: null,
            meta: null,
        };
        this.paginate = this.paginate.bind(this);
    }

    componentDidMount() {
        this.paginate();
    }

    paginate(page) {
        const { paginateRequest } = this.props;

        if (paginateRequest === 'paginateRemoved') {
            paginateRemoved(this, page, {
                urlParams: { itemsPerPage: 9 },
            });
        } else {
            paginate(this, page, {
                urlParams: { itemsPerPage: 9 },
            });
        }
    }

    render() {
        const { paginateRequest } = this.props;
        const { data, links, meta } = this.state;

        return (
            <div className="box">
                {data ? (
                    Boolean(data.length) ? (
                        <>
                            <div className="columns is-multiline">
                                {data.map((clipping) => (
                                    <div key={clipping.id} className="column is-one-third">
                                        <Link
                                            to={`/na-midia${
                                                paginateRequest === 'paginateRemoved' ? '/removidos' : ''
                                            }/${clipping.id}`}
                                        >
                                            <div className="card">
                                                <ClippingCollectionItem clipping={clipping} />
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                            {links && meta && (
                                <>
                                    <br />
                                    <Pagination links={links} meta={meta} goToPage={this.paginate} />
                                </>
                            )}
                        </>
                    ) : (
                        <Message>Nenhum resultado</Message>
                    )
                ) : (
                    <Message>
                        <Loader />
                    </Message>
                )}
            </div>
        );
    }
}

export default withNotifications(ClippingCollection);
