import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ImageUpdateForm from '../../forms/Image/ImageUpdateForm';

export default function ImageRevisionPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Versão de imagem">
            <ImageUpdateForm id={id} showRequest="revision" />
        </DefaultLayout>
    );
}
