import React from 'react';
import { Link } from 'react-router-dom';

import { paginate, paginateRemoved } from '../../requests/image';
import withNotifications from '../../contexts/withNotifications';
import Pagination from '../../components/Pagination';
import Message from '../../components/Message';
import Input from '../../components/Forms/Input';
import ImageCollectionItem from './ImageCollectionItem';
import Loader from '../../components/Loader';

class ImageCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            links: null,
            meta: null,
            isLoading: false,
        };
        this.paginate = this.paginate.bind(this);
    }

    componentDidMount() {
        this.paginate();
    }

    componentDidUpdate(prevProps, prevState) {
        const { name } = this.state;

        if (name !== prevState.name) {
            this.paginate();
        }
    }

    paginate(page) {
        const { mimeType, width, height, paginateRequest } = this.props;
        const { name } = this.state;

        if (paginateRequest === 'paginateRemoved') {
            paginateRemoved(this, page, {
                urlParams: { name, mimeType, width, height },
            });
        } else {
            paginate(this, page, {
                urlParams: { name, mimeType, width, height },
            });
        }
    }

    render() {
        const { paginateRequest, selectFn, withoutBox } = this.props;
        const { data, links, meta, isLoading, selected } = this.state;

        return (
            <div className={`ImageCollection ${!withoutBox ? 'box' : ''}`}>
                <div className={`ImageCollection__inner ${selected ? 'ImageCollection__inner--with-selected' : ''}`}>
                    <div className="columns">
                        <div className="column">
                            <Input
                                name="name-controller"
                                label="Filtrar por nome"
                                labelHidden
                                placeholder="Filtrar por nome"
                                onChange={(e) => this.setState({ name: e.target.value })}
                                isLoading={isLoading}
                            />
                        </div>
                        <div className="column" />
                        <div className="column" />
                    </div>

                    {data ? (
                        Boolean(data.length) ? (
                            <>
                                <div className="columns is-multiline">
                                    {data.map((image) => (
                                        <div key={image.id} className="column is-one-fifth">
                                            {selectFn ? (
                                                <button
                                                    type="button"
                                                    className={`button-reset card ${isLoading ? 'is-loading' : ''}`}
                                                    disabled={isLoading}
                                                    onClick={() => selectFn(image)}
                                                >
                                                    <ImageCollectionItem image={image} />
                                                </button>
                                            ) : (
                                                <Link
                                                    to={`/imagens${
                                                        paginateRequest === 'paginateRemoved' ? '/removidas' : ''
                                                    }/${image.id}`}
                                                >
                                                    <div className="card">
                                                        <ImageCollectionItem image={image} />
                                                    </div>
                                                </Link>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                {links && meta && (
                                    <>
                                        <br />
                                        <Pagination links={links} meta={meta} goToPage={this.paginate} />
                                    </>
                                )}
                            </>
                        ) : (
                            <Message>Nenhuma imagem do tamanho ou formato adequado</Message>
                        )
                    ) : (
                        <Message>
                            <Loader />
                        </Message>
                    )}
                </div>
            </div>
        );
    }
}

export default withNotifications(ImageCollection);
