import React from 'react';

import { NotificationsContext } from './NotificationsProvider';

export default function withNotifications(Component) {
    return (props) => (
        <NotificationsContext.Consumer>
            {(notifications) => <Component {...props} notifications={notifications} />}
        </NotificationsContext.Consumer>
    );
}
