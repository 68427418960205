import React from 'react';

import Field from './Field';
import DateTime from './DateTime';

export default class DateTimeField extends React.Component {
    render() {
        const { name, label, labelHidden, errors, touched } = this.props;

        return (
            <Field
                name={name}
                label={label}
                labelHidden={labelHidden}
                component={DateTime}
                errors={errors}
                touched={touched}
            />
        );
    }
}
