import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { show, create, update, restore, showRemoved, showRevision } from '../../requests/work-group';
import withNotifications from '../../contexts/withNotifications';
import withDialogs from '../../contexts/withDialogs';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';
import RevisionData from '../../components/Forms/RevisionData';

export const initialValues = {
    title: '',
};

export const validationSchema = Yup.object({
    title: Yup.string().required('Campo obrigatório').max(191, 'O campo não pode ter mais que 191 caracteres'),
});

class WorkGroupCreatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            workGroup: initialValues,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { id, showRequest, onSubmitRequest } = this.props;

        if (id) {
            if (showRequest === 'revision') {
                showRevision(this, id);
            } else if (onSubmitRequest === 'restore') {
                showRemoved(this, id);
            } else {
                show(this, id);
            }
        }
    }

    /**
     * Cria, atualiza ou restaura a workGroup ao submeter o formulário.
     *
     * @param {*} values
     */
    onSubmit(values) {
        const { id, onSubmitRequest, successCallbackFn } = this.props;

        if (id) {
            if (onSubmitRequest === 'restore') {
                restore(this, id);
            } else {
                update(this, id, values);
            }
        } else {
            if (successCallbackFn) {
                create(this, values, { successCallbackFn });
            } else {
                create(this, values);
            }
        }
    }

    /**
     * @inheritdoc
     */
    render() {
        const { id, showRequest, disabled, onSubmitRequest, withoutBox } = this.props;
        const { isLoading, workGroup, revision } = this.state;

        return (
            <Formik
                enableReinitialize
                initialValues={workGroup}
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
            >
                {({ errors, touched }) => (
                    <Form>
                        <fieldset
                            className={!withoutBox ? 'box' : ''}
                            disabled={disabled || showRequest === 'revision' || onSubmitRequest === 'restore'}
                        >
                            <div className="columns">
                                <div className="column">
                                    <Field name="title" label="Título" errors={errors.title} touched={touched.title} />
                                </div>
                                <div className="column" />
                            </div>
                        </fieldset>

                        {showRequest === 'revision' && revision && (
                            <>
                                <hr className="invisible" />
                                <RevisionData revision={revision} />
                            </>
                        )}

                        {showRequest !== 'revision' && (
                            <>
                                <hr className="invisible" />
                                <div className="buttons actions actions--sticky">
                                    <button
                                        type="submit"
                                        className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                                        disabled={isLoading}
                                    >
                                        {onSubmitRequest === 'restore' ? 'Restaurar' : id ? 'Editar' : 'Adicionar'}
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withDialogs(withNotifications(withRouter(WorkGroupCreatePage)));
