import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterSectionTemplateCreateOrUpdateForm from '../../forms/NewsletterSectionTemplate/NewsletterSectionTemplateCreateOrUpdateForm';
import NewsletterSectionTemplateRemoveForm from '../../forms/NewsletterSectionTemplate/NewsletterSectionTemplateRemoveForm';

export default function NewsletterSectionTemplateUpdatePage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Editar seção de boletim"
            tabs={[
                { linkTo: `/secoes-de-boletim/${id}`, text: 'Editar' },
                { linkTo: `/secoes-de-boletim/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <NewsletterSectionTemplateCreateOrUpdateForm id={id} />
            <br />
            <NewsletterSectionTemplateRemoveForm id={id} />
        </DefaultLayout>
    );
}
