import React from 'react';

import { getImageOrIcon } from '../../services/media';

export default function ImageCollectionItem(props) {
    const { image } = props;

    return (
        <div className="ImageCollectionItem">
            <img
                src={getImageOrIcon(image.mimeType, image.url)}
                alt=""
                width={image.width ? parseInt(image.width / 2, 10) : 150}
                height={image.height ? parseInt(image.height / 2, 10) : 150}
            />
            <p className="ImageCollectionItem__thumbnail">{image.name}</p>
        </div>
    );
}
