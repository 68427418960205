import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import UserCollection from '../../collections/User/UserCollection';

export default function UserListRemovedPage() {
    return (
        <DefaultLayout
            title="Usuários removidos"
            tabs={[
                { linkTo: '/usuarios', text: 'Ativos' },
                { linkTo: '/usuarios/removidos', text: 'Removidos' },
            ]}
        >
            <UserCollection paginateRequest="paginateRemoved" />
        </DefaultLayout>
    );
}
