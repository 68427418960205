import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextareaAutosize from 'react-autosize-textarea';

import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';
import BlockquoteElement from './BlockquoteElement';

export const validationSchema = Yup.object({
    text: Yup.string().required('Campo obrigatório'),
});

class BlockquoteForm extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        const { confirmBlockquote, dialogs, atomicParams } = this.props;
        const { position } = atomicParams;

        confirmBlockquote(values.text, position);
        dialogs.remove(dialogsKeys.editorBlock);
    }

    render() {
        const { text } = this.props;

        const initialValues = {
            text: text || '',
        };

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit}>
                {({ values, errors, touched }) => (
                    <Form>
                        {values.text && (
                            <>
                                <figure className="cdts-atomic cdts-atomic--blockquote">
                                    <BlockquoteElement text={values.text} />
                                </figure>
                            </>
                        )}
                        <Field
                            name="text"
                            label="Texto do olho"
                            as={TextareaAutosize}
                            errors={errors.text}
                            touched={touched.text}
                        />
                        <div className="actions actions--sticky">
                            <button type="submit" className="button is-primary is-medium">
                                Confirmar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withDialogs(BlockquoteForm);
