import React from 'react';
import { Field as FormikField } from 'formik';
import { FastField as FormikFastField } from 'formik';

import Checkbox from './Checkbox';

export default class CheckboxField extends React.Component {
    render() {
        const { name, label, errors, touched, isFastField } = this.props;

        if (isFastField) {
            return (
                <FormikFastField id={name} name={name}>
                    {({ field, form }) => (
                        <>
                            <Checkbox
                                label={label}
                                name={field.name}
                                value={field.value}
                                onChange={(e) => {
                                    form.setFieldValue(field.name, e.target.checked);
                                }}
                                errors={errors}
                                touched={touched}
                            />
                        </>
                    )}
                </FormikFastField>
            );
        }

        return (
            <FormikField id={name} name={name}>
                {({ field, form }) => (
                    <>
                        <Checkbox
                            label={label}
                            name={field.name}
                            value={field.value}
                            onChange={(e) => {
                                form.setFieldValue(field.name, e.target.checked);
                            }}
                            errors={errors}
                            touched={touched}
                        />
                    </>
                )}
            </FormikField>
        );
    }
}
