import React from 'react';
import { Redirect } from 'react-router-dom';

import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';

export default function NewsletterRecipientSubscriptionRequestedPage(props) {
    const { location } = props;

    if (location && location.state && location.state.email) {
        return (
            <UnauthenticatedLayout title="Verifique seu e-mail">
                <p className="subtitle">{`Enviamos um e-mail para ${location.state.email} com um link de verificação.`}</p>
                <p className="subtitle">Clique no link para finalizar a sua inscrição.</p>
            </UnauthenticatedLayout>
        );
    }

    return <Redirect to="/inscricao" />;
}
