import React from 'react';

import { insertBlock } from '../../services/editor';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import ImagePrompter from './ImagePrompter';

class ImageTool extends React.Component {
    constructor(props) {
        super(props);
        this.confirmImage = this.confirmImage.bind(this);
    }

    confirmImage(image, caption, width, height, position, orientation) {
        insertBlock(this, 'IMAGE', { image, caption, width, height, position, orientation });
        this.props.dialogs.remove(dialogsKeys.editorBlock);
    }

    render() {
        const { editorState, setEditorState } = this.props;

        return (
            <ImagePrompter
                editorState={editorState}
                setEditorState={setEditorState}
                confirmImage={this.confirmImage}
                isToolButton
            >
                Imagem
            </ImagePrompter>
        );
    }
}

export default withDialogs(ImageTool);
