import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import NotificationsProvider from './contexts/NotificationsProvider';
import DialogsProvider from './contexts/DialogsProvider';
import Routes from './Routes';

export default function App() {
    return (
        <BrowserRouter>
            <NotificationsProvider>
                <DialogsProvider>
                    <Routes />
                </DialogsProvider>
            </NotificationsProvider>
        </BrowserRouter>
    );
}
