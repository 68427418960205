import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterRecipientUpdateForm from '../../forms/NewsletterRecipient/NewsletterRecipientUpdateForm';
import NewsletterRecipientRemoveForm from '../../forms/NewsletterRecipient/NewsletterRecipientRemoveForm';

export default function NewsletterRecipientUpdatePage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Visualizar destinatário de boletim"
            tabs={[
                { linkTo: `/destinatarios-de-boletim/${id}`, text: 'Visualizar' },
                { linkTo: `/destinatarios-de-boletim/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <NewsletterRecipientUpdateForm id={id} />
            <br />
            <NewsletterRecipientRemoveForm id={id} />
        </DefaultLayout>
    );
}
