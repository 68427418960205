import React from 'react';

import { preview } from '../../requests/newsletter';
import withNotifications from '../../contexts/withNotifications';

class NewsletterPreviewPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: 'Carregando...',
        };
    }

    componentDidMount() {
        preview(this, this.props.match.params.id);
    }

    render() {
        const { preview } = this.state;

        return (
            <div
                className="html-container"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: preview }}
            />
        );
    }
}

export default withNotifications(NewsletterPreviewPage);
