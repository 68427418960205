import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import InterviewCollection from '../../collections/Interview/InterviewCollection';

export default function InterviewRemovedIndexPage() {
    return (
        <DefaultLayout
            title="Entrevistas removidas"
            tabs={[
                { linkTo: '/entrevistas', text: 'Ativas' },
                { linkTo: '/entrevistas/removidas', text: 'Removidas' },
            ]}
        >
            <InterviewCollection paginateRequest="paginateRemoved" />
        </DefaultLayout>
    );
}
