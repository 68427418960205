import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ImageUpdateForm from '../../forms/Image/ImageUpdateForm';

export default function ImageRemovedIndexPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Mídia removida">
            <ImageUpdateForm id={id} onSubmitRequest="restore" />
        </DefaultLayout>
    );
}
