import React from 'react';

export default class Input extends React.Component {
    render() {
        const {
            name,
            label,
            labelHidden,
            type,
            autoComplete,
            placeholder,
            value,
            defaultValue,
            min,
            max,
            step,
            pattern,
            onKeyDown,
            onChange,
            list,
            withAddOn,
            isStatic,
            isUppercase,
            errors,
            touched,
            isLoading,
        } = this.props;

        return (
            <>
                {withAddOn && (
                    <label htmlFor={name} className={`label ${labelHidden ? 'is-sr-only' : ''}`}>
                        {label}
                    </label>
                )}
                <div className={`field ${withAddOn ? 'has-addons' : ''}`}>
                    {!withAddOn && (
                        <label htmlFor={name} className={`label ${labelHidden ? 'is-sr-only' : ''}`}>
                            {label}
                        </label>
                    )}
                    <div className={`control ${isLoading ? 'is-loading' : ''} ${withAddOn ? 'is-expanded' : ''}`}>
                        <input
                            id={name}
                            name={name}
                            type={type || 'text'}
                            autoComplete={autoComplete}
                            placeholder={placeholder}
                            value={value}
                            defaultValue={defaultValue}
                            min={min}
                            max={max}
                            step={step}
                            pattern={pattern}
                            list={list}
                            onKeyDown={onKeyDown}
                            onChange={onChange}
                            className={`input ${errors && touched ? 'is-danger' : ''} ${isStatic ? 'is-static' : ''} ${
                                isUppercase ? 'is-uppercase' : ''
                            }`}
                        />
                        {errors && touched && <div className="help is-danger">{errors}</div>}
                    </div>
                    {withAddOn && <div className="control">{withAddOn}</div>}
                </div>
            </>
        );
    }
}
