import React from 'react';

import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';
import NewsletterRecipientRequestSubscriptionForm from '../../forms/NewsletterRecipient/NewsletterRecipientRequestSubscriptionForm';

export default function NewsletterRecipientRequestSubscriptionPage() {
    return (
        <UnauthenticatedLayout title="Inscrição no boletim">
            <NewsletterRecipientRequestSubscriptionForm />
        </UnauthenticatedLayout>
    );
}
