import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterSectionTemplateCreateOrUpdateForm from '../../forms/NewsletterSectionTemplate/NewsletterSectionTemplateCreateOrUpdateForm';

export default function NewsletterSectionTemplateRevisionPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Versão de seção de boletim">
            <NewsletterSectionTemplateCreateOrUpdateForm id={id} showRequest="revision" />
        </DefaultLayout>
    );
}
