import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import UserUpdateForm from '../../forms/User/UserUpdateForm';

export default function UserRevisionPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Versão de usuário">
            <UserUpdateForm id={id} showRequest="revision" />
        </DefaultLayout>
    );
}
