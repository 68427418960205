import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterCreateOrUpdateForm from '../../forms/Newsletter/NewsletterCreateOrUpdateForm';

export default function NewsletterRemovedPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Boletim removido">
            <NewsletterCreateOrUpdateForm id={id} onSubmitRequest="restore" />
        </DefaultLayout>
    );
}
