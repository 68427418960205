import React from 'react';
import { withRouter } from 'react-router-dom';

import withNotifications from '../../contexts/withNotifications';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import ImageCollection from '../../collections/Image/ImageCollection';
import ImageUpdateForm from './ImageUpdateForm';

class ImageSelectForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: '',
            selectedUrl: '',
        };
        this.select = this.select.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { selectedId, selectedUrl } = this.props;

        if (selectedId) {
            this.setState({ selectedId });
        }

        if (selectedUrl) {
            this.setState({ selectedUrl });
        }
    }

    select(image) {
        this.setState({
            selectedId: image.id,
            selectedUrl: image.url,
        });
    }

    onSubmit(event) {
        event.preventDefault();
        const { setImageId, setImageUrl, dialogs } = this.props;
        const { selectedId, selectedUrl } = this.state;

        setImageId(selectedId);
        setImageUrl(selectedUrl);
        dialogs.remove(dialogsKeys.imageField);
    }

    render() {
        const { mimeType, width, height } = this.props;
        const { isLoading, selectedId } = this.state;

        return (
            <div className="ImageSelectForm">
                <h3 className="is-sr-only">Biblioteca de Mídias</h3>
                <div className="ImageSelectForm__container">
                    {selectedId && (
                        <>
                            <div className="ImageSelectForm__selected">
                                <ImageUpdateForm id={selectedId} withoutBox smallSized disabled />
                            </div>
                        </>
                    )}
                    <ImageCollection
                        mimeType={mimeType}
                        width={width}
                        height={height}
                        selectFn={this.select}
                        withoutBox
                    />
                </div>
                {selectedId && (
                    <div className="actions actions--sticky">
                        <form noValidate onSubmit={this.onSubmit}>
                            <button
                                type="submit"
                                className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                                disabled={isLoading}
                            >
                                Selecionar
                            </button>
                        </form>
                    </div>
                )}
            </div>
        );
    }
}

export default withNotifications(withDialogs(withRouter(ImageSelectForm)));
