import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ClippingSourceCreateOrUpdateForm from '../../forms/ClippingSource/ClippingSourceCreateOrUpdateForm';
import ClippingSourceRemoveForm from '../../forms/ClippingSource/ClippingSourceRemoveForm';

export default function ClippingSourceUpdatePage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Editar fonte de mídia"
            tabs={[
                { linkTo: `/fontes-de-midia/${id}`, text: 'Editar' },
                { linkTo: `/fontes-de-midia/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <ClippingSourceCreateOrUpdateForm id={id} />
            <br />
            <ClippingSourceRemoveForm id={id} />
        </DefaultLayout>
    );
}
