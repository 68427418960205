import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import MemberCollection from '../../collections/Member/MemberCollection';

export default function MemberRemovedIndexPage() {
    return (
        <DefaultLayout
            title="Membros removidos"
            tabs={[
                { linkTo: '/membros', text: 'Ativos' },
                { linkTo: '/membros/removidos', text: 'Removidos' },
            ]}
        >
            <MemberCollection paginateRequest="paginateRemoved" />
        </DefaultLayout>
    );
}
