import React from 'react';
import { debounce } from 'lodash';

import { searchByTitle } from '../../requests/tag';
import withNotifications from '../../contexts/withNotifications';
import Input from './Input';

class TagField extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            tagList: [],
        };
        this.buttonRef = React.createRef();
        this.searchByTitle = debounce(searchByTitle, 300);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { value } = this.state;

        if (value && value !== prevState.value) {
            this.searchByTitle(this, value);
        }
    }

    onKeyDown(event) {
        const { value } = this.state;

        if (event.keyCode === 13) {
            event.preventDefault();

            if (value) {
                this.buttonRef.current.click();
            }
        }
    }

    render() {
        const { addFn } = this.props;
        const { value, tagList, isLoading } = this.state;

        return (
            <>
                <div className="field">
                    <Input
                        name="tag-controller"
                        label="Buscar ou adicionar tag"
                        list="tags"
                        value={value}
                        onKeyDown={this.onKeyDown}
                        onChange={(e) => this.setState({ value: e.target.value })}
                        isLoading={isLoading}
                        autoComplete="off"
                        isUppercase
                        withAddOn={
                            <button
                                ref={this.buttonRef}
                                type="button"
                                className="button is-primary"
                                onClick={() => {
                                    addFn(value, this);
                                }}
                            >
                                OK
                            </button>
                        }
                    />
                </div>
                <datalist id="tags">
                    {tagList && tagList.map((tag) => <option key={tag.id} value={tag.title} />)}
                </datalist>
            </>
        );
    }
}

export default withNotifications(TagField);
