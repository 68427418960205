import api from '../services/api';
import handleError from '../services/error-handler';

/**
 * Retorna se o usuário autenticado é administrador ou não.
 *
 * @param {Object} authenticatedUser
 */
function isAdmin(authenticatedUser) {
    return authenticatedUser && authenticatedUser.role === 'admin';
}

/**
 * Retorna se o usuário autenticado é administrador ou não e se ele não é o de id passado como parâmetro.
 *
 * @param {Object} authenticatedUser
 * @param {string|int} userId
 */
function isAdminAndIsNotUser(authenticatedUser, userId) {
    return isAdmin(authenticatedUser) && authenticatedUser.id !== parseInt(userId, 10);
}

/**
 * Verifica se o usuário autenticado atende a certos parâmetros (gates).
 *
 * @param {React.Component} component
 * @param {string} gate
 * @param {string|id|null} userId
 * @param {string|null} stateKey
 */
export default function authorize(component, gate, userId = null, stateKey = 'isAuthorized') {
    const { notifications } = component.props;

    api.get('/usuario-autenticado')
        .then((response) => {
            const authenticatedUser = response.data.data;

            switch (gate) {
                case 'isAdmin':
                    component.setState({
                        [stateKey]: isAdmin(authenticatedUser),
                    });
                    break;
                case 'isAdminAndIsNotUser':
                    component.setState({
                        [stateKey]: isAdminAndIsNotUser(authenticatedUser, userId),
                    });
                    break;
                default:
                    component.setState({ [stateKey]: false });
                    break;
            }
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
        });
}
