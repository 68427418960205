import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import withDialogs from '../contexts/withDialogs';

const menuItems = [
    {
        label: 'Métricas',
        links: [
            {
                to: '/',
                text: 'Visão geral',
                exact: true,
            },
        ],
    },
    {
        label: 'Conteúdo',
        links: [
            {
                to: '/noticias',
                text: 'Notícias',
            },
            {
                to: '/entrevistas',
                text: 'Entrevistas',
            },
            {
                to: '/opiniao-do-especialista',
                text: 'Opinião do especialista',
            },
        ],
    },
    {
        label: 'Boletim',
        links: [
            {
                to: '/boletins',
                text: 'Boletins',
            },
            {
                to: '/secoes-de-boletim',
                text: 'Seções de boletim',
            },
            {
                to: '/destinatarios-de-boletim',
                text: 'Destinatários',
            },
        ],
    },
    {
        label: 'Clipping',
        links: [
            {
                to: '/na-midia',
                text: 'Na mídia',
            },
            {
                to: '/fontes-de-midia',
                text: 'Fontes',
            },
        ],
    },
    {
        label: 'Institucional',
        links: [
            {
                to: '/membros',
                text: 'Membros',
            },
            {
                to: '/grupos-de-trabalho',
                text: 'Grupos de Trabalho',
            },
        ],
    },
    {
        label: 'Sistema',
        links: [
            {
                to: '/tags',
                text: 'Tags',
            },
            {
                to: '/imagens',
                text: 'Mídias',
            },
            {
                to: '/usuarios',
                text: 'Usuários',
            },
        ],
    },
    {
        label: 'Acesso',
        links: [
            {
                to: '/atualizar-email',
                text: 'Atualizar e-mail',
            },
            {
                to: '/atualizar-senha',
                text: 'Atualizar senha',
            },
            {
                to: '/sair',
                text: 'Sair',
            },
        ],
    },
];

class DefaultLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuActive: true,
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.reset = this.reset.bind(this);
    }

    toggleMenu() {
        this.setState({ menuActive: !this.state.menuActive });
    }

    reset() {
        const { history, dialogs } = this.props;
        history.push('/');
        dialogs.removeAll();
    }

    render() {
        const { title, tabs, children, location, isFullWidth, withHiddenTitle } = this.props;
        const { menuActive } = this.state;

        return (
            <>
                <header className="DefaultLayout__header navbar is-fixed-top">
                    <div className="DefaultLayout__top-bar" />
                    <div className="navbar-brand">
                        <button className="navbar-item" onClick={this.reset}>
                            <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="" />
                        </button>
                        <button
                            type="button"
                            className={`navbar-burger${menuActive ? ' is-active' : ''}`}
                            aria-label={menuActive ? 'Esconder menu' : 'Mostrar menu'}
                            aria-expanded={menuActive}
                            onClick={this.toggleMenu}
                        >
                            <span aria-hidden="true" />
                            <span aria-hidden="true" />
                            <span aria-hidden="true" />
                        </button>
                    </div>
                </header>

                <main className={`DefaultLayout__main ${!menuActive ? 'DefaultLayout__main--hidden' : ''}`}>
                    <div
                        className={`DefaultLayout__container ${
                            isFullWidth ? 'DefaultLayout__container--full-width' : 'DefaultLayout__container--contained'
                        }`}
                    >
                        <div className="level DefaultLayout__title">
                            <div className="level-left">
                                <h1 className={`title ${withHiddenTitle ? 'is-sr-only' : ''}`}>{title}</h1>
                            </div>
                            {tabs && Boolean(tabs.length) && (
                                <div className="level-right">
                                    <nav className="tabs is-centered is-toggle">
                                        <ul>
                                            {tabs.map((tab) => (
                                                <li
                                                    key={tab.text}
                                                    className={location.pathname === tab.linkTo ? 'is-active' : ''}
                                                >
                                                    <NavLink exact to={tab.linkTo}>
                                                        {tab.text}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            )}
                        </div>
                        {children}
                    </div>
                </main>

                <aside className={`DefaultLayout__aside ${!menuActive ? 'DefaultLayout__aside--hidden' : ''}`}>
                    <nav className="menu DefaultLayout__navigation">
                        {menuItems.map((item) => (
                            <React.Fragment key={item.label}>
                                <p className="menu-label">{item.label}</p>
                                <ul className="menu-list">
                                    {item.links.map((link) => (
                                        <li key={link.text}>
                                            <NavLink
                                                to={link.to}
                                                activeClassName="is-active"
                                                exact={link.exact ? link.exact : false}
                                            >
                                                {link.text}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </React.Fragment>
                        ))}
                    </nav>
                </aside>
            </>
        );
    }
}

export default withRouter(withDialogs(DefaultLayout));
