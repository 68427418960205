import React from 'react';

import { logout } from '../../requests/authentication';
import withNotifications from '../../contexts/withNotifications';

class AuthenticationLogoutPage extends React.Component {
    componentDidMount() {
        logout(this);
    }

    render() {
        return null;
    }
}

export default withNotifications(AuthenticationLogoutPage);
