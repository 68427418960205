import React from 'react';
import { withRouter } from 'react-router-dom';

class Dialog extends React.Component {
    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();
        this.unlistenHistory = null;
        this.focusableEls = null;
        this.firstFocusableEl = null;
        this.lastFocusableEl = null;
        this.focusedElementBeforeOpen = null;
        this.handleBackButton = this.handleBackButton.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        this.handleBackButton();
        document.addEventListener('keydown', this.handleKeyDown);
        this.focusedElementBeforeOpen = document.activeElement;
        this.focusableEls = this.dialogRef.current.querySelectorAll(
            `a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]`
        );
        this.firstFocusableEl = this.focusableEls[0];
        this.lastFocusableEl = this.focusableEls[this.focusableEls.length - 1];
        this.firstFocusableEl.focus();
    }

    componentWillUnmount() {
        this.focusedElementBeforeOpen.focus();
        document.removeEventListener('keydown', this.handleKeyDown);
        this.unlistenHistory();
    }

    handleBackButton() {
        const { history, close } = this.props;
        this.unlistenHistory = history.listen(() => {
            if (history.action === 'POP') {
                close();
            }
        });
    }

    handleKeyDown(event) {
        const { close } = this.props;
        const KEY_TAB = 9;
        const KEY_ESC = 27;

        const handleBackwardTab = () => {
            if (document.activeElement === this.firstFocusableEl) {
                event.preventDefault();
                this.lastFocusableEl.focus();
            }
        };

        const handleForwardTab = () => {
            if (document.activeElement === this.lastFocusableEl) {
                event.preventDefault();
                this.firstFocusableEl.focus();
            }
        };

        switch (event.keyCode) {
            case KEY_TAB:
                if (this.focusableEls.length === 1) {
                    event.preventDefault();
                    break;
                }

                if (event.shiftKey) {
                    handleBackwardTab();
                } else {
                    handleForwardTab();
                }
                break;
            case KEY_ESC:
                close();
                event.stopPropagation();
                break;
            default:
                break;
        }
    }

    render() {
        const { isFullWidth, title, close, content } = this.props;

        return (
            <div className="modal is-active" role="dialog" aria-modal="true" ref={this.dialogRef}>
                <div
                    tabIndex="-1"
                    role="button"
                    aria-label="Fechar"
                    className="modal-background"
                    onClick={close}
                    onKeyDown={close}
                />
                <div className={`modal-card Dialog ${isFullWidth ? 'Dialog--full-width' : ''}`}>
                    <header className="modal-card-head">
                        <h2 className="modal-card-title">{title}</h2>
                        <button type="button" className="delete" aria-label="Fechar" onClick={close} />
                    </header>
                    <div className="modal-card-body">{content}</div>
                </div>
            </div>
        );
    }
}

export default withRouter(Dialog);
