import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import TagCollection from '../../collections/Tag/TagCollection';

export default function TagRemovedIndexPage() {
    return (
        <DefaultLayout
            title="Tags removidas"
            tabs={[
                { linkTo: '/tags', text: 'Ativas' },
                { linkTo: '/tags/removidas', text: 'Removidas' },
            ]}
        >
            <TagCollection paginateRequest="paginateRemoved" />
        </DefaultLayout>
    );
}
