import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import TagCreateOrUpdateForm from '../../forms/Tag/TagCreateOrUpdateForm';

export default function TagRemovedPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Tag removida">
            <TagCreateOrUpdateForm id={id} onSubmitRequest="restore" />
        </DefaultLayout>
    );
}
