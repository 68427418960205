import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import UserRevisionCollection from '../../collections/User/UserRevisionCollection';

export default function UserRevisionsPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Histórico do usuário"
            tabs={[
                { linkTo: `/usuarios/${id}`, text: 'Editar' },
                { linkTo: `/usuarios/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <UserRevisionCollection id={id} request="listUserRevisions" linkToPrefix="usuarios" />
        </DefaultLayout>
    );
}
