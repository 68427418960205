import React from 'react';
import { Link } from 'react-router-dom';

import DefaultLayout from '../../layouts/DefaultLayout';
import ImageCollection from '../../collections/Image/ImageCollection';

export default function ImageIndexPage() {
    return (
        <DefaultLayout
            title="Mídias"
            tabs={[
                { linkTo: '/imagens', text: 'Ativas' },
                { linkTo: '/imagens/removidas', text: 'Removidas' },
            ]}
        >
            <ImageCollection />
            <div className="actions actions--sticky">
                <Link to="/imagens/adicionar" className="button is-primary is-medium">
                    Adicionar
                </Link>
            </div>
        </DefaultLayout>
    );
}
