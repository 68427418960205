import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import WorkGroupCreateOrUpdateForm from '../../forms/WorkGroup/WorkGroupCreateOrUpdateForm';

export default function WorkGroupCreatePage() {
    return (
        <DefaultLayout title="Adicionar grupo de trabalho">
            <WorkGroupCreateOrUpdateForm />
        </DefaultLayout>
    );
}
