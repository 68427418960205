import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { show, showRemoved, showRevision, update, restore } from '../../requests/user';
import authorize from '../../requests/authorization';
import withNotifications from '../../contexts/withNotifications';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';
import RevisionData from '../../components/Forms/RevisionData';

export const initialValues = {
    email: '',
    role: 'user',
};

const validationSchema = Yup.object({
    email: Yup.string()
        .required('Campo obrigatório')
        .email('Endereço de e-mail inválido')
        .max(191, 'O campo não pode ter mais que 191 caracteres'),
    role: Yup.string().required('Campo obrigatório').oneOf(['user', 'admin'], 'Opção inválida'),
});

class UserUpdateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isAuthorized: false,
            user: initialValues,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { id, showRequest, onSubmitRequest } = this.props;

        authorize(this, 'isAdminAndIsNotUser');

        if (showRequest === 'revision') {
            showRevision(this, id);
        } else if (onSubmitRequest === 'restore') {
            showRemoved(this, id);
        } else {
            show(this, id);
        }
    }

    onSubmit(values) {
        const { id, onSubmitRequest } = this.props;

        if (onSubmitRequest === 'restore') {
            restore(this, id);
        } else {
            update(this, id, values);
        }
    }

    render() {
        const { showRequest, onSubmitRequest } = this.props;
        const { isLoading, isAuthorized, user, revision } = this.state;

        return (
            <Formik
                initialValues={user}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
            >
                {({ errors, touched }) => (
                    <Form>
                        <fieldset
                            className="box"
                            disabled={!isAuthorized || showRequest === 'revision' || onSubmitRequest === 'restore'}
                        >
                            <div className="columns">
                                <div className="column">
                                    <Field
                                        name="email"
                                        label="E-mail"
                                        type="email"
                                        autoComplete="email"
                                        errors={errors.email}
                                        touched={touched.email}
                                    />
                                </div>
                                <div className="column">
                                    <Field
                                        name="role"
                                        label="Função"
                                        as="select"
                                        errors={errors.role}
                                        touched={touched.role}
                                    >
                                        <option value="user">Usuário</option>
                                        <option value="admin">Administrador</option>
                                    </Field>
                                </div>
                            </div>
                        </fieldset>

                        {showRequest === 'revision' && revision && (
                            <>
                                <hr className="invisible" />
                                <RevisionData revision={revision} />
                            </>
                        )}

                        {isAuthorized && showRequest !== 'revision' && (
                            <div className="actions actions--sticky">
                                <button
                                    type="submit"
                                    className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                                    disabled={isLoading}
                                >
                                    {onSubmitRequest === 'restore' ? 'Restaurar' : 'Editar'}
                                </button>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withNotifications(withRouter(UserUpdateForm));
