import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { resetPassword } from '../../requests/authentication';
import withNotifications from '../../contexts/withNotifications';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';

const initialValues = {
    password: '',
    password_confirmation: '',
};

const validationSchema = Yup.object({
    password: Yup.string()
        .required('Campo obrigatório')
        .matches(
            /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*+]).*$/,
            'A senha informada é muito fraca. Use letras maiúsculas e minúsculas, números e símbolos (!@#$%&*+).'
        )
        .min(8, 'A senha precisa ter no mínimo 8 caracteres'),
    password_confirmation: Yup.string()
        .required('Campo obrigatório')
        .oneOf([Yup.ref('password'), null], 'As senhas não coincidem'),
});

class AuthenticationResetPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        resetPassword(this, values, this.props.url);
    }

    render() {
        const { isLoading } = this.state;

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit}>
                {({ errors, touched }) => (
                    <Form>
                        <div className="box">
                            <Field
                                name="password"
                                label="Senha"
                                type="password"
                                autoComplete="new-password"
                                errors={errors.password}
                                touched={touched.password}
                            />
                            <Field
                                name="password_confirmation"
                                label="Confirmar senha"
                                type="password"
                                autoComplete="new-password"
                                errors={errors.password_confirmation}
                                touched={touched.password_confirmation}
                            />
                            <button
                                type="submit"
                                className={`button is-primary is-fullwidth ${isLoading ? 'is-loading' : ''}`}
                                disabled={isLoading}
                            >
                                Redefinir senha
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withNotifications(withRouter(AuthenticationResetPasswordForm));
