import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { show, create, update, showRemoved, showRevision, restore } from '../../requests/newsletter-section-template';
import withNotifications from '../../contexts/withNotifications';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import Form from '../../components/Forms/Form';
import Input from '../../components/Forms/Input';
import Select from '../../components/Forms/Select';
import ImageField from '../../components/Forms/ImageField';
import NewsletterSectionTemplateCollectionItem from '../../collections/NewsletterSectionTemplate/NewsletterSectionTemplateCollectionItem';
import RevisionData from '../../components/Forms/RevisionData';

export const initialValues = {
    title: '',
    color: '#e66100',
    style: 'simple',
    image: {
        id: undefined,
        url: undefined,
    },
};

export const validationSchema = Yup.object({
    title: Yup.string().required('Campo obrigatório').max(191, 'O campo não pode ter mais que 191 caracteres'),
    color: Yup.string()
        .required('Campo obrigatório')
        .min(7, 'O campo deve ser um código hexadecimal de cor com cerquilha')
        .max(7, 'O campo deve ser um código hexadecimal de cor com cerquilha'),
    style: Yup.string().required('Campo obrigatório').oneOf(['simple', 'highlighted'], 'Opção inválida'),
});

class NewsletterSectionTemplateCreateOrUpdateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            newsletterSectionTemplate: initialValues,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.openImageField = this.openImageField.bind(this);
    }

    componentDidMount() {
        const { id, showRequest, onSubmitRequest } = this.props;

        if (id) {
            if (showRequest === 'revision') {
                showRevision(this, id);
            } else if (onSubmitRequest === 'restore') {
                showRemoved(this, id);
            } else {
                show(this, id);
            }
        }
    }

    onSubmit(values) {
        const { id, onSubmitRequest } = this.props;

        if (id) {
            if (onSubmitRequest === 'restore') {
                restore(this, id);
            } else {
                update(this, id, values);
            }
        } else {
            create(this, values);
        }
    }

    openImageField(setFieldValue) {
        const { dialogs } = this.props;

        dialogs.add(
            dialogsKeys.imageField,
            'Gerenciador de Mídias',
            <ImageField
                mimeType="image/jpeg"
                width={1600}
                height={400}
                aspectRatio={4}
                setImageId={(value) => setFieldValue('image.id', value)}
                setImageUrl={(value) => setFieldValue('image.url', value)}
            />,
            true
        );
    }

    render() {
        const { id, showRequest, withoutBox, onSubmitRequest, disabled } = this.props;
        const { isLoading, newsletterSectionTemplate, revision } = this.state;

        return (
            <Formik
                initialValues={newsletterSectionTemplate}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <fieldset
                            className={`ImageUpdateForm ${!withoutBox ? 'box' : ''}`}
                            disabled={disabled || showRequest === 'revision' || onSubmitRequest === 'restore'}
                        >
                            <div className="columns">
                                <div className="column is-one-third">
                                    <Input
                                        name="title-controller"
                                        label="Título"
                                        value={values.title}
                                        onChange={(event) => setFieldValue('title', event.target.value)}
                                        errors={errors.title}
                                        touched={touched.title}
                                        isUppercase
                                    />
                                    <Input
                                        name="color-controller"
                                        label="Cor"
                                        type="color"
                                        value={values.color}
                                        onChange={(event) => setFieldValue('color', event.target.value)}
                                        errors={errors.color}
                                        touched={touched.color}
                                    />
                                    <Select
                                        name="style-controller"
                                        label="Estilo"
                                        value={values.style}
                                        onChange={(event) => setFieldValue('style', event.target.value)}
                                        errors={errors.style}
                                        touched={touched.style}
                                    >
                                        <option value="simple">Simples</option>
                                        <option value="highlighted">Destacado</option>
                                    </Select>
                                    <h2 className="label">Imagem</h2>
                                    {values.image && values.image.id ? (
                                        <div className="buttons">
                                            <button
                                                type="button"
                                                className={`button ${isLoading ? 'is-loading' : ''}`}
                                                disabled={isLoading}
                                                onClick={() => this.openImageField(setFieldValue)}
                                            >
                                                Trocar imagem
                                            </button>
                                            <button
                                                type="button"
                                                className={`button is-danger is-light ${isLoading ? 'is-loading' : ''}`}
                                                disabled={isLoading}
                                                onClick={() => setFieldValue('image', undefined)}
                                            >
                                                Remover imagem
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            type="button"
                                            className={`button ${isLoading ? 'is-loading' : ''}`}
                                            disabled={isLoading}
                                            onClick={() => this.openImageField(setFieldValue)}
                                        >
                                            Adicionar imagem
                                        </button>
                                    )}
                                </div>
                                <div className="column is-two-thirds">
                                    <NewsletterSectionTemplateCollectionItem template={values} />
                                </div>
                            </div>
                        </fieldset>

                        {showRequest === 'revision' && revision && (
                            <>
                                <hr className="invisible" />
                                <RevisionData revision={revision} />
                            </>
                        )}

                        {showRequest !== 'revision' && (
                            <div className="actions actions--sticky">
                                <button
                                    type="submit"
                                    className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                                    disabled={isLoading}
                                >
                                    {onSubmitRequest === 'restore' ? 'Restaurar' : id ? 'Editar' : 'Adicionar'}
                                </button>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withNotifications(withDialogs(withRouter(NewsletterSectionTemplateCreateOrUpdateForm)));
