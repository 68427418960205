import React from 'react';

export default class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            slideOut: false,
        };
        this.timeoutSlideOut = null;
        this.timeoutDismiss = null;
    }

    componentDidMount() {
        this.setState({ active: true });
    }

    componentDidUpdate() {
        const { handleDismiss, withExtraTime } = this.props;
        const { active } = this.state;

        const slideOut = () => {
            this.setState({ active: false, slideOut: true });
            clearTimeout(this.timeoutSlideOut);
        };

        const dismiss = () => {
            this.setState({ slideOut: false });
            handleDismiss();
            clearTimeout(this.timeoutDismiss);
        };

        if (!this.timeoutSlideOut && active === true) {
            this.timeoutSlideOut = setTimeout(slideOut, withExtraTime ? 10000 : 5000);
        }

        if (!this.timeoutDismiss && active === false) {
            this.timeoutDismiss = setTimeout(dismiss, 250);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutSlideOut);
        clearTimeout(this.timeoutDismiss);
    }

    render() {
        const { status, content, handleDismiss } = this.props;
        const { active, slideOut } = this.state;

        return (
            <div
                className={`Notification notification is-${status || 'info'} ${active ? 'Notification--active' : ''} ${
                    slideOut ? 'Notification--slide-out' : ''
                }`}
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
            >
                <button type="button" className="delete" aria-label="Fechar" onClick={handleDismiss} />
                {content}
            </div>
        );
    }
}
