import React from 'react';

export default class LinkElement extends React.Component {
    render() {
        const { contentState, entityKey, children } = this.props;
        const { href, target } = contentState.getEntity(entityKey).getData();

        if (target === '_blank') {
            return (
                <a href={href} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            );
        }

        return <a href={href}>{children}</a>;
    }
}
