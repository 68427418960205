import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import MemberRevisionCollection from '../../collections/Member/MemberRevisionCollection';

export default function MemberRevisionIndexPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Histórico de membro"
            tabs={[
                { linkTo: `/membros/${id}`, text: 'Editar' },
                { linkTo: `/membros/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <MemberRevisionCollection id={id} request="listUserRevisions" linkToPrefix="membros" />
        </DefaultLayout>
    );
}
