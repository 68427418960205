import React from 'react';
import { Link } from 'react-router-dom';

import DefaultLayout from '../../layouts/DefaultLayout';
import InterviewCollection from '../../collections/Interview/InterviewCollection';

export default function InterviewIndexPage() {
    return (
        <DefaultLayout
            title="Entrevistas"
            tabs={[
                { linkTo: '/entrevistas', text: 'Ativas' },
                { linkTo: '/entrevistas/removidas', text: 'Removidas' },
            ]}
        >
            <InterviewCollection />
            <Link to="/entrevistas/adicionar" className="button is-primary is-medium">
                Adicionar
            </Link>
        </DefaultLayout>
    );
}
