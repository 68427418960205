import * as crud from './crud';
import * as revisionable from './revisionable';
import * as softDeletes from './soft-deletes';
import * as storable from '../services/storable';
import api from '../services/api';
import handleError from '../services/error-handler';
import { dialogsKeys } from '../contexts/withDialogs';

const URL_PREFIX = 'boletins';
const STATE_KEY = 'newsletter';
const STORAGE_KEY = 'newsletter';

/**
 * Faz a requisição de disparo do boletim.
 *
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} values
 */
export function send(component, id, values) {
    const { dialogs, notifications, history } = component.props;

    component.setState({ isLoading: true });

    api.post(`/${URL_PREFIX}/${id}/disparar`, values)
        .then((response) => {
            notifications.add(response.data.message, 'success');
            dialogs.remove(dialogsKeys.newsletterSend);

            if (values.recipients === 'default') {
                history.push(`/${URL_PREFIX}`);
            }
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            component.setState({ isLoading: false });
        });
}

/**
 * Faz a requisição de pré-visualização do boletim, e envia a resposta a um
 * componente.
 *
 * @export
 * @param {*} component
 * @param {*} id
 */
export function preview(component, id) {
    const { notifications } = component.props;

    component.setState({ isLoading: true });

    api.get(`/${URL_PREFIX}/${id}/pre-visualizacao/e-mail`)
        .then((response) => {
            component.setState({ preview: response.data });
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
        })
        .finally(() => {
            component.setState({ isLoading: false });
        });
}

/**
 * Lista as impressões de um boletim.
 *
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} page
 */
export function listImpressions(component, id, page) {
    const { notifications } = component.props;

    component.setState({ isLoading: true });

    api.get(`/${URL_PREFIX}/${id}/impressoes?page=${page}`)
        .then((response) => {
            component.setState({
                data: response.data.data,
                links: response.data.links,
                meta: response.data.meta,
            });
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
        })
        .finally(() => {
            component.setState({ isLoading: false });
        });
}

/**
 * Faz a requisição de listagem de redirecionamentos de um boletim.
 *
 * @export
 * @param {*} component
 * @param {*} id
 * @param {number} [page=1]
 */
export function listRedirects(component, id, page = 1) {
    const { notifications } = component.props;

    component.setState({ isLoading: true });

    api.get(`/${URL_PREFIX}/${id}/redirecionamentos?page=${page}`)
        .then((response) => {
            component.setState({
                data: response.data.data,
                links: response.data.links,
                meta: response.data.meta,
            });
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
        })
        .finally(() => {
            component.setState({ isLoading: false });
        });
}

/**
 * Faz a requisição de contagem de redirecionamentos de um boletim agrupados
 * por URL.
 *
 * @export
 * @param {*} component
 * @param {*} id
 * @param {number} [page=1]
 */
export function countRedirectsGroupedByUrl(component, id, page = 1) {
    const { notifications } = component.props;

    component.setState({ isLoading: true });

    api.get(`/${URL_PREFIX}/${id}/redirecionamentos/total-por-url?page=${page}`)
        .then((response) => {
            component.setState({
                data: response.data.data,
                links: response.data.links,
                meta: response.data.meta,
            });
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
        })
        .finally(() => {
            component.setState({ isLoading: false });
        });
}

/**
 * Lista as impressões e redirecionamentos dos boletins.
 *
 * @export
 * @param string filter
 * @param {*} component
 */
export function listImpressionsAndRedirects(component, filter = '', page = 1) {
    const { notifications } = component.props;

    api.get(`/${URL_PREFIX}/impressoes-e-redirecionamentos${filter ? `/${filter}` : ''}?page=${page}`)
        .then((response) => {
            component.setState({
                data: response.data.data,
                links: response.data.links,
                meta: response.data.meta,
            });
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
        });
}

/**
 * @export
 * @param {*} component
 * @param {*} page
 * @param {*} [options=null]
 */
export function paginate(component, page, options = null) {
    crud.paginate(URL_PREFIX, component, page, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} values
 * @param {*} [options=null]
 */
export function create(component, values, options = null) {
    const { history } = component.props;

    crud.create(URL_PREFIX, component, values, {
        ...options,
        storageKey: STORAGE_KEY,
        successCallbackFn: (response) => {
            history.push(`/${URL_PREFIX}/${response.data.id}`);
        },
    });
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function show(component, id, options) {
    crud.show(URL_PREFIX, STATE_KEY, component, id, {
        ...options,
        afterStateUpdateFn: () => showStoredValues(component, id),
    });
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} values
 * @param {*} [options=null]
 */
export function update(component, id, values, options = null) {
    const _options = {
        storageKey: STORAGE_KEY,
        successCallbackFn: () => show(component, id),
    };

    if (options && options.sendDialog) {
        const { dialogs } = component.props;

        _options.successCallbackFn = () => {
            show(component, id);
            dialogs.add(dialogsKeys.newsletterSend, 'Enviar boletim', options.sendDialog, true);
        };
    }

    crud.update(URL_PREFIX, component, id, values, _options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function remove(component, id, options = null) {
    crud.remove(URL_PREFIX, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} page
 */
export function paginateRemoved(component, page) {
    softDeletes.paginateRemoved(URL_PREFIX, component, page);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function showRemoved(component, id, options = null) {
    softDeletes.showRemoved(URL_PREFIX, STATE_KEY, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function restore(component, id) {
    softDeletes.restore(URL_PREFIX, component, id);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function removePermanently(component, id) {
    softDeletes.removePermanently(URL_PREFIX, component, id);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} page
 */
export function listRevisions(component, id, page) {
    revisionable.listRevisions(URL_PREFIX, component, id, page);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function showRevision(component, id, options = null) {
    revisionable.showRevision(URL_PREFIX, STATE_KEY, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} prevProps
 * @param {*} [options=null]
 */
export function storeValues(component, initialValues, options = null) {
    storable.storeValues(STORAGE_KEY, component, initialValues, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} [id=null]
 * @param {*} [options=null]
 */
export function showStoredValues(component, id = null, options = null) {
    storable.getStoredValues(STORAGE_KEY, STATE_KEY, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} [id=null]
 */
export function removeStoredValues(component, initialValues, id = null) {
    storable.removeStoredValues(STORAGE_KEY, STATE_KEY, initialValues, show, component, id);
}
