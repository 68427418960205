import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ImageRevisionCollection from '../../collections/Image/ImageRevisionCollection';

export default function ImageRevisionIndexPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Histórico da imagem"
            tabs={[
                { linkTo: `/imagens/${id}`, text: 'Visualizar' },
                { linkTo: `/imagens/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <ImageRevisionCollection id={id} request="listUserRevisions" linkToPrefix="imagens" />
        </DefaultLayout>
    );
}
