import React from 'react';
import { FieldArray } from 'formik';

import NewsletterSectionItemsField from './NewsletterSectionItemsField';

export default class NewsletterSectionField extends React.PureComponent {
    /**
     * @inheritdoc
     */
    render() {
        const { index, name, length, values, errors, touched, setFieldValue, arrayHelpers, isLoading } = this.props;

        return (
            <>
                <div className="buttons">
                    <button
                        type="button"
                        className={`button is-small ${isLoading ? 'is-loading' : ''}`}
                        disabled={index === 0 || isLoading}
                        onClick={() => arrayHelpers.move(index, index - 1)}
                    >
                        Seção para cima
                    </button>
                    <button
                        type="button"
                        className={`button is-small ${isLoading ? 'is-loading' : ''}`}
                        disabled={index + 1 === length || isLoading}
                        onClick={() => arrayHelpers.move(index, index + 1)}
                    >
                        Seção para baixo
                    </button>
                    <button
                        type="button"
                        className={`button is-small ${isLoading ? 'is-loading' : ''}`}
                        disabled={isLoading}
                        onClick={() => arrayHelpers.remove(index)}
                    >
                        Remover seção
                    </button>
                </div>
                <article
                    style={{ color: values.color }}
                    className={`NewsletterSectionField${
                        values.style ? ` NewsletterSectionField--${values.style}` : ''
                    }`}
                >
                    <h3 className="NewsletterSectionField__title">
                        {values.image && values.image.id ? (
                            <img src={`${process.env.REACT_APP_BASE_WEB_URL}${values.image.url}`} alt={values.title} />
                        ) : (
                            <span>
                                <strong>{values.title}</strong>
                            </span>
                        )}
                    </h3>
                    <FieldArray
                        name={`${name}.items`}
                        render={(arrayHelpers) => (
                            <NewsletterSectionItemsField
                                parentName={`${name}.items`}
                                values={values.items}
                                errors={errors && errors.items}
                                touched={touched && touched.items}
                                setFieldValue={setFieldValue}
                                arrayHelpers={arrayHelpers}
                                isLoading={isLoading}
                            />
                        )}
                    />
                </article>
            </>
        );
    }
}
