import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ImageCollection from '../../collections/Image/ImageCollection';

export default function ImageRemovedIndexPage() {
    return (
        <DefaultLayout
            title="Mídias removidas"
            tabs={[
                { linkTo: '/imagens', text: 'Ativas' },
                { linkTo: '/imagens/removidas', text: 'Removidas' },
            ]}
        >
            <ImageCollection paginateRequest="paginateRemoved" />
        </DefaultLayout>
    );
}
