import React from 'react';

import { unsubscribe } from '../../requests/newsletter-recipient';
import withNotifications from '../../contexts/withNotifications';
import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';

class NewsletterRecipientUnsubscribePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: 'Estamos te desinscrevendo da lista de recebimento do nosso boletim...',
        };
    }

    componentDidMount() {
        const { location } = this.props;
        const url = new URLSearchParams(location.search).get('url');

        unsubscribe(this, url);
    }

    render() {
        return (
            <UnauthenticatedLayout title="Desinscrição no boletim">
                <p className="subtitle">{this.state.message}</p>
            </UnauthenticatedLayout>
        );
    }
}

export default withNotifications(NewsletterRecipientUnsubscribePage);
