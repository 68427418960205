import React from 'react';
import { Link } from 'react-router-dom';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterRecipientCollection from '../../collections/NewsletterRecipient/NewsletterRecipientCollection';

export default function NewsletterRecipientListPage() {
    return (
        <DefaultLayout
            title="Destinatários de boletim"
            tabs={[
                { linkTo: '/destinatarios-de-boletim', text: 'Ativos' },
                { linkTo: '/destinatarios-de-boletim/removidos', text: 'Removidos' },
            ]}
        >
            <NewsletterRecipientCollection />
            <Link to="/destinatarios-de-boletim/adicionar" className="button is-primary is-medium">
                Adicionar
            </Link>
        </DefaultLayout>
    );
}
