import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';

import { show, showRemoved, showRevision } from '../../requests/newsletter-recipient';
import withNotifications from '../../contexts/withNotifications';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';
import RevisionData from '../../components/Forms/RevisionData';

const initialValues = {
    email: '',
    active: '0',
};

class NewsletterRecipientUpdateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            newsletterRecipient: initialValues,
        };
    }

    componentDidMount() {
        const { id, showRequest, onSubmitRequest } = this.props;

        if (showRequest === 'revision') {
            showRevision(this, id);
        } else if (onSubmitRequest === 'restore') {
            showRemoved(this, id);
        } else {
            show(this, id);
        }
    }

    render() {
        const { showRequest } = this.props;
        const { newsletterRecipient, revision } = this.state;

        return (
            <Formik initialValues={newsletterRecipient} enableReinitialize>
                {({ errors, touched }) => (
                    <Form>
                        <fieldset className="box" disabled>
                            <div className="columns">
                                <div className="column">
                                    <Field
                                        name="email"
                                        label="E-mail"
                                        type="email"
                                        autoComplete="email"
                                        errors={errors.email}
                                        touched={touched.email}
                                    />
                                </div>
                                <div className="column">
                                    <Field
                                        name="active"
                                        label="Status"
                                        as="select"
                                        errors={errors.active}
                                        touched={touched.active}
                                    >
                                        <option value="1">Inscrito(a)</option>
                                        <option value="0">Desinscrito(a)</option>
                                    </Field>
                                </div>
                            </div>
                        </fieldset>
                        {showRequest === 'revision' && revision && (
                            <>
                                <hr className="invisible" />
                                <RevisionData revision={revision} />
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withNotifications(withRouter(NewsletterRecipientUpdateForm));
