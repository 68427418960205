import React from 'react';

export default class Pagination extends React.Component {
    render() {
        const { links, meta, goToPage } = this.props;

        return (
            <nav className="pagination is-small">
                {meta.last_page > 1 && (
                    <>
                        <button
                            type="button"
                            className="pagination-previous"
                            disabled={!links.prev}
                            onClick={() => goToPage(meta.current_page - 1)}
                        >
                            Anterior
                        </button>
                        <button
                            type="button"
                            className="pagination-next"
                            disabled={!links.next}
                            onClick={() => goToPage(meta.current_page + 1)}
                        >
                            Próxima
                        </button>
                    </>
                )}
                <span className="pagination-list">{`Mostrando ${meta.from} a ${meta.to} de ${meta.total}`}</span>
            </nav>
        );
    }
}
