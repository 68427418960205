import React from 'react';

import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';
import AuthenticationLoginForm from '../../forms/Authentication/AuthenticationLoginForm';

export default function AuthenticationLoginPage() {
    return (
        <UnauthenticatedLayout title="Entrar">
            <AuthenticationLoginForm />
        </UnauthenticatedLayout>
    );
}
