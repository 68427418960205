import React from 'react';

import { insertBlock } from '../../services/editor';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import SoundCloudPrompter from './SoundCloudPrompter';

class SoundCloudTool extends React.Component {
    constructor(props) {
        super(props);
        this.confirmSoundCloud = this.confirmSoundCloud.bind(this);
    }

    confirmSoundCloud(url, caption, position) {
        insertBlock(this, 'SOUNDCLOUD', { url, caption, position });
        this.props.dialogs.remove(dialogsKeys.editorBlock);
    }

    render() {
        const { editorState, setEditorState } = this.props;

        return (
            <SoundCloudPrompter
                editorState={editorState}
                setEditorState={setEditorState}
                confirmSoundCloud={this.confirmSoundCloud}
                isToolButton
            >
                SoundCloud
            </SoundCloudPrompter>
        );
    }
}

export default withDialogs(SoundCloudTool);
