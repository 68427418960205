import React from 'react';

import withNotifications from '../../contexts/withNotifications';
import WorkGroupField from './WorkGroupField';

class WorkGroupsField extends React.PureComponent {
    constructor(props) {
        super(props);
        this.add = this.add.bind(this);
    }

    add(value) {
        const { arrayHelpers } = this.props;
        arrayHelpers.push(value);
    }

    render() {
        const { values, errors, touched, arrayHelpers, showRequest, onSubmitRequest } = this.props;

        return (
            <>
                {values && Boolean(values.length) && (
                    <div className="tags">
                        {values.map((workGroup, i) => (
                            <span key={i} className="tag cdts-tag">
                                {workGroup.title}
                                <button
                                    type="button"
                                    className="delete is-small"
                                    onClick={() => arrayHelpers.remove(i)}
                                />
                            </span>
                        ))}
                    </div>
                )}

                {touched && errors && typeof errors === 'string' && (
                    <p className="menu-label has-text-centered has-text-danger py-3">{errors}</p>
                )}

                {showRequest !== 'revision' && onSubmitRequest !== 'restore' && (
                    <WorkGroupField addFn={this.add} values={values} />
                )}
            </>
        );
    }
}

export default withNotifications(WorkGroupsField);
