import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ClippingCreateOrUpdateForm from '../../forms/Clipping/ClippingCreateOrUpdateForm';

export default function ClippingRemovedPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Na mídia removido">
            <ClippingCreateOrUpdateForm id={id} onSubmitRequest="restore" />
        </DefaultLayout>
    );
}
