import * as crud from './crud';
import * as revisionable from './revisionable';
import * as softDeletes from './soft-deletes';

const URL_PREFIX = 'membros';
const STATE_KEY = 'member';

/**
 * @export
 * @param {*} component
 * @param {*} page
 * @param {*} [options=null]
 */
export function paginate(component, page, options = null) {
    crud.paginate(URL_PREFIX, component, page, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} values
 * @param {*} [options=null]
 */
export function create(component, values, options = null) {
    crud.create(URL_PREFIX, component, values, {
        ...options,
        transformData: (data) => {
            data.image_id = data.image.id; 

            return data;
        }
    });
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function show(component, id, options = null) {
    crud.show(URL_PREFIX, STATE_KEY, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} values
 */
export function update(component, id, values, options = null) {
    crud.update(URL_PREFIX, component, id, values, {
        ...options,
        transformData: (data) => {
            data.image_id = data.image.id; 

            return data;
        }
    });
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function remove(component, id, options = null) {
    crud.remove(URL_PREFIX, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} page
 */
export function paginateRemoved(component, page) {
    softDeletes.paginateRemoved(URL_PREFIX, component, page);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function showRemoved(component, id) {
    softDeletes.showRemoved(URL_PREFIX, STATE_KEY, component, id);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function restore(component, id) {
    softDeletes.restore(URL_PREFIX, component, id);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function removePermanently(component, id) {
    softDeletes.removePermanently(URL_PREFIX, component, id);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} page
 */
export function listRevisions(component, id, page) {
    revisionable.listRevisions(URL_PREFIX, component, id, page);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function showRevision(component, id) {
    revisionable.showRevision(URL_PREFIX, STATE_KEY, component, id);
}
