import React from 'react';

export const redirectCountGroupedByUrlTableHeaders = ['URL', 'Cliques'];

export default function RedirectCountGroupedByUrlTableRow(props) {
    const { data } = props;

    return (
        <tr key={data.url}>
            <td>
                <a href={data.url}>{data.url}</a>
            </td>
            <td>{data.total}</td>
        </tr>
    );
}
