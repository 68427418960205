/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Editor as DraftJsEditor, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';

export default class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
        this.handleKeyCommand = this.handleKeyCommand.bind(this);
        this.focus = this.focus.bind(this);
    }

    handleKeyCommand(command) {
        const { editorState, setEditorState } = this.props;
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            setEditorState(newState);
            return true;
        }

        return false;
    }

    focus() {
        return this.editorRef.current.focus();
    }

    render() {
        const { editorState, setEditorState, blockRenderMap, blockStyleFn, blockRendererFn, children } = this.props;

        let className = 'RichEditor-editor Editor';
        const contentState = editorState.getCurrentContent();

        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            <div className="RichEditor-root">
                <div role="textbox" tabIndex="0" className={className} onClick={this.focus}>
                    <DraftJsEditor
                        blockRenderMap={blockRenderMap}
                        blockStyleFn={blockStyleFn}
                        blockRendererFn={blockRendererFn}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        onChange={setEditorState}
                        placeholder="Escreva algo..."
                        ref={this.editorRef}
                        spellCheck
                        stripPastedStyles
                    />
                </div>
                {children}
            </div>
        );
    }
}
