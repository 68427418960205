import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import InterviewCreateOrUpdateForm from '../../forms/Interview/InterviewCreateOrUpdateForm';
import InterviewRemoveForm from '../../forms/Interview/InterviewRemoveForm';

export default function InterviewUpdatePage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Editar entrevista"
            isFullWidth
            tabs={[
                { linkTo: `/entrevistas/${id}`, text: 'Editar' },
                { linkTo: `/entrevistas/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <InterviewCreateOrUpdateForm id={id} />
            <br />
            <InterviewRemoveForm id={id} />
        </DefaultLayout>
    );
}
