import React from 'react';

import { selectByTitle } from '../../requests/tag';
import withNotifications from '../../contexts/withNotifications';
import TagField from './TagField';

class TagsField extends React.PureComponent {
    constructor(props) {
        super(props);
        this.add = this.add.bind(this);
    }

    add(title, controllerComponent) {
        const { values, arrayHelpers, notifications } = this.props;

        console.log(!values || values.filter((e) => e.title === title).length === 0);

        if (!values || values.filter((e) => e.title === title).length === 0) {
            selectByTitle(this, title, arrayHelpers, controllerComponent);
        } else {
            notifications.add('Essa tag já foi incluída.', 'danger');
        }
    }

    render() {
        const { values, errors, touched, arrayHelpers, showRequest, onSubmitRequest } = this.props;

        return (
            <>
                {values && Boolean(values.length) && (
                    <div className="tags">
                        {values.map((tag, i) => (
                            <span key={i} className="tag cdts-tag">
                                {tag.title}
                                <button
                                    type="button"
                                    className="delete is-small"
                                    onClick={() => arrayHelpers.remove(i)}
                                />
                            </span>
                        ))}
                    </div>
                )}

                {touched && errors && typeof errors === 'string' && (
                    <p className="menu-label has-text-centered has-text-danger py-3">{errors}</p>
                )}

                {showRequest !== 'revision' && onSubmitRequest !== 'restore' && (
                    <div className="columns">
                        <div className="column">
                            <TagField addFn={this.add} />
                        </div>
                        <div className="column" />
                    </div>
                )}
            </>
        );
    }
}

export default withNotifications(TagsField);
