import React from 'react';

import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';
import UserCreateForm from '../../forms/User/UserCreateForm';

export default function UserCreatePage(props) {
    const url = new URLSearchParams(props.location.search).get('url');

    return (
        <UnauthenticatedLayout title="Cadastro no sistema">
            <UserCreateForm url={url} />
        </UnauthenticatedLayout>
    );
}
