import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import OpinionCollection from '../../collections/Opinion/OpinionCollection';

export default function OpinionRemovedIndexPage() {
    return (
        <DefaultLayout
            title="Opinião do especialista removidos"
            tabs={[
                { linkTo: '/opiniao-do-especialista', text: 'Ativas' },
                { linkTo: '/opiniao-do-especialista/removidos', text: 'Removidos' },
            ]}
        >
            <OpinionCollection paginateRequest="paginateRemoved" />
        </DefaultLayout>
    );
}
