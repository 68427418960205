import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ClippingSourceCreateOrUpdateForm from '../../forms/ClippingSource/ClippingSourceCreateOrUpdateForm';

export default function ClippingSourceRevisionPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Versão de fonte de mídia">
            <ClippingSourceCreateOrUpdateForm id={id} showRequest="revision" />
        </DefaultLayout>
    );
}
