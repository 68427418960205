import React from 'react';
import { Link } from 'react-router-dom';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterSectionTemplateCollection from '../../collections/NewsletterSectionTemplate/NewsletterSectionTemplateCollection';

export default function NewsletterSectionTemplateListPage() {
    return (
        <DefaultLayout
            title="Seções de boletim"
            tabs={[
                { linkTo: '/secoes-de-boletim', text: 'Ativas' },
                { linkTo: '/secoes-de-boletim/removidas', text: 'Removidas' },
            ]}
        >
            <NewsletterSectionTemplateCollection />
            <div className="actions actions--sticky">
                <Link to="/secoes-de-boletim/adicionar" className="button is-primary is-medium">
                    Adicionar
                </Link>
            </div>
        </DefaultLayout>
    );
}
