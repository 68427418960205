import React from 'react';

import Notification from '../components/Notification';

export const NotificationsContext = React.createContext();

export default class NotificationsProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
        };
        this.id = 0;
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);
    }

    add(content, status = 'info', withExtraTime = false) {
        const { id } = this;

        this.setState((prevState) => ({
            notifications: [
                ...prevState.notifications,
                {
                    id,
                    content,
                    status,
                    withExtraTime,
                },
            ],
        }));

        this.id += 1;

        return id;
    }

    remove(id) {
        const { notifications } = this.state;

        this.setState({
            notifications: notifications.filter((notification) => notification.id !== id),
        });
    }

    render() {
        const { children } = this.props;
        const { notifications } = this.state;

        return (
            <NotificationsContext.Provider value={{ add: this.add, remove: this.remove }}>
                {children}
                {Boolean(notifications.length) && (
                    <div className="NotificationsProvider" aria-live="polite" aria-atomic="true">
                        {notifications.map(({ id, content, status, withExtraTime }) => (
                            <Notification
                                key={id}
                                content={content}
                                status={status}
                                withExtraTime={withExtraTime}
                                handleDismiss={() => this.remove(id)}
                            />
                        ))}
                    </div>
                )}
            </NotificationsContext.Provider>
        );
    }
}
