import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import PostCreateOrUpdateForm from '../../forms/Post/PostCreateOrUpdateForm';

export default function PostRevisionPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Versão de notícia" isFullWidth>
            <PostCreateOrUpdateForm id={id} showRequest="revision" />
        </DefaultLayout>
    );
}
