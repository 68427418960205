import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { isAuthenticated } from './services/authentication';

import HomePage from './pages/HomePage';

import AuthenticationLoginPage from './pages/Authentication/AuthenticationLoginPage';
import AuthenticationLogoutPage from './pages/Authentication/AuthenticationLogoutPage';
import AuthenticationForgotPasswordPage from './pages/Authentication/AuthenticationForgotPasswordPage';
import AuthenticationResetPasswordPage from './pages/Authentication/AuthenticationResetPasswordPage';
import AuthenticationUpdateEmailPage from './pages/Authentication/AuthenticationUpdateEmailPage';
import AuthenticationUpdatePasswordPage from './pages/Authentication/AuthenticationUpdatePasswordPage';

import UserIndexPage from './pages/User/UserIndexPage';
import UserCreatePage from './pages/User/UserCreatePage';
import UserRequestCreatePage from './pages/User/UserRequestCreatePage';
import UserRemovedPage from './pages/User/UserRemovedPage';
import UserRemovedIndexPage from './pages/User/UserRemovedIndexPage';
import UserUpdatePage from './pages/User/UserUpdatePage';
import UserRevisionIndexPage from './pages/User/UserRevisionIndexPage';
import UserRevisionPage from './pages/User/UserRevisionPage';

import ImageIndexPage from './pages/Image/ImageIndexPage';
import ImageCreatePage from './pages/Image/ImageCreatePage';
import ImageRemovedPage from './pages/Image/ImageRemovedPage';
import ImageRemovedIndexPage from './pages/Image/ImageRemovedIndexPage';
import ImageUpdatePage from './pages/Image/ImageUpdatePage';
import ImageRevisionIndexPage from './pages/Image/ImageRevisionIndexPage';
import ImageRevisionPage from './pages/Image/ImageRevisionPage';

import TagIndexPage from './pages/Tag/TagIndexPage';
import TagCreatePage from './pages/Tag/TagCreatePage';
import TagRemovedPage from './pages/Tag/TagRemovedPage';
import TagRemovedIndexPage from './pages/Tag/TagRemovedIndexPage';
import TagUpdatePage from './pages/Tag/TagUpdatePage';
import TagRevisionIndexPage from './pages/Tag/TagRevisionIndexPage';
import TagRevisionPage from './pages/Tag/TagRevisionPage';

import NewsletterRecipientRequestCreatePage from './pages/NewsletterRecipient/NewsletterRecipientRequestCreatePage';
import NewsletterRecipientIndexPage from './pages/NewsletterRecipient/NewsletterRecipientIndexPage';
import NewsletterRecipientRemovedIndexPage from './pages/NewsletterRecipient/NewsletterRecipientRemovedIndexPage';
import NewsletterRecipientSubscribePage from './pages/NewsletterRecipient/NewsletterRecipientSubscribePage';
import NewsletterRecipientUnsubscribePage from './pages/NewsletterRecipient/NewsletterRecipientUnsubscribePage';
import NewsletterRecipientRequestSubscriptionPage from './pages/NewsletterRecipient/NewsletterRecipientRequestSubscriptionPage';
import NewsletterRecipientSubscriptionRequestedPage from './pages/NewsletterRecipient/NewsletterRecipientSubscriptionRequestedPage';
import NewsletterRecipientRevisionPage from './pages/NewsletterRecipient/NewsletterRecipientRevisionPage';
import NewsletterRecipientRevisionIndexPage from './pages/NewsletterRecipient/NewsletterRecipientRevisionIndexPage';
import NewsletterRecipientRemovedPage from './pages/NewsletterRecipient/NewsletterRecipientRemovedPage';
import NewsletterRecipientUpdatePage from './pages/NewsletterRecipient/NewsletterRecipientUpdatePage';

import NewsletterSectionTemplateIndexPage from './pages/NewsletterSectionTemplate/NewsletterSectionTemplateIndexPage';
import NewsletterSectionTemplateCreatePage from './pages/NewsletterSectionTemplate/NewsletterSectionTemplateCreatePage';
import NewsletterSectionTemplateRemovedPage from './pages/NewsletterSectionTemplate/NewsletterSectionTemplateRemovedPage';
import NewsletterSectionTemplateRemovedIndexPage from './pages/NewsletterSectionTemplate/NewsletterSectionTemplateRemovedIndexPage';
import NewsletterSectionTemplateUpdatePage from './pages/NewsletterSectionTemplate/NewsletterSectionTemplateUpdatePage';
import NewsletterSectionTemplateRevisionIndexPage from './pages/NewsletterSectionTemplate/NewsletterSectionTemplateRevisionIndexPage';
import NewsletterSectionTemplateRevisionPage from './pages/NewsletterSectionTemplate/NewsletterSectionTemplateRevisionPage';

import NewsletterIndexPage from './pages/Newsletter/NewsletterIndexPage';
import NewsletterPreviewPage from './pages/Newsletter/NewsletterPreviewPage';
import NewsletterCreatePage from './pages/Newsletter/NewsletterCreatePage';
import NewsletterRemovedPage from './pages/Newsletter/NewsletterRemovedPage';
import NewsletterRemovedIndexPage from './pages/Newsletter/NewsletterRemovedIndexPage';
import NewsletterMetricsPage from './pages/Newsletter/NewsletterMetricsPage';
import NewsletterRevisionIndexPage from './pages/Newsletter/NewsletterRevisionIndexPage';
import NewsletterRevisionPage from './pages/Newsletter/NewsletterRevisionPage';
import NewsletterUpdatePage from './pages/Newsletter/NewsletterUpdatePage';

import ClippingSourceIndexPage from './pages/ClippingSource/ClippingSourceIndexPage';
import ClippingSourceCreatePage from './pages/ClippingSource/ClippingSourceCreatePage';
import ClippingSourceRemovedPage from './pages/ClippingSource/ClippingSourceRemovedPage';
import ClippingSourceRemovedIndexPage from './pages/ClippingSource/ClippingSourceRemovedIndexPage';
import ClippingSourceUpdatePage from './pages/ClippingSource/ClippingSourceUpdatePage';
import ClippingSourceRevisionIndexPage from './pages/ClippingSource/ClippingSourceRevisionIndexPage';
import ClippingSourceRevisionPage from './pages/ClippingSource/ClippingSourceRevisionPage';

import ClippingIndexPage from './pages/Clipping/ClippingIndexPage';
import ClippingCreatePage from './pages/Clipping/ClippingCreatePage';
import ClippingRemovedPage from './pages/Clipping/ClippingRemovedPage';
import ClippingRemovedIndexPage from './pages/Clipping/ClippingRemovedIndexPage';
import ClippingUpdatePage from './pages/Clipping/ClippingUpdatePage';
import ClippingRevisionIndexPage from './pages/Clipping/ClippingRevisionIndexPage';
import ClippingRevisionPage from './pages/Clipping/ClippingRevisionPage';

import PostIndexPage from './pages/Post/PostIndexPage';
import PostCreatePage from './pages/Post/PostCreatePage';
import PostRemovedPage from './pages/Post/PostRemovedPage';
import PostRemovedIndexPage from './pages/Post/PostRemovedIndexPage';
import PostUpdatePage from './pages/Post/PostUpdatePage';
import PostRevisionIndexPage from './pages/Post/PostRevisionIndexPage';
import PostRevisionPage from './pages/Post/PostRevisionPage';

import InterviewIndexPage from './pages/Interview/InterviewIndexPage';
import InterviewCreatePage from './pages/Interview/InterviewCreatePage';
import InterviewRemovedPage from './pages/Interview/InterviewRemovedPage';
import InterviewRemovedIndexPage from './pages/Interview/InterviewRemovedIndexPage';
import InterviewUpdatePage from './pages/Interview/InterviewUpdatePage';
import InterviewRevisionIndexPage from './pages/Interview/InterviewRevisionIndexPage';
import InterviewRevisionPage from './pages/Interview/InterviewRevisionPage';

import OpinionIndexPage from './pages/Opinion/OpinionIndexPage';
import OpinionCreatePage from './pages/Opinion/OpinionCreatePage';
import OpinionRemovedPage from './pages/Opinion/OpinionRemovedPage';
import OpinionRemovedIndexPage from './pages/Opinion/OpinionRemovedIndexPage';
import OpinionUpdatePage from './pages/Opinion/OpinionUpdatePage';
import OpinionRevisionIndexPage from './pages/Opinion/OpinionRevisionIndexPage';
import OpinionRevisionPage from './pages/Opinion/OpinionRevisionPage';

import MemberIndexPage from './pages/Member/MemberIndexPage';
import MemberCreatePage from './pages/Member/MemberCreatePage';
import MemberRemovedPage from './pages/Member/MemberRemovedPage';
import MemberRemovedIndexPage from './pages/Member/MemberRemovedIndexPage';
import MemberUpdatePage from './pages/Member/MemberUpdatePage';
import MemberRevisionIndexPage from './pages/Member/MemberRevisionIndexPage';
import MemberRevisionPage from './pages/Member/MemberRevisionPage';

import WorkGroupIndexPage from './pages/WorkGroup/WorkGroupIndexPage';
import WorkGroupCreatePage from './pages/WorkGroup/WorkGroupCreatePage';
import WorkGroupRemovedPage from './pages/WorkGroup/WorkGroupRemovedPage';
import WorkGroupRemovedIndexPage from './pages/WorkGroup/WorkGroupRemovedIndexPage';
import WorkGroupUpdatePage from './pages/WorkGroup/WorkGroupUpdatePage';
import WorkGroupRevisionIndexPage from './pages/WorkGroup/WorkGroupRevisionIndexPage';
import WorkGroupRevisionPage from './pages/WorkGroup/WorkGroupRevisionPage';

import NotFoundPage from './pages/NotFoundPage';

function PrivateRoute(props) {
    const { exact, path, component } = props;

    if (isAuthenticated()) {
        return <Route exact={exact} path={path} component={component} />;
    }

    return <Redirect to="/entrar" />;
}

export default function Routes() {
    return (
        <Switch>
            {/* Home */}
            <PrivateRoute exact path="/" component={HomePage} />
            {/* Authentication */}
            <Route path="/entrar" component={AuthenticationLoginPage} />
            <Route path="/sair" component={AuthenticationLogoutPage} />
            <Route path="/esqueci-minha-senha" component={AuthenticationForgotPasswordPage} />
            <Route path="/redefinir-senha" component={AuthenticationResetPasswordPage} />
            <PrivateRoute path="/atualizar-email" component={AuthenticationUpdateEmailPage} />
            <PrivateRoute path="/atualizar-senha" component={AuthenticationUpdatePasswordPage} />
            {/* User */}
            <Route path="/cadastrar" component={UserCreatePage} />
            <PrivateRoute exact path="/usuarios" component={UserIndexPage} />
            <PrivateRoute exact path="/usuarios/adicionar" component={UserRequestCreatePage} />
            <PrivateRoute path="/usuarios/removidos/:id" component={UserRemovedPage} />
            <PrivateRoute exact path="/usuarios/removidos" component={UserRemovedIndexPage} />
            <PrivateRoute path="/usuarios/historico/:id" component={UserRevisionPage} />
            <PrivateRoute path="/usuarios/:id/historico" component={UserRevisionIndexPage} />
            <PrivateRoute path="/usuarios/:id" component={UserUpdatePage} />
            {/* Image */}
            <PrivateRoute exact path="/imagens" component={ImageIndexPage} />
            <PrivateRoute exact path="/imagens/adicionar" component={ImageCreatePage} />
            <PrivateRoute path="/imagens/removidas/:id" component={ImageRemovedPage} />
            <PrivateRoute exact path="/imagens/removidas" component={ImageRemovedIndexPage} />
            <PrivateRoute path="/imagens/historico/:id" component={ImageRevisionPage} />
            <PrivateRoute path="/imagens/:id/historico" component={ImageRevisionIndexPage} />
            <PrivateRoute path="/imagens/:id" component={ImageUpdatePage} />
            {/* Tag */}
            <PrivateRoute exact path="/tags" component={TagIndexPage} />
            <PrivateRoute path="/tags/adicionar" component={TagCreatePage} />
            <PrivateRoute path="/tags/removidas/:id" component={TagRemovedPage} />
            <PrivateRoute exact path="/tags/removidas" component={TagRemovedIndexPage} />
            <PrivateRoute path="/tags/historico/:id" component={TagRevisionPage} />
            <PrivateRoute path="/tags/:id/historico" component={TagRevisionIndexPage} />
            <PrivateRoute path="/tags/:id" component={TagUpdatePage} />
            {/* NewsletterRecipient */}
            <Route path="/desinscrever" component={NewsletterRecipientUnsubscribePage} />
            <Route path="/inscricao" component={NewsletterRecipientRequestSubscriptionPage} />
            <Route path="/inscricao-solicitada" component={NewsletterRecipientSubscriptionRequestedPage} />
            <Route path="/confirmar-inscricao" component={NewsletterRecipientSubscribePage} />
            <PrivateRoute exact path="/destinatarios-de-boletim" component={NewsletterRecipientIndexPage} />
            <PrivateRoute path="/destinatarios-de-boletim/adicionar" component={NewsletterRecipientRequestCreatePage} />
            <PrivateRoute path="/destinatarios-de-boletim/removidos/:id" component={NewsletterRecipientRemovedPage} />
            <PrivateRoute path="/destinatarios-de-boletim/historico/:id" component={NewsletterRecipientRevisionPage} />
            <PrivateRoute
                path="/destinatarios-de-boletim/:id/historico"
                component={NewsletterRecipientRevisionIndexPage}
            />
            <PrivateRoute
                exact
                path="/destinatarios-de-boletim/removidos"
                component={NewsletterRecipientRemovedIndexPage}
            />
            <PrivateRoute path="/destinatarios-de-boletim/:id" component={NewsletterRecipientUpdatePage} />
            {/* NewsletterSectionTemplate */}
            <PrivateRoute exact path="/secoes-de-boletim" component={NewsletterSectionTemplateIndexPage} />
            <PrivateRoute exact path="/secoes-de-boletim/adicionar" component={NewsletterSectionTemplateCreatePage} />
            <PrivateRoute path="/secoes-de-boletim/removidas/:id" component={NewsletterSectionTemplateRemovedPage} />
            <PrivateRoute
                exact
                path="/secoes-de-boletim/removidas"
                component={NewsletterSectionTemplateRemovedIndexPage}
            />
            <PrivateRoute path="/secoes-de-boletim/historico/:id" component={NewsletterSectionTemplateRevisionPage} />
            <PrivateRoute
                path="/secoes-de-boletim/:id/historico"
                component={NewsletterSectionTemplateRevisionIndexPage}
            />
            <PrivateRoute path="/secoes-de-boletim/:id" component={NewsletterSectionTemplateUpdatePage} />
            {/* Newsletter */}
            <PrivateRoute exact path="/boletins" component={NewsletterIndexPage} />
            <PrivateRoute path="/boletins/adicionar" component={NewsletterCreatePage} />
            <PrivateRoute path="/boletins/removidos/:id" component={NewsletterRemovedPage} />
            <PrivateRoute exact path="/boletins/removidos" component={NewsletterRemovedIndexPage} />
            <PrivateRoute path="/boletins/historico/:id" component={NewsletterRevisionPage} />
            <PrivateRoute path="/boletins/:id/metricas" component={NewsletterMetricsPage} />
            <PrivateRoute path="/boletins/:id/historico" component={NewsletterRevisionIndexPage} />
            <PrivateRoute path="/boletins/:id/pre-visualizacao" component={NewsletterPreviewPage} />
            <PrivateRoute path="/boletins/:id" component={NewsletterUpdatePage} />
            {/* ClippingSource */}
            <PrivateRoute exact path="/fontes-de-midia" component={ClippingSourceIndexPage} />
            <PrivateRoute path="/fontes-de-midia/adicionar" component={ClippingSourceCreatePage} />
            <PrivateRoute path="/fontes-de-midia/removidas/:id" component={ClippingSourceRemovedPage} />
            <PrivateRoute exact path="/fontes-de-midia/removidas" component={ClippingSourceRemovedIndexPage} />
            <PrivateRoute path="/fontes-de-midia/historico/:id" component={ClippingSourceRevisionPage} />
            <PrivateRoute path="/fontes-de-midia/:id/historico" component={ClippingSourceRevisionIndexPage} />
            <PrivateRoute path="/fontes-de-midia/:id" component={ClippingSourceUpdatePage} />
            {/* Clipping */}
            <PrivateRoute exact path="/na-midia" component={ClippingIndexPage} />
            <PrivateRoute path="/na-midia/adicionar" component={ClippingCreatePage} />
            <PrivateRoute path="/na-midia/removidos/:id" component={ClippingRemovedPage} />
            <PrivateRoute exact path="/na-midia/removidos" component={ClippingRemovedIndexPage} />
            <PrivateRoute path="/na-midia/historico/:id" component={ClippingRevisionPage} />
            <PrivateRoute path="/na-midia/:id/historico" component={ClippingRevisionIndexPage} />
            <PrivateRoute path="/na-midia/:id" component={ClippingUpdatePage} />
            {/* Post */}
            <PrivateRoute exact path="/noticias" component={PostIndexPage} />
            <PrivateRoute path="/noticias/adicionar" component={PostCreatePage} />
            <PrivateRoute path="/noticias/removidas/:id" component={PostRemovedPage} />
            <PrivateRoute exact path="/noticias/removidas" component={PostRemovedIndexPage} />
            <PrivateRoute path="/noticias/historico/:id" component={PostRevisionPage} />
            <PrivateRoute path="/noticias/:id/historico" component={PostRevisionIndexPage} />
            <PrivateRoute path="/noticias/:id" component={PostUpdatePage} />
            {/* Interview */}
            <PrivateRoute exact path="/entrevistas" component={InterviewIndexPage} />
            <PrivateRoute path="/entrevistas/adicionar" component={InterviewCreatePage} />
            <PrivateRoute path="/entrevistas/removidas/:id" component={InterviewRemovedPage} />
            <PrivateRoute exact path="/entrevistas/removidas" component={InterviewRemovedIndexPage} />
            <PrivateRoute path="/entrevistas/historico/:id" component={InterviewRevisionPage} />
            <PrivateRoute path="/entrevistas/:id/historico" component={InterviewRevisionIndexPage} />
            <PrivateRoute path="/entrevistas/:id" component={InterviewUpdatePage} />
            {/* Opinion */}
            <PrivateRoute exact path="/opiniao-do-especialista" component={OpinionIndexPage} />
            <PrivateRoute path="/opiniao-do-especialista/adicionar" component={OpinionCreatePage} />
            <PrivateRoute path="/opiniao-do-especialista/removidos/:id" component={OpinionRemovedPage} />
            <PrivateRoute exact path="/opiniao-do-especialista/removidos" component={OpinionRemovedIndexPage} />
            <PrivateRoute path="/opiniao-do-especialista/historico/:id" component={OpinionRevisionPage} />
            <PrivateRoute path="/opiniao-do-especialista/:id/historico" component={OpinionRevisionIndexPage} />
            <PrivateRoute path="/opiniao-do-especialista/:id" component={OpinionUpdatePage} />
            {/* WorkGroup */}
            <PrivateRoute exact path="/grupos-de-trabalho" component={WorkGroupIndexPage} />
            <PrivateRoute path="/grupos-de-trabalho/adicionar" component={WorkGroupCreatePage} />
            <PrivateRoute path="/grupos-de-trabalho/removidos/:id" component={WorkGroupRemovedPage} />
            <PrivateRoute exact path="/grupos-de-trabalho/removidos" component={WorkGroupRemovedIndexPage} />
            <PrivateRoute path="/grupos-de-trabalho/historico/:id" component={WorkGroupRevisionPage} />
            <PrivateRoute path="/grupos-de-trabalho/:id/historico" component={WorkGroupRevisionIndexPage} />
            <PrivateRoute path="/grupos-de-trabalho/:id" component={WorkGroupUpdatePage} />
            {/* Member */}
            <PrivateRoute exact path="/membros" component={MemberIndexPage} />
            <PrivateRoute path="/membros/adicionar" component={MemberCreatePage} />
            <PrivateRoute path="/membros/removidos/:id" component={MemberRemovedPage} />
            <PrivateRoute exact path="/membros/removidos" component={MemberRemovedIndexPage} />
            <PrivateRoute path="/membros/historico/:id" component={MemberRevisionPage} />
            <PrivateRoute path="/membros/:id/historico" component={MemberRevisionIndexPage} />
            <PrivateRoute path="/membros/:id" component={MemberUpdatePage} />
            {/* NotFound */}
            <Route path="*" component={NotFoundPage} />
        </Switch>
    );
}
