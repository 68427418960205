import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import WorkGroupCollection from '../../collections/WorkGroup/WorkGroupCollection';

export default function WorkGroupRemovedIndexPage() {
    return (
        <DefaultLayout
            title="Grupos de trabalho removidos"
            tabs={[
                { linkTo: '/grupos-de-trabalho', text: 'Ativos' },
                { linkTo: '/grupos-de-trabalho/removidas', text: 'Removidos' },
            ]}
        >
            <WorkGroupCollection paginateRequest="paginateRemoved" />
        </DefaultLayout>
    );
}
