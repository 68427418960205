import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import 'moment/locale/pt-br';
import TextareaAutosize from 'react-autosize-textarea';

import placeholder_1600x1600 from '../../images/placeholders/1600x1600.png';
import { getDate } from '../../services/datetime';
import {
    show,
    create,
    update,
    showRemoved,
    showRevision,
    restore,
    storeValues,
    showStoredValues,
    removeStoredValues,
} from '../../requests/newsletter';
import withNotifications from '../../contexts/withNotifications';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';
import PublicationFields from '../../components/Forms/PublicationFields';
import NewsletterSectionsField from './NewsletterCreateOrUpdateForm/NewsletterSectionsField';
import RevisionData from '../../components/Forms/RevisionData';
import NewsletterSendForm from './NewsletterSendForm';
import ImageField from '../../components/Forms/ImageField';
import ShareIconsList from '../../components/Icons/ShareIconsList';

moment.locale('pt-br');

export const initialValues = {
    subject: '',
    title: '',
    date: '',
    summary: '',
    published: '1',
    published_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    sections: [],
};

export const validationSchema = Yup.object({
    subject: Yup.string().required('Campo obrigatório').max(191, 'O campo não pode ter mais que 191 caracteres'),
    title: Yup.string().required('Campo obrigatório').max(191, 'O campo não pode ter mais que 191 caracteres'),
    date: Yup.string().required('Campo obrigatório').max(191, 'O campo não pode ter mais que 191 caracteres'),
    summary: Yup.string().required('Campo obrigatório'),
    sections: Yup.array()
        .min(1, 'O boletim deve ter pelo menos uma seção')
        .of(
            Yup.object().shape({
                items: Yup.array()
                    .min(1, 'As seções do boletim devem ter pelo menos um item')
                    .of(
                        Yup.object().shape({
                            title: Yup.string().required('Campo obrigatório'),
                            text: Yup.string().required('Campo obrigatório'),
                            link_href: Yup.string().nullable().url('O campo deve ser uma URL válida'),
                        })
                    ),
            })
        ),
    published: Yup.bool().required('Campo obrigatório'),
    published_at: Yup.string().required('Campo obrigatório'),
});

class NewsletterCreatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            newsletter: initialValues,
            hasStoredValues: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.sendNewsletter = this.sendNewsletter.bind(this);
        this.removeStoredValues = this.removeStoredValues.bind(this);
        this.openThumbnailField = this.openThumbnailField.bind(this);
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { id, showRequest, onSubmitRequest } = this.props;

        if (id) {
            if (showRequest === 'revision') {
                showRevision(this, id);
            } else if (onSubmitRequest === 'restore') {
                showRemoved(this, id);
            } else {
                show(this, id);
            }
        } else {
            showStoredValues(this);
        }
    }

    /**
     * Cria, atualiza ou restaura o boletim ao submeter o formulário.
     * @param {*} values
     */
    onSubmit(values) {
        const { id, onSubmitRequest } = this.props;

        if (id) {
            if (onSubmitRequest === 'restore') {
                restore(this, id);
            } else {
                update(this, id, values);
            }
        } else {
            create(this, values);
        }
    }

    sendNewsletter(values) {
        const { id } = this.props;

        update(this, id, values, {
            sendDialog: <NewsletterSendForm id={id} />,
        });
    }

    /**
     * Remove os valores armazenados no navegador.
     */
    removeStoredValues() {
        const { id } = this.props;

        removeStoredValues(this, initialValues, id || null);
    }

    openThumbnailField(setFieldValue) {
        const { dialogs } = this.props;

        dialogs.add(
            dialogsKeys.imageField,
            'Gerenciador de Mídias',
            <ImageField
                mimeType="image/jpeg"
                width={1600}
                height={1600}
                aspectRatio={1}
                setImageId={(value) => setFieldValue('thumbnail.id', value)}
                setImageUrl={(value) => setFieldValue('thumbnail.url', value)}
            />,
            true
        );
    }

    /**
     * @inheritdoc
     */
    render() {
        const { id, showRequest, disabled, withoutBox, onSubmitRequest } = this.props;
        const { isLoading, newsletter, revision, hasStoredValues } = this.state;

        return (
            <Formik
                initialValues={newsletter}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
                validateOnChange={false}
            >
                {({ values, errors, touched, setFieldValue, isValid, submitCount }) => (
                    <Form
                        isValid={isValid}
                        submitCount={submitCount}
                        storeValues={storeValues}
                        values={values}
                        initialValues={newsletter}
                    >
                        <fieldset disabled={disabled || showRequest === 'revision' || onSubmitRequest === 'restore'}>
                            <div className={`NewsletterCreateOrUpdateForm ${!withoutBox ? 'box' : ''}`}>
                                <Field
                                    name="subject"
                                    label="Assunto do e-mail"
                                    errors={errors.subject}
                                    touched={touched.subject}
                                />

                                <hr className="is-invisible" />

                                <div className="NewsletterCreateOrUpdateForm__container">
                                    <div className="NewsletterCreateOrUpdateForm__title">
                                        <Field
                                            variant="NewsletterCreateOrUpdateForm"
                                            modifier="title"
                                            name="title"
                                            label="Edição"
                                            labelHidden
                                            placeholder="Edição"
                                            style={{
                                                width: `${values.title ? values.title.length : 0}ch`,
                                            }}
                                            errors={errors.title}
                                            touched={touched.title}
                                        />
                                        <strong>&nbsp;&nbsp;|&nbsp;&nbsp;</strong>
                                        <Field
                                            variant="NewsletterCreateOrUpdateForm"
                                            modifier="date"
                                            name="date"
                                            label="Data"
                                            labelHidden
                                            placeholder="Data"
                                            style={{
                                                width: `${values.date ? values.date.length : 0}ch`,
                                            }}
                                            errors={errors.date}
                                            touched={touched.date}
                                        />
                                    </div>
                                    <h2 className="NewsletterCreateOrUpdateForm__subtitle">
                                        <span>
                                            <strong>Translacional</strong>
                                        </span>
                                        <small>
                                            <strong>Saúde, Ciência, Tecnologia &amp; Inovação</strong>
                                        </small>
                                    </h2>
                                    <div className="NewsletterCreateOrUpdateForm__bar" />
                                    <p className="NewsletterCreateOrUpdateForm__overture">
                                        <strong>A newsletter do CDTS/Fiocruz</strong>
                                    </p>
                                    <article
                                        className="NewsletterSectionField NewsletterSectionField--highlighted"
                                        style={{ color: '#888888' }}
                                    >
                                        <h3 className="NewsletterSectionField__title">
                                            <span>
                                                <strong>Resumo da edição</strong>
                                            </span>
                                        </h3>
                                        <Field
                                            variant="NewsletterCreateOrUpdateForm"
                                            modifier="summary"
                                            name="summary"
                                            label="Resumo"
                                            labelHidden
                                            as={TextareaAutosize}
                                            placeholder="Texto do resumo"
                                            errors={errors.summary}
                                            touched={touched.summary}
                                        />
                                    </article>
                                    <FieldArray
                                        name="sections"
                                        render={(arrayHelpers) => (
                                            <NewsletterSectionsField
                                                parentName="sections"
                                                values={values.sections}
                                                errors={errors.sections}
                                                touched={touched.sections}
                                                setFieldValue={setFieldValue}
                                                arrayHelpers={arrayHelpers}
                                                isLoading={isLoading}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <hr className="is-invisible" />

                            <section className="contained">
                                <h2 className="subtitle">Visualização em lista</h2>
                                <div className="box">
                                    <div className="cdts-default-list-item">
                                        <div className="image-buttons cdts-default-list-item__image">
                                            {values.thumbnail && values.thumbnail.id ? (
                                                <>
                                                    <img
                                                        src={`${process.env.REACT_APP_BASE_WEB_URL}${values.thumbnail.url}`}
                                                        alt=""
                                                        width={800}
                                                        height={800}
                                                    />
                                                    <button
                                                        type="button"
                                                        className={`image-buttons__double button button-reset is-small ${
                                                            isLoading ? 'is-loading' : ''
                                                        }`}
                                                        disabled={isLoading}
                                                        onClick={() => this.openThumbnailField(setFieldValue)}
                                                    >
                                                        Trocar imagem
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={`image-buttons__double button button-reset is-small ${
                                                            isLoading ? 'is-loading' : ''
                                                        }`}
                                                        disabled={isLoading}
                                                        onClick={() => {
                                                            setFieldValue('thumbnail.id', '');
                                                            setFieldValue('thumbnail.url', '');
                                                        }}
                                                    >
                                                        Remover imagem
                                                    </button>
                                                </>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className={`image-buttons__single image-buttons__single--with-placeholder button is-small ${
                                                        isLoading ? 'is-loading' : ''
                                                    }`}
                                                    disabled={isLoading}
                                                    onClick={() => this.openThumbnailField(setFieldValue)}
                                                >
                                                    <img src={placeholder_1600x1600} alt="" width={800} height={800} />
                                                    <span>Adicionar imagem</span>
                                                </button>
                                            )}
                                        </div>

                                        <div className="cdts-default-list-item__content">
                                            <h3 className="cdts-default-list-item__title">
                                                {values.subject || 'Título'}
                                            </h3>
                                            {values.person_name && values.person_job_title && (
                                                <p className="cdts-default-list-item__person">
                                                    <strong>{values.person_name}</strong>
                                                    <span>{values.person_job_title}</span>
                                                </p>
                                            )}
                                            <p className="cdts-default-list-item__subtitle">
                                                {values.summary || 'Subtítulo'}
                                            </p>
                                        </div>

                                        <div className="cdts-default-list-item__aside">
                                            <p className="cdts-default-list-item__published-at">
                                                <span>Publicado em</span>
                                                <br />
                                                <span>{getDate(values.published_at)}</span>
                                            </p>
                                            <ShareIconsList className="cdts-link-hub cdts-default-list-item__link-hub" />
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <hr className="is-invisible" />

                            <PublicationFields errors={errors} touched={touched} />
                        </fieldset>

                        {showRequest === 'revision' && revision && (
                            <>
                                <hr className="invisible" />
                                <RevisionData revision={revision} />
                            </>
                        )}

                        {showRequest !== 'revision' && (
                            <>
                                <hr className="invisible" />
                                <div className="buttons actions actions--sticky">
                                    <button
                                        type="submit"
                                        className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                                        disabled={isLoading}
                                    >
                                        {onSubmitRequest === 'restore' ? 'Restaurar' : id ? 'Salvar' : 'Adicionar'}
                                    </button>
                                    {onSubmitRequest !== 'restore' && id && (
                                        <button
                                            type="button"
                                            className={`button is-primary is-light is-medium ${
                                                isLoading ? 'is-loading' : ''
                                            }`}
                                            disabled={isLoading}
                                            onClick={() => this.sendNewsletter(values)}
                                        >
                                            Enviar
                                        </button>
                                    )}
                                </div>
                            </>
                        )}

                        <div className="buttons is-justified-center">
                            {showRequest !== 'revision' &&
                                onSubmitRequest !== 'restore' &&
                                newsletter.slug &&
                                Boolean(newsletter.published) && (
                                    <a
                                        className={`button is-light is-info ${isLoading ? 'is-loading' : ''}`}
                                        href={`${process.env.REACT_APP_BASE_WEB_URL}/boletins/${newsletter.slug}`}
                                    >
                                        Visualizar no site
                                    </a>
                                )}
                            {hasStoredValues && (
                                <button
                                    type="button"
                                    className={`button is-light is-warning ${isLoading ? 'is-loading' : ''}`}
                                    disabled={isLoading}
                                    onClick={this.removeStoredValues}
                                >
                                    Limpar dados armazenados
                                </button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withDialogs(withNotifications(withRouter(NewsletterCreatePage)));
