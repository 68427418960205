import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextareaAutosize from 'react-autosize-textarea';

import { getImageOrIcon, formatBytes, audioMimeTypes, pdfMimeTypes, videoMimeTypes } from '../../services/media';
import { show, showRemoved, showRevision, update, restore } from '../../requests/image';
import withNotifications from '../../contexts/withNotifications';
import { getDatetime } from '../../services/datetime';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';
import RevisionData from '../../components/Forms/RevisionData';

export const initialValues = {
    name: '',
};

const validationSchema = Yup.object({
    name: Yup.string().required('Campo obrigatório'),
});

class ImageUpdateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            image: initialValues,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.getImagePreview = this.getImagePreview.bind(this);
    }

    componentDidMount() {
        const { id, showRequest, onSubmitRequest } = this.props;

        if (showRequest === 'revision') {
            showRevision(this, id);
        } else if (onSubmitRequest === 'restore') {
            showRemoved(this, id);
        } else {
            show(this, id);
        }
    }

    componentDidUpdate(prevProps) {
        const { id, showRequest } = this.props;

        if (id !== prevProps.id) {
            if (showRequest === 'revision') {
                showRevision(this, id);
            } else {
                show(this, id);
            }
        }
    }

    onSubmit(values) {
        const { id, onSubmitRequest, disabled } = this.props;

        if (!disabled) {
            if (onSubmitRequest === 'restore') {
                restore(this, id);
            } else {
                update(this, id, values);
            }
        }
    }

    getImagePreview(values) {
        const { mimeType, url, width, height } = values;

        switch (true) {
            case audioMimeTypes.includes(mimeType):
                return (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <audio
                        controls
                        src={`${process.env.REACT_APP_BASE_WEB_URL}${url}`}
                        className="ImageUpdateForm__audio"
                    />
                );
            case pdfMimeTypes.includes(mimeType):
                return (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <object
                        data={`${process.env.REACT_APP_BASE_WEB_URL}${url}`}
                        type="application/pdf"
                        width="100%"
                        height="360"
                    >
                        <img
                            src={getImageOrIcon(mimeType, url)}
                            alt=""
                            className="ImageUpdateForm__image"
                            width="150"
                            height="150"
                        />
                    </object>
                );
            case videoMimeTypes.includes(mimeType):
                return (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video
                        controls
                        src={`${process.env.REACT_APP_BASE_WEB_URL}${url}`}
                        className="ImageUpdateForm__video"
                    />
                );
            default:
                return (
                    <img
                        src={getImageOrIcon(mimeType, url)}
                        alt=""
                        className="ImageUpdateForm__image"
                        width={width ? parseInt(width / 2, 10) : 150}
                        height={height ? parseInt(height / 2, 10) : 150}
                    />
                );
        }
    }

    render() {
        const { showRequest, onSubmitRequest, withoutBox, smallSized, disabled } = this.props;
        const { isLoading, image, revision } = this.state;

        return (
            <Formik
                initialValues={image}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
            >
                {({ values, errors, touched }) => (
                    <Form>
                        <fieldset
                            className={`ImageUpdateForm ${!withoutBox ? 'box' : ''}`}
                            disabled={disabled || showRequest === 'revision' || onSubmitRequest === 'restore'}
                        >
                            <div className="has-text-centered">{this.getImagePreview(values)}</div>
                            <br />
                            <Field
                                name="name"
                                label="Nome"
                                as={TextareaAutosize}
                                errors={errors.name}
                                touched={touched.name}
                            />
                            <div className={!smallSized ? 'columns is-multiline' : 'mb-4'}>
                                <div className={!smallSized ? 'column is-half' : 'mb-4'}>
                                    <p>
                                        <strong>Extensão</strong>
                                    </p>
                                    <p>{values.extension}</p>
                                </div>
                                <div className={!smallSized ? 'column is-half' : 'mb-4'}>
                                    <p>
                                        <strong>Tamanho</strong>
                                    </p>
                                    <p>{formatBytes(values.size)}</p>
                                </div>
                                <div className={!smallSized ? 'column is-half' : 'mb-4'}>
                                    <p>
                                        <strong>Data e hora</strong>
                                    </p>
                                    <p>{getDatetime(values.datetime)}</p>
                                </div>
                                {values.width && values.height && (
                                    <div className={!smallSized ? 'column is-half' : 'mb-4'}>
                                        <p>
                                            <strong>Dimensões</strong>
                                        </p>
                                        <p>{`${parseInt(values.width, 10)}x${parseInt(values.height, 10)} pixels`}</p>
                                    </div>
                                )}
                                <div className={!smallSized ? 'column is-half' : ''}>
                                    <p>
                                        <strong>Endereço na web</strong>
                                    </p>
                                    <p className="ImageUpdateForm__link">
                                        <a href={`${process.env.REACT_APP_BASE_WEB_URL}${image.url}`}>
                                            {`${process.env.REACT_APP_BASE_WEB_URL}${image.url}`}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </fieldset>

                        {showRequest === 'revision' && revision && (
                            <>
                                <hr className="invisible" />
                                <RevisionData revision={revision} />
                            </>
                        )}

                        {!disabled && showRequest !== 'revision' && (
                            <div className={!smallSized ? `actions actions--sticky` : ''}>
                                <button
                                    type="submit"
                                    className={`button ${!smallSized ? `is-primary is-medium` : 'is-fullwidth'} ${
                                        isLoading ? 'is-loading' : ''
                                    }`}
                                    disabled={isLoading}
                                >
                                    {onSubmitRequest === 'restore' ? 'Restaurar' : 'Editar'}
                                </button>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withNotifications(withRouter(ImageUpdateForm));
