import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import PostRevisionCollection from '../../collections/Post/PostRevisionCollection';

export default function PostRevisionIndexPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Histórico de notícia"
            tabs={[
                { linkTo: `/noticias/${id}`, text: 'Editar' },
                { linkTo: `/noticias/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <PostRevisionCollection id={id} request="listUserRevisions" linkToPrefix="noticias" />
        </DefaultLayout>
    );
}
