import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import OpinionCreateOrUpdateForm from '../../forms/Opinion/OpinionCreateOrUpdateForm';

export default function OpinionCreatePage() {
    return (
        <DefaultLayout title="Adicionar opinião do especialista" isFullWidth>
            <OpinionCreateOrUpdateForm />
        </DefaultLayout>
    );
}
