import React from 'react';

export default class BlockquoteElement extends React.Component {
    render() {
        const { text } = this.props;

        return <blockquote>{text}</blockquote>;
    }
}

export function getBlockquoteHtmlString(text) {
    return `<blockquote>${text.trim()}</blockquote>`;
}
