import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import InterviewCreateOrUpdateForm from '../../forms/Interview/InterviewCreateOrUpdateForm';

export default function InterviewRemovedPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Entrevista removida" isFullWidth>
            <InterviewCreateOrUpdateForm id={id} onSubmitRequest="restore" />
        </DefaultLayout>
    );
}
