import React from 'react';

import DefaultLayout from '../layouts/DefaultLayout';
import NewsletterProgressionChart from '../charts/NewsletterProgressionChart';

export default function HomePage() {
    return (
        <DefaultLayout title="Visão geral" withHiddenTitle>
            <h2 className="subtitle">Progressão dos boletins</h2>
            <div className="box">
                <NewsletterProgressionChart />
            </div>
        </DefaultLayout>
    );
}
