import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import TagCreateOrUpdateForm from '../../forms/Tag/TagCreateOrUpdateForm';

export default function TagRevisionPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Versão de tag">
            <TagCreateOrUpdateForm id={id} showRequest="revision" />
        </DefaultLayout>
    );
}
