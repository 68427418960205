import React from 'react';
import { Link } from 'react-router-dom';

import { paginate, paginateRemoved } from '../../requests/newsletter';
import withNotifications from '../../contexts/withNotifications';
import PaginatedTable from '../../components/Collections/PaginatedTable';

class NewsletterCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            links: null,
            meta: null,
        };
        this.paginate = this.paginate.bind(this);
    }

    paginate(page) {
        const { paginateRequest } = this.props;

        if (paginateRequest === 'paginateRemoved') {
            paginateRemoved(this, page);
        } else {
            paginate(this, page);
        }
    }

    render() {
        const { paginateRequest } = this.props;
        const { data, links, meta } = this.state;

        return (
            <div className="box">
                <PaginatedTable
                    listFn={this.paginate}
                    data={data}
                    links={links}
                    meta={meta}
                    row={(data) => (
                        <tr key={data.id}>
                            <td>
                                <Link
                                    to={`/boletins${paginateRequest === 'paginateRemoved' ? '/removidos' : ''}/${
                                        data.id
                                    }`}
                                >
                                    <div className="collection-image-item">
                                        {data.thumbnail && (
                                            <img
                                                className="collection-image-item__image"
                                                src={`${process.env.REACT_APP_BASE_WEB_URL}${data.thumbnail.url}`}
                                                alt=""
                                            />
                                        )}
                                        <div>
                                            <p className="collection-image-item__title">{data.subject}</p>
                                            <p>
                                                <small className="collection-image-item__text">{`${data.summary.substring(
                                                    0,
                                                    200
                                                )}...`}</small>
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </td>
                        </tr>
                    )}
                />
            </div>
        );
    }
}

export default withNotifications(NewsletterCollection);
