import React from 'react';

import { editBlock, deleteBlock } from '../../services/editor';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import ImagePrompter from './ImagePrompter';
import ImageElement from './ImageElement';

class ImageBlock extends React.Component {
    constructor(props) {
        super(props);
        this.confirmImage = this.confirmImage.bind(this);
        this.removeImage = this.removeImage.bind(this);
    }

    confirmImage(image, caption, width, height, position, orientation) {
        editBlock(this, { image, caption, width, height, position, orientation });
        this.props.dialogs.remove(dialogsKeys.editorBlock);
    }

    removeImage(event) {
        event.stopPropagation();
        deleteBlock(this);
    }

    render() {
        const { block, contentState } = this.props;
        const { editorState, setEditorState } = this.props.blockProps;
        const { image, caption, width, height, position, orientation } = contentState
            .getEntity(block.getEntityAt(0))
            .getData();

        return (
            <div className="editor-block">
                <ImageElement image={image} caption={caption} width={width} height={height} />
                <ImagePrompter
                    editorState={editorState}
                    setEditorState={setEditorState}
                    image={image}
                    caption={caption}
                    width={width}
                    height={height}
                    position={position}
                    orientation={orientation}
                    confirmImage={this.confirmImage}
                >
                    Editar imagem
                </ImagePrompter>
                <button type="button" className="button" onClick={this.removeImage}>
                    Remover imagem
                </button>
            </div>
        );
    }
}

export default withDialogs(ImageBlock);
