import React from 'react';

import ImageSelectForm from '../../forms/Image/ImageSelectForm';
import ImageCreateForm from '../../forms/Image/ImageCreateForm';

export default class ImageField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 'library',
        };
    }

    render() {
        const { mimeType, width, height, aspectRatio, setImageId, setImageUrl, selectedId, selectedUrl } = this.props;
        const { tab } = this.state;

        return (
            <>
                <nav>
                    <div className="tabs">
                        <ul>
                            <li>
                                <button
                                    type="button"
                                    className={`ImageField__tab ${tab === 'upload' ? 'is-active' : ''}`}
                                    onClick={() => this.setState({ tab: 'upload' })}
                                >
                                    Adicionar imagem
                                </button>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    className={`ImageField__tab ${tab === 'library' ? 'is-active' : ''}`}
                                    onClick={() => this.setState({ tab: 'library' })}
                                >
                                    Selecionar imagem
                                </button>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="ImageField__container">
                    {tab === 'library' ? (
                        <ImageSelectForm
                            mimeType={mimeType}
                            width={width}
                            height={height}
                            setImageId={setImageId}
                            setImageUrl={setImageUrl}
                            selectedId={selectedId}
                            selectedUrl={selectedUrl}
                        />
                    ) : (
                        <ImageCreateForm
                            mimeType={mimeType}
                            width={width}
                            height={height}
                            aspectRatio={aspectRatio}
                            setImageId={setImageId}
                            setImageUrl={setImageUrl}
                            withoutBox
                        />
                    )}
                </div>
            </>
        );
    }
}
