import axios from 'axios';

import { getToken, setToken } from './authentication';
import { authReboot } from './error-handler';

/**
 * Gera uma instância do Axios com a URL base da API.
 */
const api = axios.create({ baseURL: process.env.REACT_APP_BASE_API_URL });

/**
 * Adiciona o JWT às requisições.
 */
api.interceptors.request.use((config) => {
    const token = getToken();

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

let authTokenRequest;

/**
 * Reseta o token de autenticação.
 */
function resetAuthTokenRequest() {
    authTokenRequest = null;
}

/**
 * Retorna a promessa do token de autenticação.
 *
 * @returns Promise
 */
function getAuthToken() {
    if (!authTokenRequest) {
        authTokenRequest = api.post('/renovar-token');
        authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
    }

    return authTokenRequest;
}

/**
 * Tenta renovar o token em problema de autenticação, exceto na página de
 * login. Caso não consiga, reinicia o app.
 */
api.interceptors.response.use(undefined, (error) => {
    if (
        error.response &&
        error.config &&
        error.response.status === 401 &&
        error.config.url !== '/entrar' &&
        !error.config.__isRetryRequest
    ) {
        return getAuthToken()
            .then((response) => {
                const token = response.data.token;
                setToken(token);
                error.config.headers.Authorization = `Bearer ${token}`;
                error.config.__isRetryRequest = true;

                return axios.request(error.config);
            })
            .catch(() => {
                authReboot();
            });
    }

    return Promise.reject(error);
});

export default api;
