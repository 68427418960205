import React from 'react';

export default class SoundCloudElement extends React.Component {
    render() {
        const { url, caption } = this.props;

        return (
            <>
                <iframe
                    title="Áudio do SoundCloud incorporado"
                    width="100%"
                    height="300"
                    scrolling="no"
                    frameBorder="no"
                    src={url}
                />
                {caption && <figcaption>{caption}</figcaption>}
            </>
        );
    }
}

export function getSoundCloudHtmlString(url, caption) {
    return `
    <iframe
      title="Áudio do SoundCloud incorporado"
      width="100%"
      height="300"
      scrolling="no"
      frameBorder="no"
      src="${url}"
      loading="lazy"
    /></iframe>
    ${caption && `<figcaption>${caption.trim()}</figcaption>`}
  `;
}
