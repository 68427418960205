import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import TagRevisionCollection from '../../collections/Tag/TagRevisionCollection';

export default function TagRevisionIndexPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Histórico de tag"
            tabs={[
                { linkTo: `/tags/${id}`, text: 'Editar' },
                { linkTo: `/tags/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <TagRevisionCollection id={id} request="listUserRevisions" linkToPrefix="tags" />
        </DefaultLayout>
    );
}
