import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ClippingSourceRevisionCollection from '../../collections/ClippingSource/ClippingSourceRevisionCollection';

export default function ClippingSourceRevisionIndexPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Histórico de fonte de mídia"
            tabs={[
                { linkTo: `/fontes-de-midia/${id}`, text: 'Editar' },
                { linkTo: `/fontes-de-midia/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <ClippingSourceRevisionCollection
                id={id}
                request="listUserRevisions"
                linkToPrefix="opiniao-do-especialista"
            />
        </DefaultLayout>
    );
}
