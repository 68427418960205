import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import TagCreateOrUpdateForm from '../../forms/Tag/TagCreateOrUpdateForm';
import TagRemoveForm from '../../forms/Tag/TagRemoveForm';

export default function TagUpdatePage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Editar tag"
            tabs={[
                { linkTo: `/tags/${id}`, text: 'Editar' },
                { linkTo: `/tags/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <TagCreateOrUpdateForm id={id} />
            <br />
            <TagRemoveForm id={id} />
        </DefaultLayout>
    );
}
