import React from 'react';
import { withRouter } from 'react-router-dom';

import { remove, removePermanently } from '../../requests/newsletter-section-template';
import authorize from '../../requests/authorization';
import withNotifications from '../../contexts/withNotifications';
import RemoveForm from '../RemoveForm';

class NewsletterSectionTemplateRemoveForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isAuthorized: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        authorize(this, 'isAdmin');
    }

    onSubmit(event) {
        event.preventDefault();
        const { id, onSubmitRequest } = this.props;

        if (onSubmitRequest === 'removePermanently') {
            removePermanently(this, id);
        } else {
            remove(this, id);
        }
    }

    render() {
        const { onSubmitRequest } = this.props;
        const { isLoading, isAuthorized } = this.state;

        if (onSubmitRequest !== 'removePermanently' || (onSubmitRequest === 'removePermanently' && isAuthorized)) {
            return (
                <RemoveForm isLoading={isLoading} onSubmit={this.onSubmit}>
                    {onSubmitRequest === 'removePermanently' ? 'Remover permanentemente' : 'Remover'}
                </RemoveForm>
            );
        }

        return null;
    }
}

export default withNotifications(withRouter(NewsletterSectionTemplateRemoveForm));
