import api from '../services/api';
import handleError from '../services/error-handler';

/**
 * Faz uma requisição de listagem e adiciona a resposta a um componente.
 *
 * @param {string} urlPrefix
 * @param {React.Component} component
 * @param {int} page
 * @param {Object|null} options
 */
export function paginate(urlPrefix, component, page = 1, options = null) {
    const { notifications } = component.props;

    component.setState({ isLoading: true });

    const urlParams = new URLSearchParams();

    urlParams.append('page', page);

    if (options && options.urlParams) {
        Object.entries(options.urlParams).forEach((item) => {
            const [key, value] = item;

            if (value) {
                value && urlParams.append(key, value);
            }
        });
    }

    api.get(`/${urlPrefix}?${urlParams}`)
        .then((response) => {
            component.setState({
                data: response.data.data,
                links: response.data.links,
                meta: response.data.meta,
            });
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
        })
        .finally(() => {
            component.setState({ isLoading: false });
        });
}

/**
 * Faz uma requisição de criação.
 *
 * @param {string} urlPrefix
 * @param {React.Component} component
 * @param {Object} values
 * @param {Object|null} options
 */
export function create(urlPrefix, component, values, options = null) {
    const { notifications, history } = component.props;

    component.setState({ isLoading: true });

    if (options && options.transformData) {
        values = options.transformData(values);
    }

    api.post(`/${urlPrefix}/adicionar`, values, options && options.requestConfig)
        .then((response) => {
            if (options && options.storageKey) {
                localStorage.removeItem(options.storageKey);
            }

            notifications.add(response.data.message, 'success');

            if (options && options.successCallbackFn) {
                component.setState({ isLoading: false });
                options.successCallbackFn(response);
            } else {
                history.push(`/${urlPrefix}`);
            }
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            component.setState({ isLoading: false });
        });
}

/**
 * Faz uma requisição de exibição e adiciona a resposta a um componente.
 *
 * @param {string} urlPrefix
 * @param {string} stateKey
 * @param {React.Component} component
 * @param {int} id
 * @param {Object|null} options
 */
export function show(urlPrefix, stateKey, component, id, options = null) {
    const { notifications } = component.props;

    component.setState({ isLoading: true });

    api.get(`/${urlPrefix}/${id}`)
        .then((response) => {
            let { data } = response.data;

            if (options && options.transformData) {
                data = options.transformData(data);
            }

            component.setState({ [stateKey]: data }, options && options.afterStateUpdateFn);
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
        })
        .finally(() => {
            component.setState({ isLoading: false });
        });
}

/**
 * Faz uma requisição de atualização e adiciona a resposta a um componente.
 *
 * @param {string} urlPrefix
 * @param {React.Component} component
 * @param {int} id
 * @param {array} values
 * @param {Object|null} options
 */
export function update(urlPrefix, component, id, values, options = null) {
    const { notifications, history } = component.props;

    component.setState({ isLoading: true });

    if (options && options.transformData) {
        values = options.transformData(values);
    }

    api.put(`/${urlPrefix}/${id}/editar`, values)
        .then((response) => {
            if (options && options.storageKey) {
                localStorage.removeItem(`${options.storageKey}_${id}`);
            }

            notifications.add(response.data.message, 'success');

            if (options && options.successCallbackFn) {
                component.setState({ isLoading: false });
                options.successCallbackFn(response);
            } else {
                history.push(`/${urlPrefix}`);
            }
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            component.setState({ isLoading: false });
        });
}

/**
 * Faz uma requisição de remoção.
 *
 * @param {string} urlPrefix
 * @param {React.Component} component
 * @param {int} id
 * @param {Object|null} options
 */
export function remove(urlPrefix, component, id, options = null) {
    const { notifications, history } = component.props;

    component.setState({ isLoading: true });

    api.delete(`/${urlPrefix}/${id}/remover`)
        .then((response) => {
            notifications.add(response.data.message, 'success');

            if (options && options.successCallbackFn) {
                component.setState({ isLoading: false });
                options.successCallbackFn(response);
            } else {
                history.push(`/${urlPrefix}`);
            }
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            component.setState({ isLoading: false });
        });
}
