import * as Yup from 'yup';

import * as crud from './crud';
import * as softDeletes from './soft-deletes';
import * as revisionable from './revisionable';

const URL_PREFIX = 'imagens';
const STATE_KEY = 'image';

export const updateValidation = Yup.object({
    name: Yup.string().required('Campo obrigatório'),
});

/**
 * @export
 * @param {*} component
 * @param {*} page
 * @param {*} [options=null]
 */
export function paginate(component, page, options = null) {
    crud.paginate(URL_PREFIX, component, page, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} file
 * @param {*} [options=null]
 */
export function create(component, file, options = null) {
    const formData = new FormData();
    formData.set('file', file);

    options.requestConfig = {
        'Content-Type': 'multipart/form-data',
    };

    crud.create(URL_PREFIX, component, formData, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function show(component, id, options = null) {
    crud.show(URL_PREFIX, STATE_KEY, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} values
 * @param {*} [options=null]
 */
export function update(component, id, values, options = null) {
    crud.update(URL_PREFIX, component, id, values, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function remove(component, id, options = null) {
    crud.remove(URL_PREFIX, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} page
 */
export function paginateRemoved(component, page) {
    softDeletes.paginateRemoved(URL_PREFIX, component, page);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function showRemoved(component, id, options = null) {
    softDeletes.showRemoved(URL_PREFIX, STATE_KEY, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function restore(component, id) {
    softDeletes.restore(URL_PREFIX, component, id);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function removePermanently(component, id) {
    softDeletes.removePermanently(URL_PREFIX, component, id);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} page
 */
export function listRevisions(component, id, page) {
    revisionable.listRevisions(URL_PREFIX, component, id, page);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function showRevision(component, id, options = null) {
    revisionable.showRevision(URL_PREFIX, STATE_KEY, component, id, options);
}
