import React from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextareaAutosize from 'react-autosize-textarea';

import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import withNotifications from '../../contexts/withNotifications';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';
import Input from '../../components/Forms/Input';
import SoundCloudElement from './SoundCloudElement';

export const validationSchema = Yup.object({
    url: Yup.string().required('Campo obrigatório').url('O campo deve ser uma URL válida'),
});

class SoundCloudForm extends React.Component {
    constructor(props) {
        super(props);
        this.generateEmbed = this.generateEmbed.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    generateEmbed(value, setFieldValue) {
        if (value) {
            const { notifications } = this.props;
            const infoNotification = notifications.add('Gerando o código de incorporação...');

            axios
                .get(`http://soundcloud.com/oembed?format=js&url=${encodeURIComponent(value)}&iframe=true`)
                .then((response) => {
                    const { data } = response;
                    const { html } = JSON.parse(data.slice(1, -2));
                    const element = document.createElement('div');
                    element.innerHTML = html;
                    setFieldValue('url', element.querySelector('iframe').getAttribute('src'));
                    notifications.remove(infoNotification);
                })
                .catch(() => {
                    notifications.remove(infoNotification);
                    notifications.add('Erro ao gerar código de incorporação', 'danger');
                    setFieldValue('url', '');
                });
        }
    }

    onSubmit(values) {
        const { confirmSoundCloud, dialogs, atomicParams } = this.props;
        const { position } = atomicParams;

        confirmSoundCloud(values.url, values.caption, position);
        dialogs.remove(dialogsKeys.editorBlock);
    }

    render() {
        const { url, caption } = this.props;

        const initialValues = {
            url: url || '',
            caption: caption || '',
        };

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit}>
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        {values.url && (
                            <>
                                <figure className="cdts-atomic cdts-atomic--soundcloud">
                                    <SoundCloudElement url={values.url} caption={values.caption} />
                                </figure>
                            </>
                        )}
                        <Input
                            name="url-controller"
                            label="Link do SoundCloud"
                            type="url"
                            defaultValue={url}
                            onChange={(e) => this.generateEmbed(e.target.value, setFieldValue)}
                            errors={errors.url}
                            touched
                        />
                        <Field
                            name="caption"
                            label="Legenda"
                            as={TextareaAutosize}
                            errors={errors.caption}
                            touched={touched.caption}
                        />
                        <div className="actions actions--sticky">
                            <button type="submit" className="button is-primary is-medium">
                                Confirmar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withNotifications(withDialogs(SoundCloudForm));
