import React from 'react';

export default function CopyLinkIcon() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.487 29.537">
            <path d="m19.293 11.923a2.64 2.64 0 0 0-0.15-0.55v-0.07a1.88 1.88 0 0 0-0.4-0.53 7.09 7.09 0 0 0-10 0l-6.68 6.7a7.09 7.09 0 0 0 0 10 7.09 7.09 0 0 0 10 0l3.93-3.92a9.86 9.86 0 0 1-3.88-1.58l-2.77 2.8a3.29 3.29 0 0 1-4.55 0 3.23 3.23 0 0 1 0-4.55l3.65-3.67 3-3a3.21 3.21 0 0 1 1.06-0.7 3.28 3.28 0 0 1 0.61-0.18 3.22 3.22 0 0 1 2.88 0.88 1.92 1.92 0 0 0 2.73 0 1.84 1.84 0 0 0 0.51-0.92 1.9 1.9 0 0 0 0-0.58z" />
            <path d="m29.423 2.0633a7.09 7.09 0 0 0-10 0l-3.95 3.93a10 10 0 0 1 3.89 1.58l2.78-2.8a3.3 3.3 0 0 1 4.6 0 3.24 3.24 0 0 1 0.94 2.28 3.2 3.2 0 0 1-0.95 2.27l-1.22 1.22-5.45 5.44a3.29 3.29 0 0 1-4.55 0 1.9658 1.9658 0 1 0-2.77 2.79 7.06 7.06 0 0 0 10 0l5.45-5.44 1.23-1.23a7.09 7.09 0 0 0 0-10z" />
        </svg>
    );
}
