import React from 'react';
import { Field as FormikField } from 'formik';

import Select from './Select';

export default class SelectField extends React.Component {
    render() {
        const { name, label, labelHidden, errors, touched, children, isLoading, withAddOn } = this.props;

        return (
            <FormikField id={name} name={name}>
                {({ field }) => (
                    <Select
                        label={label}
                        labelHidden={labelHidden}
                        name={field.name}
                        value={field.value}
                        errors={errors}
                        touched={touched}
                        onChange={field.onChange}
                        isLoading={isLoading}
                        withAddOn={withAddOn}
                    >
                        {children}
                    </Select>
                )}
            </FormikField>
        );
    }
}
