import React from 'react';

import Dialog from '../components/Dialog';

export const DialogsContext = React.createContext();

export default class DialogsProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogs: [],
        };
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);
        this.removeAll = this.removeAll.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { dialogs } = this.state;

        if (prevState.dialogs !== dialogs && dialogs.length) {
            document.documentElement.classList.add('is-clipped');
        } else {
            document.documentElement.classList.remove('is-clipped');
        }
    }

    add(key, title, content, isFullWidth) {
        this.setState((prevState) => ({
            dialogs: [
                ...prevState.dialogs,
                {
                    key,
                    title,
                    content,
                    isFullWidth,
                },
            ],
        }));
    }

    remove(key) {
        const { dialogs } = this.state;

        this.setState({
            dialogs: dialogs.filter((dialog) => dialog.key !== key),
        });
    }

    removeAll() {
        this.setState({ dialogs: [] });
    }

    render() {
        const { children } = this.props;
        const { dialogs } = this.state;

        return (
            <DialogsContext.Provider
                value={{
                    add: this.add,
                    remove: this.remove,
                    removeAll: this.removeAll,
                }}
            >
                {children}
                {Boolean(dialogs.length) && (
                    <div className="DialogsProvider">
                        {dialogs.map(({ key, title, content, isFullWidth }) => (
                            <Dialog
                                key={key}
                                isFullWidth={isFullWidth}
                                title={title}
                                content={content}
                                close={() => this.remove(key)}
                            />
                        ))}
                    </div>
                )}
            </DialogsContext.Provider>
        );
    }
}
