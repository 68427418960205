import React from 'react';

import { rebooted } from '../services/error-handler';
import withNotifications from '../contexts/withNotifications';

class UnauthenticatedLayout extends React.Component {
    componentDidMount() {
        rebooted(this);
    }

    render() {
        const { title, children } = this.props;

        return (
            <div className="UnauthenticatedLayout">
                <div className="UnauthenticatedLayout__top-bar" />
                <div className="UnauthenticatedLayout__container">
                    <div className="UnauthenticatedLayout__title">
                        <img
                            className="UnauthenticatedLayout__image"
                            src={`${process.env.PUBLIC_URL}/logo192.png`}
                            alt=""
                        />
                        <h1 className="title">{title}</h1>
                    </div>
                    {children}
                </div>
            </div>
        );
    }
}

export default withNotifications(UnauthenticatedLayout);
