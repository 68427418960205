import api from '../services/api';
import handleError from '../services/error-handler';
import * as crud from './crud';
import * as softDeletes from './soft-deletes';
import * as revisionable from './revisionable';

const URL_PREFIX = 'destinatarios-de-boletim';
const STATE_KEY = 'newsletterRecipient';

/**
 * Faz a requisição de pedido de inscrição no boletim.
 *
 * @export
 * @param {*} component
 * @param {*} values
 * @param {*} [options=null]
 */
export function requestCreate(component, values, options = null) {
    const { notifications, history } = component.props;

    component.setState({ isLoading: true });

    api.post(`/${URL_PREFIX}/solicitar-inscricao`, values)
        .then((response) => {
            if (options && options.unauthenticated) {
                history.push('/inscricao-solicitada', { email: values.email });
            } else {
                notifications.add(response.data.message, 'success');
                history.push(`/${URL_PREFIX}`);
            }
        })
        .catch((error) => {
            notifications.add(handleError(error), 'danger');
            component.setState({ isLoading: false });
        });
}

/**
 * Faz a requisição de inscrição no boletim.
 *
 * @export
 * @param {*} component
 * @param {*} url
 */
export function subscribe(component, url) {
    const { notifications } = component.props;

    api.post(url)
        .then(() => {
            component.setState({ message: 'Você foi inscrito(a) no boletim com sucesso!' });
        })
        .catch((error) => {
            component.setState({
                message: 'Houve um erro na inscrição no boletim, por favor tente novamente.',
            });
            notifications.add(handleError(error), 'danger');
        });
}

/**
 * Faz a requisição de desinscrição no boletim.
 *
 * @export
 * @param {*} component
 * @param {*} url
 */
export function unsubscribe(component, url) {
    const { notifications } = component.props;

    api.put(url)
        .then(() => {
            component.setState({ message: 'Você foi desinscrito(a) do recebimento do nosso boletim com sucesso.' });
        })
        .catch((error) => {
            component.setState({
                message:
                    'Ocorreu um erro ao desinscrever você do recebimento do nosso boletim, por favor tente novamente.',
            });

            notifications.add(handleError(error), 'danger');
        });
}

/**
 * @export
 * @param {*} component
 * @param {*} page
 */
export function paginate(component, page, options = null) {
    crud.paginate(URL_PREFIX, component, page, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function show(component, id, options = null) {
    crud.show(URL_PREFIX, STATE_KEY, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function remove(component, id, options = null) {
    crud.remove(URL_PREFIX, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} page
 */
export function paginateRemoved(component, page) {
    softDeletes.paginateRemoved(URL_PREFIX, component, page);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function showRemoved(component, id, options = null) {
    softDeletes.showRemoved(URL_PREFIX, STATE_KEY, component, id, options);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function restore(component, id) {
    softDeletes.restore(URL_PREFIX, component, id);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 */
export function removePermanently(component, id) {
    softDeletes.removePermanently(URL_PREFIX, component, id);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} page
 */
export function listRevisions(component, id, page) {
    revisionable.listRevisions(URL_PREFIX, component, id, page);
}

/**
 * @export
 * @param {*} component
 * @param {*} id
 * @param {*} [options=null]
 */
export function showRevision(component, id, options = null) {
    revisionable.showRevision(URL_PREFIX, STATE_KEY, component, id, options);
}
