import React from 'react';
import { Link } from 'react-router-dom';

import { paginate, paginateRemoved } from '../../requests/newsletter-section-template';
import withNotifications from '../../contexts/withNotifications';
import Pagination from '../../components/Pagination';
import Message from '../../components/Message';
import NewsletterSectionTemplateCollectionItem from './NewsletterSectionTemplateCollectionItem';
import Loader from '../../components/Loader';

class NewsletterSectionTemplateCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            links: null,
            meta: null,
            isLoading: false,
        };
        this.paginate = this.paginate.bind(this);
    }

    componentDidMount() {
        this.paginate();
    }

    paginate(page = 1) {
        const { paginateRequest } = this.props;

        if (paginateRequest === 'paginateRemoved') {
            paginateRemoved(this, page, {
                urlParams: { itemsPerPage: 6 },
            });
        } else {
            paginate(this, page, {
                urlParams: { itemsPerPage: 6 },
            });
        }
    }

    render() {
        const { paginateRequest, selectFn, withoutBox } = this.props;
        const { data, links, meta, isLoading } = this.state;

        return (
            <div className={`NewsletterSectionTemplateCollection ${!withoutBox ? 'box' : ''}`}>
                {data ? (
                    Boolean(data.length) ? (
                        <div className="NewsletterSectionTemplateCollection__container">
                            <div className="columns is-multiline">
                                {data.map((template) => (
                                    <div key={template.id} className="column is-one-third">
                                        <div className="card">
                                            {selectFn ? (
                                                <button
                                                    type="button"
                                                    className={`button-reset ${isLoading ? 'is-loading' : ''}`}
                                                    disabled={isLoading}
                                                    onClick={() => selectFn(template)}
                                                >
                                                    <NewsletterSectionTemplateCollectionItem template={template} />
                                                </button>
                                            ) : (
                                                <Link
                                                    to={`/secoes-de-boletim${
                                                        paginateRequest === 'paginateRemoved' ? '/removidas' : ''
                                                    }/${template.id}`}
                                                >
                                                    <NewsletterSectionTemplateCollectionItem template={template} />
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {links && meta && <Pagination links={links} meta={meta} goToPage={this.paginate} />}
                        </div>
                    ) : (
                        <Message>Nenhum resultado</Message>
                    )
                ) : (
                    <Message>
                        <Loader />
                    </Message>
                )}
            </div>
        );
    }
}

export default withNotifications(NewsletterSectionTemplateCollection);
