import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

import { getDate } from '../../services/datetime';

moment.locale('pt-br');

export default function ClippingCollectionItem(props) {
    const { clipping } = props;

    return (
        <div className="ClippingCollectionItem cdts-clippings__list-item">
            <div className="cdts-card-list-item__container">
                {clipping.image && clipping.image.url ? (
                    <img
                        src={`${process.env.REACT_APP_BASE_WEB_URL}${clipping.image.url}`}
                        alt=""
                        width={800}
                        height={600}
                    />
                ) : (
                    <div className="ClippingCollectionItem__image-placeholder" />
                )}
                <div className="cdts-card-list-item__secondary-image">
                    {clipping.clipping_source && clipping.clipping_source.id ? (
                        <img
                            src={`${process.env.REACT_APP_BASE_WEB_URL}${clipping.clipping_source.image.url}`}
                            alt=""
                            width={480}
                            height={270}
                        />
                    ) : (
                        <div className="ClippingCollectionItem__clipping-source-placeholder" />
                    )}
                </div>
            </div>
            <div className="cdts-card-list-item__content">
                <h3 className="cdts-card-list-item__title">{clipping.title || 'Título'}</h3>
                <p className="cdts-card-list-item__published-at">
                    {`Publicada em ${getDate(clipping.published_at || moment().format('YYYY-MM-DD HH:mm:ss'))}`}
                </p>
            </div>
        </div>
    );
}
