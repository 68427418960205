import React from 'react';
import { Link } from 'react-router-dom';

import { getDate, getTime } from '../../services/datetime';

export default function RevisionFields(props) {
    const { revision } = props;

    return (
        <>
            <h2 className="subtitle">Informações da versão</h2>
            <div className="box">
                <p className="has-text-centered py-4">{revision.message}</p>
                <br />
                <div className="columns">
                    <div className="column">
                        <h2 className="label">Data e hora da versão</h2>
                        <p>{`${getDate(revision.created_at)} às ${getTime(revision.created_at)}`}</p>
                    </div>
                    <div className="column">
                        <h2 className="label">Autor(a)</h2>
                        <p>
                            {revision.user && revision.user.id ? (
                                <Link to={`/usuarios/${revision.user.id}`}>{revision.user.email}</Link>
                            ) : (
                                <span>{revision.user}</span>
                            )}
                        </p>
                    </div>
                    <div className="column">
                        <h2 className="label">Endereço IP</h2>
                        <p>{revision.user_ip}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
