import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ClippingSourceCollection from '../../collections/ClippingSource/ClippingSourceCollection';

export default function ClippingSourceRemovedIndexPage() {
    return (
        <DefaultLayout
            title="Fontes de mídia removidas"
            tabs={[
                { linkTo: '/fontes-de-midia', text: 'Ativas' },
                { linkTo: '/fontes-de-midia/removidas', text: 'Removidas' },
            ]}
        >
            <ClippingSourceCollection paginateRequest="paginateRemoved" />
        </DefaultLayout>
    );
}
