import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterSectionTemplateRevisionCollection from '../../collections/NewsletterSectionTemplate/NewsletterSectionTemplateRevisionCollection';

export default function NewsletterSectionTemplateRevisionsPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Histórico da seção de boletim"
            tabs={[
                { linkTo: `/secoes-de-boletim/${id}`, text: 'Editar' },
                { linkTo: `/secoes-de-boletim/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <NewsletterSectionTemplateRevisionCollection
                id={id}
                request="listUserRevisions"
                linkToPrefix="secoes-de-boletim"
            />
        </DefaultLayout>
    );
}
