import React from 'react';

export default function Checkbox(props) {
    const { label, name, value, onChange } = props;

    return (
        <div className="field">
            <div className="control">
                <label htmlFor={name} className="checkbox">
                    <input name={name} type="checkbox" checked={Boolean(value)} onChange={onChange} />
                    {label}
                </label>
            </div>
        </div>
    );
}
