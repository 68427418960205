import React from 'react';
import { Link } from 'react-router-dom';

import DefaultLayout from '../../layouts/DefaultLayout';
import UserCollection from '../../collections/User/UserCollection';

export default function UserListPage() {
    return (
        <DefaultLayout
            title="Usuários"
            tabs={[
                { linkTo: '/usuarios', text: 'Ativos' },
                { linkTo: '/usuarios/removidos', text: 'Removidos' },
            ]}
        >
            <UserCollection />
            <div className="actions actions--sticky">
                <Link to="/usuarios/adicionar" className="button is-primary is-medium">
                    Adicionar
                </Link>
            </div>
        </DefaultLayout>
    );
}
