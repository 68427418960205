import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import MemberCreateOrUpdateForm from '../../forms/Member/MemberCreateOrUpdateForm';

export default function MemberRevisionPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Versão de membro" isFullWidth>
            <MemberCreateOrUpdateForm id={id} showRequest="revision" />
        </DefaultLayout>
    );
}
