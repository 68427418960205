import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterSectionTemplateCollection from '../../collections/NewsletterSectionTemplate/NewsletterSectionTemplateCollection';

export default function NewsletterSectionTemplateListRemovedPage() {
    return (
        <DefaultLayout
            title="Seções de boletim removidas"
            tabs={[
                { linkTo: '/secoes-de-boletim', text: 'Ativas' },
                { linkTo: '/secoes-de-boletim/removidas', text: 'Removidas' },
            ]}
        >
            <NewsletterSectionTemplateCollection paginateRequest="paginateRemoved" />
        </DefaultLayout>
    );
}
