import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';

const validationSchema = Yup.object({
    href: Yup.string().required('Campo obrigatório').url('O campo deve ser uma URL válida'),
    target: Yup.string().required('Campo obrigatório').oneOf(['_self', '_blank']),
});

class LinkForm extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        const { confirmLink, dialogs } = this.props;

        confirmLink(values.href, values.target);
        dialogs.remove(dialogsKeys.editorBlock);
    }

    render() {
        const { href, target } = this.props;

        const initialValues = {
            href: href || '',
            target: target || '',
        };

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit}>
                {({ errors, touched }) => (
                    <Form>
                        <Field name="href" label="Endereço" type="url" errors={errors.href} touched={touched.href} />
                        <Field
                            as="select"
                            name="target"
                            label="Abrir em"
                            errors={errors.target}
                            touched={touched.target}
                        >
                            <option value="_self">Mesma aba</option>
                            <option value="_blank">Nova aba</option>
                        </Field>
                        <div className="actions actions--sticky">
                            <button type="submit" className="button is-primary is-medium">
                                Adicionar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withDialogs(LinkForm);
