import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { login } from '../../requests/authentication';
import withNotifications from '../../contexts/withNotifications';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';

const initialValues = {
    email: '',
    password: '',
};

const validationSchema = Yup.object({
    email: Yup.string().required('Campo obrigatório').email('Endereço de e-mail inválido'),
    password: Yup.string().required('Campo obrigatório'),
});

class AuthenticationLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        login(this, values);
    }

    render() {
        const { isLoading } = this.state;

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit}>
                {({ errors, touched }) => (
                    <Form>
                        <div className="box">
                            <Field
                                name="email"
                                label="E-mail"
                                type="email"
                                autoComplete="email"
                                errors={errors.email}
                                touched={touched.email}
                            />
                            <Field
                                name="password"
                                label="Senha"
                                type="password"
                                autoComplete="current-password"
                                errors={errors.password}
                                touched={touched.password}
                            />
                            <button
                                type="submit"
                                className={`button is-primary is-fullwidth ${isLoading ? 'is-loading' : ''}`}
                                disabled={isLoading}
                            >
                                Entrar
                            </button>
                            <hr />
                            <p className="has-text-centered">
                                <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
                            </p>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withNotifications(withRouter(AuthenticationLoginForm));
