import React from 'react';

import NewsletterSectionItemField from './NewsletterSectionItemField';

export default class NewsletterSectionItemsField extends React.PureComponent {
    constructor(props) {
        super(props);
        this.add = this.add.bind(this);
    }

    /**
     * Adiciona um item de seção de boletim.
     */
    add() {
        const { arrayHelpers } = this.props;

        arrayHelpers.push({
            image: {
                id: undefined,
                url: undefined,
            },
            title: '',
            text: '',
            link_text: '',
            link_href: '',
            is_cdts: false,
        });
    }

    /**
     * @inheritdoc
     */
    render() {
        const { parentName, values, errors, touched, setFieldValue, arrayHelpers, isLoading } = this.props;

        return (
            <>
                {values &&
                    values.map((item, i) => (
                        <NewsletterSectionItemField
                            key={i}
                            index={i}
                            name={`${parentName}.${i}`}
                            length={values.length}
                            values={item}
                            errors={errors ? errors[i] : {}}
                            touched={touched ? touched[i] : {}}
                            setFieldValue={setFieldValue}
                            arrayHelpers={arrayHelpers}
                            isLoading={isLoading}
                        />
                    ))}
                {touched && errors && typeof errors === 'string' && (
                    <p className="menu-label has-text-centered has-text-danger py-3">{errors}</p>
                )}
                <button
                    type="button"
                    className={`button is-fullwidth ${isLoading ? 'is-loading' : ''}`}
                    disabled={isLoading}
                    onClick={this.add}
                >
                    Adicionar item
                </button>
            </>
        );
    }
}
