import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import OpinionCreateOrUpdateForm from '../../forms/Opinion/OpinionCreateOrUpdateForm';

export default function OpinionRevisionPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Versão de opinião do especialista" isFullWidth>
            <OpinionCreateOrUpdateForm id={id} showRequest="revision" />
        </DefaultLayout>
    );
}
