import React from 'react';

export default class ImageElement extends React.Component {
    render() {
        const { image, width, height, caption } = this.props;

        return (
            <>
                <img
                    src={`${process.env.REACT_APP_BASE_WEB_URL}${image.url}`}
                    alt=""
                    width={parseInt(width / 2, 10)}
                    height={parseInt(height / 2, 10)}
                />
                {caption && <figcaption>{caption}</figcaption>}
            </>
        );
    }
}

export function getImageHtmlString(image, width, height, caption) {
    return `
    <img
      src="${image.url}"
      alt=""
      width="${parseInt(width / 2, 10)}"
      height="${parseInt(height / 2, 10)}"
      loading="lazy"
    />
    ${caption && `<figcaption>${caption.trim()}</figcaption>`}
  `;
}
