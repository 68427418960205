import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterRecipientRequestCreateForm from '../../forms/NewsletterRecipient/NewsletterRecipientRequestCreateForm';

export default function NewsletterRecipientRequestCreatePage() {
    return (
        <DefaultLayout title="Convidar destinatário de boletim">
            <NewsletterRecipientRequestCreateForm />
        </DefaultLayout>
    );
}
