import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import PostCreateOrUpdateForm from '../../forms/Post/PostCreateOrUpdateForm';

export default function PostCreatePage() {
    return (
        <DefaultLayout title="Adicionar notícia" isFullWidth>
            <PostCreateOrUpdateForm />
        </DefaultLayout>
    );
}
