import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import PostCollection from '../../collections/Post/PostCollection';

export default function PostRemovedIndexPage() {
    return (
        <DefaultLayout
            title="Notícias removidas"
            tabs={[
                { linkTo: '/noticias', text: 'Ativas' },
                { linkTo: '/noticias/removidas', text: 'Removidas' },
            ]}
        >
            <PostCollection paginateRequest="paginateRemoved" />
        </DefaultLayout>
    );
}
