import React from 'react';

export default function NewsletterSectionTemplateCollectionItem(props) {
    const { title, color, style, image } = props.template;

    return (
        <div className="NewsletterSectionTemplateCollectionItem__container">
            <div
                style={{ color }}
                className={`NewsletterSectionTemplateCollectionItem NewsletterSectionField${
                    style
                        ? ` NewsletterSectionTemplateCollectionItem--${style} NewsletterSectionField--${style}`
                        : ' NewsletterSectionTemplateCollectionItem--simple NewsletterSectionField--simple'
                }`}
            >
                <p className="NewsletterSectionField__title">
                    {image && image.url ? (
                        <img
                            src={`${process.env.REACT_APP_BASE_WEB_URL}${image.url}`}
                            alt={title || 'Título'}
                            width={800}
                            height={200}
                        />
                    ) : (
                        <span>
                            <strong>{title || 'Título'}</strong>
                        </span>
                    )}
                </p>
                <p className="NewsletterSectionItemFormFields__input--title NewsletterSectionTemplateCollectionItem__item-title">
                    {title || 'Título'}
                </p>
                <p className="NewsletterSectionTemplateCollectionItem__text NewsletterSectionItemFormFields__input--text">
                    Esse é um exemplo de texto para demonstrar uma prévia da seção.
                </p>
                <div className="NewsletterSectionTemplateCollectionItem__link-text NewsletterSectionItemFormFields__input--link_text">
                    <span>Exemplo de link</span>
                </div>
            </div>
        </div>
    );
}
