import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ClippingSourceCreateOrUpdateForm from '../../forms/ClippingSource/ClippingSourceCreateOrUpdateForm';

export default function ClippingSourceCreatePage() {
    return (
        <DefaultLayout title="Adicionar fonte de mídia">
            <ClippingSourceCreateOrUpdateForm />
        </DefaultLayout>
    );
}
