import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterRevisionCollection from '../../collections/Newsletter/NewsletterRevisionCollection';

export default function NewsletterRevisionIndexPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout
            title="Histórico de boletim"
            tabs={[
                { linkTo: `/boletins/${id}`, text: 'Editar' },
                { linkTo: `/boletins/${id}/metricas`, text: 'Métricas' },
                { linkTo: `/boletins/${id}/historico`, text: 'Histórico' },
            ]}
        >
            <NewsletterRevisionCollection id={id} request="listUserRevisions" linkToPrefix="boletins" />
        </DefaultLayout>
    );
}
