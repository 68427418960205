import React from 'react';
import CopyLinkIcon from './CopyLinkIcon';
import FacebookIcon from './FacebookIcon';
import TwitterIcon from './TwitterIcon';
import WhatsappIcon from './WhatsappIcon';

export default function ShareIconsList(props) {
    const { className } = props;

    return (
        <ul className={ className }>
            <li>
                <span>
                    <TwitterIcon />
                </span>
            </li>
            <li>
                <span>
                    <WhatsappIcon />
                </span>
            </li>
            <li>
                <span>
                    <FacebookIcon />
                </span>
            </li>
            <li>
                <span>
                    <CopyLinkIcon />
                </span>
            </li>
        </ul>
    );
}
