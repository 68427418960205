import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ClippingCreateOrUpdateForm from '../../forms/Clipping/ClippingCreateOrUpdateForm';

export default function ClippingRevisionPage(props) {
    const { id } = props.match.params;

    return (
        <DefaultLayout title="Versão de fonte de mídia">
            <ClippingCreateOrUpdateForm id={id} showRequest="revision" />
        </DefaultLayout>
    );
}
