import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ImageCreateForm from '../../forms/Image/ImageCreateForm';

export default function ImageCreatePage() {
    return (
        <DefaultLayout title="Adicionar imagem">
            <ImageCreateForm />
        </DefaultLayout>
    );
}
