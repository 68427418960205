import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { forgotPassword } from '../../requests/authentication';
import withNotifications from '../../contexts/withNotifications';
import Form from '../../components/Forms/Form';
import Field from '../../components/Forms/Field';

const initialValues = {
    email: '',
};

const validationSchema = Yup.object({
    email: Yup.string().required('Campo obrigatório').email('Endereço de e-mail inválido'),
});

class AuthenticationForgotPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        forgotPassword(this, values);
    }

    render() {
        const { isLoading } = this.state;

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit}>
                {({ errors, touched }) => (
                    <Form>
                        <div className="box">
                            <Field
                                name="email"
                                label="E-mail"
                                type="email"
                                autoComplete="email"
                                errors={errors.email}
                                touched={touched.email}
                            />
                            <button
                                type="submit"
                                className={`button is-primary is-fullwidth ${isLoading ? 'is-loading' : ''}`}
                                disabled={isLoading}
                            >
                                Redefinir senha
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withNotifications(withRouter(AuthenticationForgotPasswordForm));
