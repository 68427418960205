import React from 'react';

import withDialogs from '../contexts/withDialogs';

class AtomicForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orientation: 'landscape',
            position: 'position-1000',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.atomicParams) {
            const { orientation, position } = this.props.atomicParams;
            this.setState({ orientation, position });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.setAtomicParams(this.state);
    }

    render() {
        return (
            <>
                {this.props.withOrientation && (
                    <>
                        <h3 className="subtitle">Orientação</h3>
                        <div className="control">
                            <label className="radio" htmlFor="landscape">
                                <input
                                    type="radio"
                                    name="orientation"
                                    id="landscape"
                                    value="landscape"
                                    checked={this.state.orientation === 'landscape'}
                                    onChange={(e) => this.setState({ orientation: 'landscape' })}
                                />
                                &nbsp;Paisagem
                            </label>
                            &nbsp;
                            <label className="radio" htmlFor="portrait">
                                <input
                                    type="radio"
                                    name="orientation"
                                    id="portrait"
                                    value="portrait"
                                    checked={this.state.orientation === 'portrait'}
                                    onChange={(e) => this.setState({ orientation: 'portrait' })}
                                />
                                &nbsp;Retrato
                            </label>
                            &nbsp;
                            <label className="radio" htmlFor="square">
                                <input
                                    type="radio"
                                    name="orientation"
                                    id="square"
                                    value="square"
                                    checked={this.state.orientation === 'square'}
                                    onChange={(e) => this.setState({ orientation: 'square' })}
                                />
                                &nbsp;Quadrado
                            </label>
                            &nbsp;
                        </div>
                        <br />
                    </>
                )}
                <h3 className="subtitle">Posição</h3>
                <div className="control">
                    <label className="radio" htmlFor="position-1000">
                        <input
                            type="radio"
                            name="position"
                            id="position-1000"
                            value="position-1000"
                            checked={this.state.position === 'position-1000'}
                            onChange={(e) => this.setState({ position: 'position-1000' })}
                        />
                        &nbsp;Uma coluna na esquerda
                    </label>
                    <br />
                    <label className="radio" htmlFor="position-0001">
                        <input
                            type="radio"
                            name="position"
                            id="position-0001"
                            value="position-0001"
                            checked={this.state.position === 'position-0001'}
                            onChange={(e) => this.setState({ position: 'position-0001' })}
                        />
                        &nbsp;Uma coluna na direita
                    </label>
                    <br />
                    <label className="radio" htmlFor="position-1100">
                        <input
                            type="radio"
                            name="position"
                            id="position-1100"
                            value="position-1100"
                            checked={this.state.position === 'position-1100'}
                            onChange={(e) => this.setState({ position: 'position-1100' })}
                        />
                        &nbsp;Duas colunas na esquerda
                    </label>
                    <br />
                    <label className="radio" htmlFor="position-0011">
                        <input
                            type="radio"
                            name="position"
                            id="position-0011"
                            value="position-0011"
                            checked={this.state.position === 'position-0011'}
                            onChange={(e) => this.setState({ position: 'position-0011' })}
                        />
                        &nbsp;Duas colunas na direita
                    </label>
                    <br />
                    <label className="radio" htmlFor="position-1110">
                        <input
                            type="radio"
                            name="position"
                            id="position-1110"
                            value="position-1110"
                            checked={this.state.position === 'position-1110'}
                            onChange={(e) => this.setState({ position: 'position-1110' })}
                        />
                        &nbsp;Três colunas na esquerda
                    </label>
                    <br />
                    <label className="radio" htmlFor="position-0111">
                        <input
                            type="radio"
                            name="position"
                            id="position-0111"
                            value="position-0111"
                            checked={this.state.position === 'position-0111'}
                            onChange={(e) => this.setState({ position: 'position-0111' })}
                        />
                        &nbsp;Três colunas na direita
                    </label>
                    <br />
                    <label className="radio" htmlFor="position-1111">
                        <input
                            type="radio"
                            name="position"
                            id="position-1111"
                            value="position-1111"
                            checked={this.state.position === 'position-1111'}
                            onChange={(e) => this.setState({ position: 'position-1111' })}
                        />
                        &nbsp;Quatro colunas
                    </label>
                    <br />
                    <br />
                </div>
                <div className="actions actions--sticky">
                    <button type="button" className="button is-primary is-medium" onClick={this.handleSubmit}>
                        OK
                    </button>
                </div>
            </>
        );
    }
}

export default withDialogs(AtomicForm);
