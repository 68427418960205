import React from 'react';

import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';
import AuthenticationResetPasswordForm from '../../forms/Authentication/AuthenticationResetPasswordForm';

export default function AuthenticationResetPasswordPage(props) {
    const url = new URLSearchParams(props.location.search).get('url');

    return (
        <UnauthenticatedLayout title="Redefinir senha">
            <AuthenticationResetPasswordForm url={url} />
        </UnauthenticatedLayout>
    );
}
