import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import NewsletterSectionTemplateCreateOrUpdateForm from '../../forms/NewsletterSectionTemplate/NewsletterSectionTemplateCreateOrUpdateForm';

export default function NewsletterSectionTemplateCreatePage() {
    return (
        <DefaultLayout title="Adicionar seção de boletim">
            <NewsletterSectionTemplateCreateOrUpdateForm />
        </DefaultLayout>
    );
}
