import React from 'react';

import { getDate, getTime } from '../../services/datetime';

export const redirectTableHeaders = ['URL', 'IP', 'Data e hora'];

export default function RedirectTableRow(props) {
    const { data } = props;

    return (
        <tr key={data.id}>
            <td>
                <a href={data.url}>{data.url}</a>
            </td>
            <td>{data.ip || 'Não registrado'}</td>
            <td>{`${getDate(data.created_at)} às ${getTime(data.created_at)}`}</td>
        </tr>
    );
}
