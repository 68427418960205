import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import ClippingCreateOrUpdateForm from '../../forms/Clipping/ClippingCreateOrUpdateForm';

export default function ClippingCreatePage() {
    return (
        <DefaultLayout title="Adicionar clipping">
            <ClippingCreateOrUpdateForm />
        </DefaultLayout>
    );
}
