import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout';
import InterviewCreateOrUpdateForm from '../../forms/Interview/InterviewCreateOrUpdateForm';

export default function InterviewCreatePage() {
    return (
        <DefaultLayout title="Adicionar entrevista" isFullWidth>
            <InterviewCreateOrUpdateForm />
        </DefaultLayout>
    );
}
