import React from 'react';

import { editBlock, deleteBlock } from '../../services/editor';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import YouTubePrompter from './YouTubePrompter';
import YouTubeElement from './YouTubeElement';

class YouTubeBlock extends React.Component {
    constructor(props) {
        super(props);
        this.confirmYouTube = this.confirmYouTube.bind(this);
        this.removeYouTube = this.removeYouTube.bind(this);
    }

    confirmYouTube(url, caption, position) {
        editBlock(this, { url, caption, position });
        this.props.dialogs.remove(dialogsKeys.editorBlock);
    }

    removeYouTube(event) {
        event.stopPropagation();
        deleteBlock(this);
    }

    render() {
        const { block, contentState } = this.props;
        const { editorState, setEditorState } = this.props.blockProps;
        const { url, caption, position } = contentState.getEntity(block.getEntityAt(0)).getData();

        return (
            <div className="editor-block">
                <YouTubeElement url={url} caption={caption} />
                <YouTubePrompter
                    editorState={editorState}
                    setEditorState={setEditorState}
                    url={url}
                    caption={caption}
                    position={position}
                    confirmYouTube={this.confirmYouTube}
                >
                    Editar YouTube
                </YouTubePrompter>
                <button type="button" className="button" onClick={this.removeYouTube}>
                    Remover YouTube
                </button>
            </div>
        );
    }
}

export default withDialogs(YouTubeBlock);
