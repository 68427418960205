import React from 'react';

import { listAll } from '../../requests/clipping-source';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import withNotifications from '../../contexts/withNotifications';
import ClippingSourceCreateOrUpdateForm from '../../forms/ClippingSource/ClippingSourceCreateOrUpdateForm';
import Select from './Select';

class ClippingSourceField extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            clippingSourceList: [],
        };
        this.onChange = this.onChange.bind(this);
        this.openClippingSourceCreateForm = this.openClippingSourceCreateForm.bind(this);
    }

    componentDidMount() {
        listAll(this);
    }

    onChange(event) {
        const { value } = event.target;
        const { setFieldValue } = this.props;
        const { clippingSourceList } = this.state;

        const selectedClippingSource = clippingSourceList.find((element) => {
            return element.id === parseInt(value, 10);
        });

        if (selectedClippingSource) {
            setFieldValue('clipping_source.id', selectedClippingSource.id);
            setFieldValue('clipping_source.image.id', selectedClippingSource.image.id);
            setFieldValue('clipping_source.image.url', selectedClippingSource.image.url);
        }
    }

    openClippingSourceCreateForm() {
        const { setFieldValue, dialogs } = this.props;

        dialogs.add(
            dialogsKeys.clippingSource,
            'Adicionar fonte de mídia',
            <ClippingSourceCreateOrUpdateForm
                successCallbackFn={(response) => {
                    listAll(this);
                    setFieldValue('clipping_source.id', response.data.id);
                    setFieldValue('clipping_source.image.id', response.data.image.id);
                    setFieldValue('clipping_source.image.url', response.data.image.url);
                    dialogs.remove(dialogsKeys.clippingSource);
                }}
                withoutBox
            />
        );
    }

    render() {
        const { value, errors, touched } = this.props;
        const { clippingSourceList, isLoading } = this.state;

        return (
            <div className="field">
                <Select
                    name="clipping-source-controller"
                    label="Fonte de mídia"
                    isLoading={isLoading}
                    onChange={this.onChange}
                    defaultValue={value.id}
                    touched={touched}
                    errors={errors}
                    errorString={errors && errors.id}
                    withAddOn={
                        <button
                            type="button"
                            className={`button is-primary ${isLoading ? 'is-loading' : ''}`}
                            disabled={isLoading}
                            onClick={this.openClippingSourceCreateForm}
                        >
                            Adicionar
                        </button>
                    }
                >
                    <option value="">Selecione ou adicione uma fonte de mídia...</option>
                    {clippingSourceList &&
                        clippingSourceList.map((clippingSource) => (
                            <option key={clippingSource.id} value={clippingSource.id}>
                                {clippingSource.title}
                            </option>
                        ))}
                </Select>
            </div>
        );
    }
}

export default withNotifications(withDialogs(ClippingSourceField));
