import React from 'react';
import { withRouter } from 'react-router-dom';

import { imageMimeTypes } from '../../services/media';
import { create } from '../../requests/image';
import withNotifications from '../../contexts/withNotifications';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import ImageEditorForm from './ImageEditorForm';

class ImageCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
        this.fileInputRef = React.createRef();
        this.handleUpload = this.handleUpload.bind(this);
        this.imageUpload = this.imageUpload.bind(this);
    }

    handleUpload(event) {
        const { mimeType, fromImageField, history, dialogs, notifications } = this.props;
        const file = event.target.files[0];

        if (mimeType && imageMimeTypes.includes(mimeType)) {
            if (imageMimeTypes.includes(file.type)) {
                this.imageUpload(file);
            } else {
                notifications.add('Esse formato de arquivo não foi reconhecido como uma imagem.', 'danger');
            }
        } else {
            if (fromImageField) {
                create(this, file, {
                    successCallbackFn: () => {
                        dialogs.remove(dialogsKeys.imageField);
                    },
                });
            } else {
                create(this, file, {
                    successCallbackFn: (response) => {
                        history.push(`/imagens/${response.data.id}`);
                    },
                });
            }
        }
    }

    imageUpload(file) {
        const { mimeType, width, height, aspectRatio, setImageId, setImageUrl, notifications, dialogs } = this.props;

        this.setState({ isLoading: true });

        const infoNotification = notifications.add('Carregando a imagem...');

        const reader = new FileReader();

        reader.addEventListener('load', () => {
            notifications.remove(infoNotification);

            this.setState({ isLoading: false });

            dialogs.add(
                dialogsKeys.imageEditor,
                'Editar imagem',
                <ImageEditorForm
                    image={reader.result}
                    filename={file.name}
                    mimeType={mimeType}
                    width={width}
                    height={height}
                    aspectRatio={aspectRatio}
                    setImageId={setImageId}
                    setImageUrl={setImageUrl}
                />,
                true
            );

            dialogs.remove(dialogsKeys.imageField);
        });

        reader.readAsDataURL(file);
    }

    acceptedImageTypes() {
        const { mimeType } = this.props;

        switch (mimeType) {
            case 'image/jpeg':
            case 'image/png':
                return 'image/*';
            case 'application/pdf':
                return 'application/pdf';
            case 'video':
                return 'video/*';
            case 'audio':
                return 'audio/*';
            default:
                return '';
        }
    }

    render() {
        const { withoutBox } = this.props;
        const { isLoading } = this.state;

        return (
            <div className={`ImageCreateForm ${!withoutBox ? 'box' : ''}`}>
                <div className="ImageCreateForm__container">
                    <div className="ImageCreateForm__inner">
                        <p>Arraste um arquivo ou clique no botão abaixo para adicionar uma imagem</p>
                        <br />
                        <button
                            type="button"
                            className={`ImageCreateForm__button button is-primary is-large ${
                                isLoading ? 'isLoading' : ''
                            }`}
                            disabled={isLoading}
                            onClick={() => this.fileInputRef.current.click()}
                        >
                            Enviar arquivo
                        </button>
                    </div>
                    <input
                        ref={this.fileInputRef}
                        className="ImageCreateForm__input"
                        type="file"
                        accept={this.acceptedImageTypes()}
                        disabled={isLoading}
                        readOnly
                        onChange={this.handleUpload}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(withNotifications(withDialogs(ImageCreateForm)));
