import React from 'react';

export default class YouTubeElement extends React.Component {
    render() {
        const { url, caption } = this.props;

        return (
            <>
                <div className="embed-container">
                    <iframe
                        title="Vídeo do YouTube incorporado"
                        width="100%"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        src={url}
                    />
                </div>
                {caption && <figcaption>{caption}</figcaption>}
            </>
        );
    }
}

export function getYouTubeHtmlString(url, caption) {
    return `
    <div class="embed-container">
      <iframe
        title="Vídeo do YouTube incorporado"
        width="100%"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        src="${url}"
        loading="lazy"
      ></iframe>
    </div>
    ${caption && `<figcaption>${caption.trim()}</figcaption>`}
  `;
}
