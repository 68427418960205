import React from 'react';
import { withRouter } from 'react-router-dom';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { EditorState } from 'draft-js';
import moment from 'moment';
import 'moment/locale/pt-br';
import TextareaAutosize from 'react-autosize-textarea';

import placeholder_1600x1600 from '../../images/placeholders/1600x1600.png';
import placeholder_2880x960 from '../../images/placeholders/2880x960.png';
import {
    show,
    create,
    update,
    showRemoved,
    showRevision,
    restore,
    storeValues,
    showStoredValues,
    removeStoredValues,
} from '../../requests/post';
import { Decorator } from '../../services/editor';
import { getDate } from '../../services/datetime';
import withNotifications from '../../contexts/withNotifications';
import withDialogs, { dialogsKeys } from '../../contexts/withDialogs';
import Form from '../../components/Forms/Form';
import RevisionData from '../../components/Forms/RevisionData';
import ImageField from '../../components/Forms/ImageField';
import PublicationFields from '../../components/Forms/PublicationFields';
import TagsField from '../../components/Forms/TagsField';
import Field from '../../components/Forms/Field';
import EditorField from '../../components/Forms/EditorField';
import ShareIconsList from '../../components/Icons/ShareIconsList';

moment.locale('pt-br');

export const initialValues = {
    title: '',
    subtitle: '',
    payload: EditorState.createEmpty(Decorator),
    author: '',
    date: '',
    published: '1',
    published_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    image: {
        id: undefined,
        url: undefined,
    },
    thumbnail: {
        id: undefined,
        url: undefined,
    },
};

export const validationSchema = Yup.object({
    title: Yup.string().required('Campo obrigatório').max(191, 'O campo não pode ter mais que 191 caracteres'),
    subtitle: Yup.string().required('Campo obrigatório'),
    author: Yup.string().required('Campo obrigatório'),
    date: Yup.string().required('Campo obrigatório'),
    published: Yup.bool().required('Campo obrigatório'),
    published_at: Yup.string().required('Campo obrigatório'),
});

class PostCreatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            post: initialValues,
            hasStoredValues: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.removeStoredValues = this.removeStoredValues.bind(this);
        this.openImageField = this.openImageField.bind(this);
        this.openThumbnailField = this.openThumbnailField.bind(this);
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { id, showRequest, onSubmitRequest } = this.props;

        if (id) {
            if (showRequest === 'revision') {
                showRevision(this, id);
            } else if (onSubmitRequest === 'restore') {
                showRemoved(this, id);
            } else {
                show(this, id);
            }
        } else {
            showStoredValues(this);
        }
    }

    /**
     * Cria, atualiza ou restaura a notícia ao submeter o formulário.
     *
     * @param {*} values
     */
    onSubmit(values) {
        const { id, onSubmitRequest } = this.props;

        if (id) {
            if (onSubmitRequest === 'restore') {
                restore(this, id);
            } else {
                update(this, id, values);
            }
        } else {
            create(this, values);
        }
    }

    /**
     * Remove os valores armazenados no navegador.
     */
    removeStoredValues() {
        const { id } = this.props;

        removeStoredValues(this, initialValues, id || null);
    }

    /**
     * Abre o ImageField para o campo image.
     */
    openImageField(setFieldValue) {
        const { dialogs } = this.props;

        dialogs.add(
            dialogsKeys.imageField,
            'Gerenciador de Mídias',
            <ImageField
                mimeType="image/jpeg"
                width={2880}
                height={960}
                aspectRatio={3}
                setImageId={(value) => setFieldValue('image.id', value)}
                setImageUrl={(value) => setFieldValue('image.url', value)}
            />,
            true
        );
    }

    /**
     * Abre o ImageField para o campo thumbnail.
     */
    openThumbnailField(setFieldValue) {
        const { dialogs } = this.props;

        dialogs.add(
            dialogsKeys.imageField,
            'Gerenciador de Mídias',
            <ImageField
                mimeType="image/jpeg"
                width={1600}
                height={1600}
                aspectRatio={1}
                setImageId={(value) => setFieldValue('thumbnail.id', value)}
                setImageUrl={(value) => setFieldValue('thumbnail.url', value)}
            />,
            true
        );
    }

    /**
     * @inheritdoc
     */
    render() {
        const { id, showRequest, disabled, onSubmitRequest } = this.props;
        const { isLoading, post, revision, hasStoredValues } = this.state;

        return (
            <Formik
                initialValues={post}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
            >
                {({ values, errors, touched, setFieldValue, isValid, submitCount }) => (
                    <Form
                        withErrorNotification
                        isValid={isValid}
                        submitCount={submitCount}
                        storeValues={storeValues}
                        values={values}
                        initialValues={post}
                    >
                        <fieldset
                            className="cdts-post PostCreateOrUpdateForm"
                            disabled={disabled || showRequest === 'revision' || onSubmitRequest === 'restore'}
                        >
                            <div className="PostCreateOrUpdateForm__background">
                                <div className="cdts-container cdts-container--no-padding">
                                    <div className="cdts-post__header">
                                        <div className="cdts-post__image">
                                            <div className="image-buttons">
                                                {values.image && values.image.id ? (
                                                    <>
                                                        <img
                                                            src={`${process.env.REACT_APP_BASE_WEB_URL}${values.image.url}`}
                                                            alt=""
                                                            width={1440}
                                                            height={480}
                                                            className="cdts-post__image-core"
                                                        />
                                                        <button
                                                            type="button"
                                                            className={`image-buttons__double button button-reset ${
                                                                isLoading ? 'is-loading' : ''
                                                            }`}
                                                            disabled={isLoading}
                                                            onClick={() => this.openImageField(setFieldValue)}
                                                        >
                                                            Trocar imagem
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={`image-buttons__double button button-reset ${
                                                                isLoading ? 'is-loading' : ''
                                                            }`}
                                                            disabled={isLoading}
                                                            onClick={() => {
                                                                setFieldValue('image.id', '');
                                                                setFieldValue('image.url', '');
                                                            }}
                                                        >
                                                            Remover imagem
                                                        </button>
                                                    </>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className={`
                                                            image-buttons__single
                                                            image-buttons__single--with-placeholder
                                                            button ${isLoading ? 'is-loading' : ''}`}
                                                        disabled={isLoading}
                                                        onClick={() => this.openImageField(setFieldValue)}
                                                    >
                                                        <img
                                                            src={placeholder_2880x960}
                                                            alt=""
                                                            width={1440}
                                                            height={480}
                                                        />
                                                        <span>Adicionar imagem</span>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="cdts-post__headings">
                                            <Field
                                                variant="PostCreateOrUpdateForm"
                                                modifier="title"
                                                name="title"
                                                label="Título"
                                                labelHidden
                                                async
                                                placeholder="Título"
                                                errors={errors && errors.title}
                                                touched={touched && touched.title}
                                            />
                                            <Field
                                                variant="PostCreateOrUpdateForm"
                                                modifier="subtitle"
                                                name="subtitle"
                                                label="Subtítulo"
                                                labelHidden
                                                async
                                                placeholder="Subtítulo"
                                                errors={errors && errors.subtitle}
                                                touched={touched && touched.subtitle}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={`cdts-magazine__body cdts-container cdts-container--no-padding`}>
                                    <div className="cdts-magazine__container">
                                        <div className="cdts-magazine__content">
                                            <EditorField
                                                editorState={values.payload}
                                                setEditorState={(payload) => {
                                                    setFieldValue('payload', payload);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="cdts-magazine-aside">
                                        <div className="cdts-magazine-aside__container">
                                            <div className="cdts-magazine-aside__panel">
                                                <Field
                                                    variant="PostCreateOrUpdateForm"
                                                    modifier="author"
                                                    name="author"
                                                    label="Autoria"
                                                    labelHidden
                                                    as={TextareaAutosize}
                                                    async
                                                    placeholder="Autoria"
                                                    errors={errors && errors.author}
                                                    touched={touched && touched.author}
                                                />
                                                <hr className="cdts-magazine-aside__bar" />
                                                <Field
                                                    variant="PostCreateOrUpdateForm"
                                                    modifier="date"
                                                    name="date"
                                                    label="Data"
                                                    labelHidden
                                                    as={TextareaAutosize}
                                                    async
                                                    placeholder="Data"
                                                    errors={errors && errors.date}
                                                    touched={touched && touched.date}
                                                />
                                            </div>
                                            <div className="cdts-magazine-aside__link-hub-container">
                                                <ShareIconsList className="cdts-link-hub cdts-magazine-aside__link-hub" />
                                            </div>
                                            <hr className="cdts-magazine-aside__vertical-hr" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="is-invisible" />

                            <section className="contained">
                                <h2 className="subtitle">Tags</h2>
                                <div className="box">
                                    <FieldArray
                                        name="tags"
                                        render={(arrayHelpers) => (
                                            <TagsField
                                                values={values.tags}
                                                errors={errors.tags}
                                                touched={touched.tags}
                                                arrayHelpers={arrayHelpers}
                                                showRequest={showRequest}
                                            />
                                        )}
                                    />
                                </div>
                            </section>

                            <hr className="is-invisible" />

                            <section className="contained">
                                <h2 className="subtitle">Visualização em lista</h2>
                                <div className="box">
                                    <div className="cdts-default-list-item">
                                        <div className="image-buttons cdts-default-list-item__image DefaultListFields__thumbnail ">
                                            {values.thumbnail && values.thumbnail.id ? (
                                                <>
                                                    <img
                                                        src={`${process.env.REACT_APP_BASE_WEB_URL}${values.thumbnail.url}`}
                                                        alt=""
                                                        width={800}
                                                        height={800}
                                                    />
                                                    <button
                                                        type="button"
                                                        className={`image-buttons__double button button-reset is-small ${
                                                            isLoading ? 'is-loading' : ''
                                                        }`}
                                                        disabled={isLoading}
                                                        onClick={() => this.openThumbnailField(setFieldValue)}
                                                    >
                                                        Trocar imagem
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={`image-buttons__double button button-reset is-small ${
                                                            isLoading ? 'is-loading' : ''
                                                        }`}
                                                        disabled={isLoading}
                                                        onClick={() => {
                                                            setFieldValue('thumbnail.id', '');
                                                            setFieldValue('thumbnail.url', '');
                                                        }}
                                                    >
                                                        Remover imagem
                                                    </button>
                                                </>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className={`
                                                    image-buttons__single
                                                    image-buttons__single--with-placeholder
                                                    button
                                                    is-small ${isLoading ? 'is-loading' : ''}`}
                                                    disabled={isLoading}
                                                    onClick={() => this.openThumbnailField(setFieldValue)}
                                                >
                                                    <img src={placeholder_1600x1600} alt="" width={800} height={800} />
                                                    <span>Adicionar imagem</span>
                                                </button>
                                            )}
                                        </div>

                                        <div className="cdts-default-list-item__content">
                                            <h3 className="cdts-default-list-item__title">
                                                {values.title || 'Título'}
                                            </h3>
                                            {values.person_name && values.person_job_title && (
                                                <p className="cdts-default-list-item__person">
                                                    <strong>{values.person_name}</strong>
                                                    <span>{values.person_job_title}</span>
                                                </p>
                                            )}
                                            <p className="cdts-default-list-item__subtitle">
                                                {values.subtitle || 'Subtítulo'}
                                            </p>
                                        </div>

                                        <div className="cdts-default-list-item__aside">
                                            <p className="cdts-default-list-item__published-at">
                                                <span>Publicado em</span>
                                                <br />
                                                <span>{getDate(values.published_at)}</span>
                                            </p>
                                            <ShareIconsList className="cdts-link-hub cdts-default-list-item__link-hub" />
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <hr className="is-invisible" />

                            <section className="contained">
                                <PublicationFields errors={errors} touched={touched} />
                            </section>
                        </fieldset>

                        {showRequest === 'revision' && revision && (
                            <>
                                <hr className="invisible" />
                                <div className="contained">
                                    <RevisionData revision={revision} />
                                </div>
                            </>
                        )}

                        {showRequest !== 'revision' && (
                            <>
                                <hr className="invisible" />
                                <div className="contained buttons actions actions--sticky">
                                    <button
                                        type="submit"
                                        className={`button is-primary is-medium ${isLoading ? 'is-loading' : ''}`}
                                        disabled={isLoading}
                                    >
                                        {onSubmitRequest === 'restore' ? 'Restaurar' : id ? 'Editar' : 'Adicionar'}
                                    </button>
                                </div>
                            </>
                        )}

                        <div className="contained buttons is-justified-center">
                            {showRequest !== 'revision' &&
                                onSubmitRequest !== 'restore' &&
                                post.slug &&
                                Boolean(post.published) && (
                                    <a
                                        className={`button is-light is-info ${isLoading ? 'is-loading' : ''}`}
                                        href={`${process.env.REACT_APP_BASE_WEB_URL}/noticias/${post.slug}`}
                                    >
                                        Visualizar no site
                                    </a>
                                )}
                            {hasStoredValues && (
                                <button
                                    type="button"
                                    className={`button is-light is-warning ${isLoading ? 'is-loading' : ''}`}
                                    disabled={isLoading}
                                    onClick={this.removeStoredValues}
                                >
                                    Limpar dados armazenados
                                </button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withDialogs(withNotifications(withRouter(PostCreatePage)));
